import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, CardActions, Grid, List, ListItem, Stack } from '@mui/material';
import { subscribeServices } from 'src/Services/ApiHandlers/subscribeService';
import { toast } from 'react-toastify';
import SignUpModal from 'src/sections/PopUp/SignUpModal';
import LoginModal from 'src/sections/PopUp/LoginModal';
import { Storage } from 'src/Storage';
import { LoadingButton as Button } from '@mui/lab';

const BuySub = ({ content }) => {
  const hasToken = Storage.get('authToken');
  const hasRole = Storage.get('user-role') && JSON.parse(Storage.get('user-role'));
  const isLoggedIn = hasToken && hasRole;
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const name = isLoggedIn ? hasRole.full_name : 'Guest';

  const startHandler = async (title) => {
    if (title === 'Learner’s Launchpad') return;
    if (!isLoggedIn) {
      setOpenLoginModal(true);
      return;
    }
    setLoading(true);
    try {
      const response = await subscribeServices.payApi({ plan_name: title });
      if (response.code === 200) window.location = response.data.url;
      else toast.error(response.data.message);
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ p: 20 }}>
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Box
            component="div"
            className="bookContent py-3"
            dangerouslySetInnerHTML={{ __html: content }}
            sx={{ position: 'relative', zIndex: 1 }} // Ensures content is above the gradient
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: -100,
              left: 0,
              right: 0,
              height: '200px',
              background: 'rgba(255, 255, 255, 0.84)',
              zIndex: 2, // Keep the fade on top of the content
            }}
          />
        </Box>

        <Box
          sx={{
            position: 'relative',
            backgroundColor: 'white',
            p: 4,
            mt: 2,
            borderRadius: 2,
            boxShadow: 3,
            zIndex: 4,
            maxWidth: '1200px',
            mx: 'auto',
          }}
        >
          <Typography variant="h2" sx={{ textAlign: 'center' }}>
            Hello {name}!
          </Typography>
          {isLoggedIn ? (
            <Typography variant="body1" sx={{ textAlign: 'center', mt: 2 }}>
              Thank you for being a part of Adaptive Books! To enjoy our premium content, please consider upgrading to
              one of the following plans to access this page!
            </Typography>
          ) : (
            <>
              <Typography variant="body1" sx={{ textAlign: 'center', mt: 2 }}>
                To access premium content, please subscribe to <strong>Premium Access</strong> or
                <strong> AI Tutor</strong>. If you're already subscribed, please sign in.
              </Typography>
              <Stack sx={{ justifyContent: 'center', alignItems: 'center', width: '100%', my: '2rem' }}>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: '#0044AA', width: '200px', textAlign: 'center' }}
                  onClick={() => setOpenLoginModal(true)}
                >
                  Sign In
                </Button>
              </Stack>
            </>
          )}

          {/* Plans Section */}
          <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
            {/* Premium Access Plan */}
            <Grid item xs={12} sm={6}>
              <Card
                sx={{
                  backgroundColor: '#dae3f3',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  p: 2,
                  borderRadius: 2,
                  boxShadow: 3,
                  height: '100%',
                }}
              >
                <CardContent>
                  <Typography variant="h3" sx={{ fontSize: '1.5em', color: '#0044AA', textAlign: 'center' }}>
                    Premium Access
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: '1em', color: '#000', textAlign: 'left', pl: 1, mt: 2 }}>
                    Complete access to the entire library, including premium content.
                  </Typography>
                </CardContent>
                <CardActions sx={{ flexDirection: 'column', alignItems: 'center' }}>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: '1.2em', fontWeight: 'bold', textAlign: 'center', mb: 2 }}
                  >
                    $4.99/month
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#00A1FF',
                      width: '80%',
                      '&:hover': { backgroundColor: '#007bbd' },
                    }}
                    onClick={() => startHandler('Premium Access')}
                    loading={loading}
                  >
                    Get Started
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            {/* AI Tutor Plan */}
            <Grid item xs={12} sm={6}>
              <Card
                sx={{
                  backgroundColor: '#dae3f3',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  p: 2,
                  borderRadius: 2,
                  boxShadow: 3,
                  height: '100%',
                }}
              >
                <CardContent>
                  <Typography variant="h3" sx={{ fontSize: '1.5em', color: '#0044AA', textAlign: 'center' }}>
                    AI Tutor
                  </Typography>
                  <Box
                    sx={{
                      fontSize: '1em',
                      color: '#000',
                      textAlign: 'left',
                      pl: 2,
                      mt: 2,
                    }}
                  >
                    <List>
                      <ListItem sx={{ display: 'list-item' }}>Includes everything in Premium Access</ListItem>
                      <ListItem sx={{ display: 'list-item' }}>
                        Personalized AI assistant to answer queries and provide explanations
                      </ListItem>
                      <ListItem sx={{ display: 'list-item' }}>
                        AI-driven Exam Booster for tailored practice and instant feedback
                      </ListItem>
                      <ListItem sx={{ display: 'list-item' }}>
                        AI Summarization: Instantly summarize selected text
                      </ListItem>
                    </List>
                  </Box>
                </CardContent>
                <CardActions sx={{ flexDirection: 'column', alignItems: 'center' }}>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: '1.2em', fontWeight: 'bold', textAlign: 'center', mb: 2 }}
                  >
                    $9.99/month
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#00A1FF',
                      width: '80%',
                      '&:hover': { backgroundColor: '#007bbd' },
                    }}
                    onClick={() => startHandler('AI Tutor')}
                    loading={loading}
                  >
                    Get Started
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <LoginModal
        open={openLoginModal}
        setOpen={setOpenLoginModal}
        setRegister={setOpenRegisterModal}
        redirectURL="/need-sub"
      />
      <SignUpModal open={openRegisterModal} setOpen={setOpenRegisterModal} setLogin={setOpenLoginModal} />
    </>
  );
};

export default BuySub;
