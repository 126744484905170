import { useEffect } from 'react';
import { profileService } from 'src/Services/WebApiHandler/profileService';
import { Storage } from 'src/Storage';
import AIClassroom from '../../assets/webImages/AI-Classroom.webp';
import Caltech from '../../assets/webImages/Caltech.png';
import Harvard from '../../assets/webImages/Harvard.png';
import MIT from '../../assets/webImages/MIT_logo.png';
import Michigan from '../../assets/webImages/Michigan_Logo.png';
import NYU from '../../assets/webImages/NYU_Logo.webp';
import Stanford from '../../assets/webImages/Stanford_Logo.png';
import final from '../../assets/webImages/final.webp';
import BoredStudent from '../../assets/webImages/tired-student-min.jpg';

import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function Home() {
  const isLoggedIn = Storage.get('authToken') && JSON.parse(Storage.get('authToken'));
  const { t } = useTranslation();
  function getDetail() {
    profileService
      .getProfileDetailApi()
      .then((response) => {
        if (response.code === 200) {
          Storage.set('user-role', JSON.stringify(response.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    isLoggedIn && getDetail();
  }, []);

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: {
            xs: '60vh',
            md: '100vh',
          },
          maxHeight: '100vh',
          overflow: 'hidden',
          background: '#001826',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            height: '100%',
            aspectRatio: '1 / 1',
            backgroundImage: `url(${AIClassroom})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            maskImage: 'linear-gradient(to right, transparent, black 20%)',
            WebkitMaskImage: 'linear-gradient(to right, transparent, black 20%)',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background:
              'linear-gradient(to right, rgba(0, 0, 0, 0.99) 0%, rgba(13, 13, 13, 0.75) 20%, rgba(0, 24, 38, 0.8) 40%, rgba(0, 24, 38, 0) 70%)',
          }}
        />
        <Box
          sx={{
            position: 'relative',
            zIndex: 2,
            color: 'white',
            padding: '2rem',
            marginLeft: '8%',
            textAlign: 'left',
            paddingTop: { xs: '100px', sm: '120px', md: '120px' },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              fontSize: { xs: '18px', sm: '20px', md: '24px', lg: '30px' },
              marginBottom: '2rem',
              opacity: 0.9,
            }}
          >
            {t('home_h_1')}
          </Typography>
          <Typography
            variant="h1"
            sx={{
              fontWeight: 700,
              fontSize: { xs: '28px', sm: '36px', md: '48px', lg: '60px' },
              lineHeight: 1.5,
              margin: 0,
              color: 'transparent',
              backgroundImage: 'linear-gradient(90deg, #4CA5FF 0%, #B673F8 50%, #4CA5FF 100%)',
              backgroundClip: 'text',
            }}
          >
            {t('home_main_heading_1')}
            <br />
            {t('home_main_heading_2')}
            <br />
            {t('home_main_heading_3')}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ p: '50px 0' }}>
        <Container>
          <Typography
            component="h2"
            variant="h2"
            sx={{
              fontSize: {
                sm: '30px',
                md: '60px',
              },
              color: '#0044aa',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Learn From Leading Universities
          </Typography>
          <Box className="row university-logos">
            <Box className="col text-center">
              <img src={Harvard} alt="Harvard University" style={{ height: '150px', objectFit: 'contain' }} />
            </Box>
            <Box className="col text-center">
              <img
                src={MIT}
                alt="Massachusetts Institute of Technology"
                style={{ height: '150px', objectFit: 'contain' }}
              />
            </Box>
            <Box className="col text-center">
              <img src={NYU} alt="New York University" style={{ height: '150px', objectFit: 'contain' }} />
            </Box>
            <Box className="col text-center">
              <img src={Stanford} alt="Stanford University" style={{ height: '150px', objectFit: 'contain' }} />
            </Box>
            <Box className="col text-center">
              <img src={Michigan} alt="University of Michigan" style={{ height: '150px', objectFit: 'contain' }} />
            </Box>
            <Box className="col text-center">
              <img
                src={Caltech}
                alt="California Institute of Technology"
                style={{ height: '150px', objectFit: 'contain' }}
              />
            </Box>
          </Box>
        </Container>
      </Box>

      <div className="second-flex con-height-mt">
        <div className="container">
          <div className="col-12 d-flex justify-content-center">
            <h1 className="pl-4 heading-co-blue mt-5 mb-5 pb-5">{t('home_h_3')}</h1>
          </div>

          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-md-6">
              <img src={BoredStudent} alt="Bored Student" className="img-fluid" />
            </div>

            <div className="col-md-6 p-4 pl-2 P-Font">
              <p>{t('home_description_1')}</p>
              <p className="pl-4">{t('home_question_1')}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="adaptive_books">
        <div className="container">
          <div className="row">
            <div className="col-12 adaptive_books_col">
              <h1 className=" w-100 m-auto mt-5 color-b">{t('home_h_4')}</h1>

              <h3 className=" w-100  grad-bg-sm text-white  text-center mt-4 ">
                {t('home_description_2')}
                <br />
                {t('home_description_3')}
              </h3>

              <div className="embed-responsive embed-responsive-16by9" style={{ margin: 'auto' }}>
                <div style={{ width: '854px', margin: 'auto' }} className="media">
                  <iframe
                    title="learn-more"
                    style={{ height: '480px', width: '854px' }}
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/5EhT5VrmHwk?si=xqH_56qrSrMSdJ8l"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="interactive_graphs ">
        <h1>{t('home_h_6')}</h1>
        <p className="w-50 m-auto p-3">
          {t('home_description_4')} <br />
          {t('home_description_5')}
        </p>
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              {/* <img src={images} alt="flex" className="img-fluid" /> */}

              <iframe
                title="3DImage2"
                srcDoc='<!DOCTYPE html>

<!-- Use the following line to embed this file within another web page:

<iframe src="3DImage2.html" width="192" height="201" frameborder="0"></iframe>

-->

<html lang="">

<head>
<title>3DImage2</title>

<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
<meta name="viewport" content="user-scalable=no"/>
<style>
body {margin: 0;}
</style>

<script
src="https://vectorgraphics.github.io/asymptote/base/webgl/asygl-1.00.js">
</script>

<script>

canvasWidth=192;
canvasHeight=201;
absolute=false;

b=[-72.52873,-75.92852,-1079.363];
B=[72.52873,75.92852,-673.5032];
orthographic=false;
angle=0.2245254;
Zoom0=1;
viewportmargin=[0.5,0.5];
zoomFactor=1.05;
zoomPinchFactor=10;
zoomPinchCap=100;
zoomStep=0.1;
shiftHoldDistance=20;
shiftWaitTime=200;
vibrateTime=25;

Lights=[new Light(
direction=[0.4477358,0.4972609,0.7431448],
color=[1,1,1]),
];

Background=[1,0,0.3686275,0];
Materials=[];

Materials.push(new Material(
diffuse=[0,0,0,1],
emissive=[1,0,0.3686275,1],
specular=[0,0,0,1],
shininess=1,
metallic=0,
fresnel0=0.04
));

cylinder([-55.36066,-29.10581,-830.8099],1,173.0998,0,0,
[2.412696,0.3597665],true);

sphere([-55.36066,-29.10581,-830.8099],1,0,0,
[0.728897,-2.781826]);

sphere([52.55082,11.48361,-959.9267],1,0,0,
[2.412696,0.3597665]);

Materials.push(new Material(
diffuse=[1,0,0.3686275,1],
emissive=[0,0,0,1],
specular=[0.75,0.75,0.75,1],
shininess=0.7,
metallic=0,
fresnel0=0.04
));

P.push(new BezierPatch([
[-53.6845930738,-29.5065647061,-829.535092293],
[-53.6845930738,-30.6359483885,-829.89012778],
[-54.4349959005,-31.3720702378,-830.748698293],
[-55.3606646983,-31.1507388748,-831.452763207],
[-53.6845930738,-28.3771810236,-829.180056806],
[-54.3017056057,-29.3590104641,-830.004468902],
[-55.3606646983,-30.3978164198,-831.216072883],
[-56.2863334962,-30.9294075118,-832.156828122],
[-54.4349959005,-27.2822101329,-829.463001061],
[-55.3606646983,-27.8138012248,-830.4037563],
[-56.419623791,-28.8526071806,-831.615360281],
[-57.0367363229,-29.834436621,-832.439772377],
[-55.3606646983,-27.0608787699,-830.167065975],
[-56.2863334962,-26.8395474069,-830.87113089],
[-57.0367363229,-27.5756692561,-831.729701403],
[-57.0367363229,-28.7050529386,-832.08473689]
],0,1,[-57.0367363229,-31.1899594528,-960.925782665],[53.5507190116,12.4839865713,-829.382536637]));

P.push(new BezierPatch([
[-57.03674,-28.70505,-832.0847],
[-58.14046,-29.46393,-829.6707],
[-59.24419,-30.22281,-827.2567],
[-60.34792,-30.98169,-824.8426],
[-57.03674,-29.83444,-832.4398],
[-58.14046,-30.21686,-829.9074],
[-59.24419,-30.59928,-827.375],
[-60.34792,-30.98169,-824.8426],
[-56.28633,-30.92941,-832.1568],
[-57.64019,-30.94684,-829.7188],
[-58.99405,-30.96427,-827.2807],
[-60.34792,-30.98169,-824.8426],
[-55.36066,-31.15074,-831.4528],
[-57.02308,-31.09439,-829.2494],
[-58.6855,-31.03804,-827.046],
[-60.34792,-30.98169,-824.8426]
],0,1,[-60.34792,-31.18996,-960.9258],[53.55072,12.48399,-824.8426]));

P.push(new BezierPatch([
[-55.36066,-27.06088,-830.1671],
[-57.02308,-28.36782,-828.3923],
[-58.6855,-29.67476,-826.6174],
[-60.34792,-30.98169,-824.8426],
[-56.28633,-26.83955,-830.8711],
[-57.64019,-28.22026,-828.8616],
[-58.99405,-29.60098,-826.8521],
[-60.34792,-30.98169,-824.8426],
[-57.03674,-27.57567,-831.7297],
[-58.14046,-28.71101,-829.434],
[-59.24419,-29.84635,-827.1383],
[-60.34792,-30.98169,-824.8426],
[-57.03674,-28.70505,-832.0847],
[-58.14046,-29.46393,-829.6707],
[-59.24419,-30.22281,-827.2567],
[-60.34792,-30.98169,-824.8426]
],0,1,[-60.34792,-31.18996,-960.9258],[53.55072,12.48399,-824.8426]));

P.push(new BezierPatch([
[-55.36066,-31.15074,-831.4528],
[-57.02308,-31.09439,-829.2494],
[-58.6855,-31.03804,-827.046],
[-60.34792,-30.98169,-824.8426],
[-54.435,-31.37207,-830.7487],
[-56.40597,-31.24195,-828.78],
[-58.37694,-31.11182,-826.8113],
[-60.34792,-30.98169,-824.8426],
[-53.68459,-30.63595,-829.8901],
[-55.9057,-30.7512,-828.2076],
[-58.12681,-30.86645,-826.5251],
[-60.34792,-30.98169,-824.8426],
[-53.68459,-29.50656,-829.5351],
[-55.9057,-29.99827,-827.9709],
[-58.12681,-30.48998,-826.4068],
[-60.34792,-30.98169,-824.8426]
],0,1,[-60.34792,-31.18996,-960.9258],[53.55072,12.48399,-824.8426]));

P.push(new BezierPatch([
[-53.68459,-29.50656,-829.5351],
[-55.9057,-29.99827,-827.9709],
[-58.12681,-30.48998,-826.4068],
[-60.34792,-30.98169,-824.8426],
[-53.68459,-28.37718,-829.1801],
[-55.9057,-29.24535,-827.7343],
[-58.12681,-30.11352,-826.2884],
[-60.34792,-30.98169,-824.8426],
[-54.435,-27.28221,-829.463],
[-56.40597,-28.51537,-827.9229],
[-58.37694,-29.74853,-826.3828],
[-60.34792,-30.98169,-824.8426],
[-55.36066,-27.06088,-830.1671],
[-57.02308,-28.36782,-828.3923],
[-58.6855,-29.67476,-826.6174],
[-60.34792,-30.98169,-824.8426]
],0,1,[-60.34792,-31.18996,-960.9258],[53.55072,12.48399,-824.8426]));

P.push(new BezierPatch([
[-64.8206043675,-31.2132159483,-819.49215415],
[-64.6617483675,-31.3246759483,-819.49215415],
[-64.5028923675,-31.4361359483,-819.49215415],
[-64.3440363675,-31.5475959483,-819.49215415],
[-65.2018443675,-31.2850959483,-819.49215415],
[-64.9594857009,-31.5328399483,-819.49215415],
[-64.6421270342,-31.7170399483,-819.49215415],
[-64.3440363675,-31.8694719483,-819.49215415],
[-65.3440443675,-31.5710319483,-819.49215415],
[-65.1811990342,-31.8795399483,-819.49215415],
[-64.8683537009,-32.0609599483,-819.49215415],
[-64.5940443675,-32.1788439483,-819.49215415],
[-65.3440443675,-31.7976039483,-819.49215415],
[-65.3440443675,-32.0835399483,-819.49215415],
[-65.1190443675,-32.1788439483,-819.49215415],
[-64.9518483675,-32.1788439483,-819.49215415]
],1,0,[-65.3440443675,-32.1788439483,-960.925782665],[53.5507190116,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[-69.7440375675,-32.6444799483,-819.49215415],
[-69.7440375675,-32.6913519483,-819.49215415],
[-69.6523715675,-32.6403119483,-819.49215415],
[-69.6955995675,-32.7507159483,-819.49215415],
[-69.6113554675,-32.6995499483,-819.49215415],
[-69.5607055675,-32.6361439483,-819.49215415],
[-69.6127875675,-32.7507159483,-819.49215415],
[-69.5174739675,-32.7507159483,-819.49215415],
[-69.4924767675,-32.6804079483,-819.49215415],
[-69.4690395675,-32.6319759483,-819.49215415]
],1,0,[-69.7440375675,-32.7507159483,-960.925782665],[53.5507190116,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[-69.6596631675,-35.8038519483,-819.49215415],
[-69.8185167675,-35.6923919483,-819.49215415],
[-69.9773703675,-35.5809319483,-819.49215415],
[-70.1362239675,-35.4694719483,-819.49215415],
[-69.2315403675,-35.7194763483,-819.49215415],
[-69.4881363675,-35.471732615,-819.49215415],
[-69.8041079675,-35.2562800816,-819.49215415],
[-70.1362239675,-35.0882235483,-819.49215415],
[-69.1362243675,-35.3616591483,-819.49215415],
[-69.2765023675,-35.1125284816,-819.49215415],
[-69.5355315675,-34.927980215,-819.49215415],
[-69.8268495675,-34.8382239483,-819.49215415],
[-69.1362243675,-35.2194735483,-819.49215415],
[-69.1362243675,-34.9804119483,-819.49215415],
[-69.3143511675,-34.8382239483,-819.49215415],
[-69.5409111675,-34.8382239483,-819.49215415]
],1,0,[-70.1362239675,-35.8038519483,-960.925782665],[53.5507190116,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[-64.7487243675,-34.3725999483,-819.49215415],
[-64.7487243675,-34.3132239483,-819.49215415],
[-64.8403883675,-34.3887479483,-819.49215415],
[-64.7971563675,-34.2647799483,-819.49215415],
[-64.8803623675,-34.3141319483,-819.49215415],
[-64.9320523675,-34.4048959483,-819.49215415],
[-64.8674763675,-34.2647799483,-819.49215415],
[-64.9752843675,-34.2647799483,-819.49215415],
[-64.9877883675,-34.3257159483,-819.49215415],
[-65.0237163675,-34.4210439483,-819.49215415]
],1,0,[-70.1362239675,-35.8038519483,-960.925782665],[53.5507190116,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[-64.8206043675,-31.2132159483,-819.49215415],
[-64.6617483675,-31.3246759483,-819.49215415],
[-64.774396493,-31.1431399175,-819.49215415],
[-64.5028923675,-31.4361359483,-819.49215415],
[-64.5655836883,-31.2229721717,-819.49215415],
[-64.7281886185,-31.0730638868,-819.49215415],
[-64.3440363675,-31.5475959483,-819.49215415],
[-64.3440363675,-31.3018776749,-819.49215415],
[-64.482153525,-31.1221951459,-819.49215415],
[-64.6819807441,-31.0029878561,-819.49215415]
],1,0,[-70.1362239675,-35.8038519483,-960.925782665],[53.5507190116,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[-64.6819807441,-31.0029878561,-819.49215415],
[-64.7281886185,-31.0730638868,-819.49215415],
[-64.774396493,-31.1431399175,-819.49215415],
[-64.8206043675,-31.2132159483,-819.49215415],
[-64.8887655752,-30.8796299901,-819.49215415],
[-64.9493771726,-30.9392653095,-819.49215415],
[-65.0099887701,-30.9989006289,-819.49215415],
[-65.0706003675,-31.0585359483,-819.49215415],
[-65.1616325382,-30.8210319483,-819.49215415],
[-65.2427484813,-30.9001999483,-819.49215415],
[-65.3238644244,-30.9793679483,-819.49215415],
[-65.4049803675,-31.0585359483,-819.49215415],
[-65.4159123675,-30.8210319483,-819.49215415],
[-65.4200803675,-30.9001999483,-819.49215415],
[-65.4242483675,-30.9793679483,-819.49215415],
[-65.4284163675,-31.0585359483,-819.49215415]
],1,0,[-70.1362239675,-35.8038519483,-960.925782665],[53.5507190116,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[-69.2700129675,-31.6343184483,-819.49215415],
[-69.20431017,-31.6829431264,-819.49215415],
[-69.1386073724,-31.7315678046,-819.49215415],
[-69.0729045749,-31.7801924828,-819.49215415],
[-69.5830992675,-32.0573679483,-819.49215415],
[-69.4628994523,-32.0372404484,-819.49215415],
[-69.3426996371,-32.0171129486,-819.49215415],
[-69.2224998218,-31.9969854487,-819.49215415],
[-69.7440375675,-32.5132299483,-819.49215415],
[-69.6160412972,-32.4340038223,-819.49215415],
[-69.4880450268,-32.3547776963,-819.49215415],
[-69.3600487565,-32.2755515703,-819.49215415],
[-69.7440375675,-32.6444799483,-819.49215415],
[-69.6523715675,-32.6403119483,-819.49215415],
[-69.5607055675,-32.6361439483,-819.49215415],
[-69.4690395675,-32.6319759483,-819.49215415]
],1,0,[-70.1362239675,-35.8038519483,-960.925782665],[53.5507190116,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[-69.0729045749,-31.7801924828,-819.49215415],
[-69.1386073724,-31.7315678046,-819.49215415],
[-69.20431017,-31.6829431264,-819.49215415],
[-69.2700129675,-31.6343184483,-819.49215415],
[-68.667035762,-31.1920086632,-819.49215415],
[-68.7636660639,-31.1984287582,-819.49215415],
[-68.8602963657,-31.2048488533,-819.49215415],
[-68.9569266675,-31.2112689483,-819.49215415],
[-68.1724942578,-31.0585359483,-819.49215415],
[-68.2788936277,-30.9793679483,-819.49215415],
[-68.3852929976,-30.9001999483,-819.49215415],
[-68.4916923675,-30.8210319483,-819.49215415],
[-67.9190403675,-31.0585359483,-819.49215415],
[-67.9070603675,-30.9793679483,-819.49215415],
[-67.8950803675,-30.9001999483,-819.49215415],
[-67.8831003675,-30.8210319483,-819.49215415]
],1,0,[-70.1362239675,-35.8038519483,-960.925782665],[53.5507190116,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[-69.6596631675,-35.8038519483,-819.49215415],
[-69.8185167675,-35.6923919483,-819.49215415],
[-69.7063058407,-35.8745860984,-819.49215415],
[-69.9773703675,-35.5809319483,-819.49215415],
[-69.9151685713,-35.7950983899,-819.49215415],
[-69.7529485138,-35.9453202485,-819.49215415],
[-70.1362239675,-35.4694719483,-819.49215415],
[-70.1362239675,-35.7165535658,-819.49215415],
[-69.9996277093,-35.8968619471,-819.49215415],
[-69.799591187,-36.0160543987,-819.49215415]
],1,0,[-70.1362239675,-36.0160543987,-960.925782665],[53.5507190116,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[-69.799591187,-36.0160543987,-819.49215415],
[-69.7529485138,-35.9453202485,-819.49215415],
[-69.7063058407,-35.8745860984,-819.49215415],
[-69.6596631675,-35.8038519483,-819.49215415],
[-69.5948276508,-36.1380634577,-819.49215415],
[-69.5331054897,-36.0782219412,-819.49215415],
[-69.4713833286,-36.0183804248,-819.49215415],
[-69.4096611675,-35.9585389083,-819.49215415],
[-69.323590142,-36.1960340283,-819.49215415],
[-69.2528028838,-36.1168689883,-819.49215415],
[-69.1820156257,-36.0377039483,-819.49215415],
[-69.1112283675,-35.9585389083,-819.49215415],
[-69.0643443675,-36.1960340283,-819.49215415],
[-69.0601803675,-36.1168689883,-819.49215415],
[-69.0560163675,-36.0377039483,-819.49215415],
[-69.0518523675,-35.9585389083,-819.49215415]
],1,0,[-70.1362239675,-36.1960340283,-960.925782665],[53.5507190116,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[-66.6081003675,-36.1960340283,-819.49215415],
[-66.5961203675,-36.1168689883,-819.49215415],
[-66.5841403675,-36.0377039483,-819.49215415],
[-66.5721603675,-35.9585389083,-819.49215415],
[-65.3924763675,-36.1960340283,-819.49215415],
[-65.6070603675,-36.1168689883,-819.49215415],
[-65.8216443675,-36.0377039483,-819.49215415],
[-66.0362283675,-35.9585389083,-819.49215415],
[-64.7487243675,-34.6350999483,-819.49215415],
[-64.9471643675,-34.9132239483,-819.49215415],
[-65.1456043675,-35.1913479483,-819.49215415],
[-65.3440443675,-35.4694719483,-819.49215415],
[-64.7487243675,-34.3725999483,-819.49215415],
[-64.8403883675,-34.3887479483,-819.49215415],
[-64.9320523675,-34.4048959483,-819.49215415],
[-65.0237163675,-34.4210439483,-819.49215415]
],1,0,[-70.1362239675,-36.1960340283,-960.925782665],[53.5507190116,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[-66.5612283675,-32.2991559483,-819.49215415],
[-66.5930003675,-32.1199919483,-819.49215415],
[-66.6247723675,-31.9408279483,-819.49215415],
[-66.6565443675,-31.7616639483,-819.49215415],
[-66.3815403675,-31.5835359483,-819.49215415],
[-66.4372683675,-31.5673919483,-819.49215415],
[-66.4929963675,-31.5512479483,-819.49215415],
[-66.5487243675,-31.5351039483,-819.49215415],
[-65.9768523675,-31.0585359483,-819.49215415],
[-66.0325803675,-30.9793679483,-819.49215415],
[-66.0883083675,-30.9001999483,-819.49215415],
[-66.1440363675,-30.8210319483,-819.49215415],
[-65.4284163675,-31.0585359483,-819.49215415],
[-65.4242483675,-30.9793679483,-819.49215415],
[-65.4200803675,-30.9001999483,-819.49215415],
[-65.4159123675,-30.8210319483,-819.49215415]
],1,0,[-70.1362239675,-36.1960340283,-960.925782665],[53.5507190116,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[-66.6565443675,-31.7616639483,-819.49215415],
[-66.8627923675,-31.7897879483,-819.49215415],
[-67.0690403675,-31.8179119483,-819.49215415],
[-67.2752883675,-31.8460359483,-819.49215415],
[-66.9299763675,-30.8679159483,-819.49215415],
[-67.0450803675,-31.0986439483,-819.49215415],
[-67.1601843675,-31.3293719483,-819.49215415],
[-67.2752883675,-31.5600999483,-819.49215415],
[-67.6690443675,-30.8210319483,-819.49215415],
[-67.5737283675,-30.9001999483,-819.49215415],
[-67.4784123675,-30.9793679483,-819.49215415],
[-67.3830963675,-31.0585359483,-819.49215415],
[-67.8831003675,-30.8210319483,-819.49215415],
[-67.8950803675,-30.9001999483,-819.49215415],
[-67.9070603675,-30.9793679483,-819.49215415],
[-67.9190403675,-31.0585359483,-819.49215415]
],1,0,[-70.1362239675,-36.1960340283,-960.925782665],[53.5507190116,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[-67.8956043675,-34.5866559483,-819.49215415],
[-67.8758123675,-34.8095751483,-819.49215415],
[-67.8560203675,-35.0324943483,-819.49215415],
[-67.8362283675,-35.2554135483,-819.49215415],
[-68.0752923675,-35.3022891483,-819.49215415],
[-68.0789363675,-35.4491639483,-819.49215415],
[-68.0825803675,-35.5960387483,-819.49215415],
[-68.0862243675,-35.7429135483,-819.49215415],
[-68.4206043675,-35.9585389083,-819.49215415],
[-68.4445603675,-36.0377039483,-819.49215415],
[-68.4685163675,-36.1168689883,-819.49215415],
[-68.4924723675,-36.1960340283,-819.49215415],
[-69.0518523675,-35.9585389083,-819.49215415],
[-69.0560163675,-36.0377039483,-819.49215415],
[-69.0601803675,-36.1168689883,-819.49215415],
[-69.0643443675,-36.1960340283,-819.49215415]
],1,0,[-70.1362239675,-36.1960340283,-960.925782665],[53.5507190116,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[-67.8362283675,-35.2554135483,-819.49215415],
[-67.6294563675,-35.2236411483,-819.49215415],
[-67.4226843675,-35.1918687483,-819.49215415],
[-67.2159123675,-35.1600963483,-819.49215415],
[-67.6455963675,-35.9585389083,-819.49215415],
[-67.5023683675,-35.8548913883,-819.49215415],
[-67.3591403675,-35.7512438683,-819.49215415],
[-67.2159123675,-35.6475963483,-819.49215415],
[-67.0487283675,-36.1960340283,-819.49215415],
[-67.0289363675,-36.1168689883,-819.49215415],
[-67.0091443675,-36.0377039483,-819.49215415],
[-66.9893523675,-35.9585389083,-819.49215415],
[-66.6081003675,-36.1960340283,-819.49215415],
[-66.5961203675,-36.1168689883,-819.49215415],
[-66.5841403675,-36.0377039483,-819.49215415],
[-66.5721603675,-35.9585389083,-819.49215415]
],1,0,[-70.1362239675,-36.1960340283,-960.925782665],[53.5507190116,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[-66.5612283675,-32.2991559483,-819.49215415],
[-66.8711243675,-32.5173879483,-819.49215415],
[-67.1810203675,-32.7356199483,-819.49215415],
[-67.4909163675,-32.9538519483,-819.49215415],
[-66.5930003675,-32.1199919483,-819.49215415],
[-66.8443857009,-32.2073212816,-819.49215415],
[-67.0957710342,-32.294650615,-819.49215415],
[-67.3471563675,-32.3819799483,-819.49215415],
[-66.6247723675,-31.9408279483,-819.49215415],
[-66.8416110342,-31.996730615,-819.49215415],
[-67.0584497009,-32.0526332816,-819.49215415],
[-67.2752883675,-32.1085359483,-819.49215415],
[-66.6565443675,-31.7616639483,-819.49215415],
[-66.8627923675,-31.7897879483,-819.49215415],
[-67.0690403675,-31.8179119483,-819.49215415],
[-67.2752883675,-31.8460359483,-819.49215415]
],1,0,[-70.1362239675,-36.1960340283,-960.925782665],[53.5507190116,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[-67.8956043675,-34.5866559483,-819.49215415],
[-67.5898723675,-34.3642639483,-819.49215415],
[-67.2841403675,-34.1418719483,-819.49215415],
[-66.9784083675,-33.9194799483,-819.49215415],
[-67.8758123675,-34.8095751483,-819.49215415],
[-67.6402217009,-34.7753767483,-819.49215415],
[-67.4046310342,-34.7411783483,-819.49215415],
[-67.1690403675,-34.7069799483,-819.49215415],
[-67.8560203675,-35.0324943483,-819.49215415],
[-67.6426510342,-34.9875283483,-819.49215415],
[-67.4292817009,-34.9425623483,-819.49215415],
[-67.2159123675,-34.8975963483,-819.49215415],
[-67.8362283675,-35.2554135483,-819.49215415],
[-67.6294563675,-35.2236411483,-819.49215415],
[-67.4226843675,-35.1918687483,-819.49215415],
[-67.2159123675,-35.1600963483,-819.49215415]
],1,0,[-70.1362239675,-36.1960340283,-960.925782665],[53.5507190116,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[-66.97841,-33.91948,-819.4922],
[-66.56123,-32.29916,-819.4922],
[-67.49092,-32.95385,-819.4922],
[-67.8956,-34.58666,-819.4922]
],1,0,[-67.8956,-34.58666,-819.4922],[-66.56123,-32.29916,-819.4922]));

cylinder([74.78595,-24.70396,-844.8124],1,173.0998,0,0,
[2.207705,2.906895],true);

sphere([74.78595,-24.70396,-844.8124],1,0,0,
[0.9338881,-0.2346979]);

sphere([-60.56042,7.657937,-947.757],1,0,0,
[2.207705,2.906895]);

P.push(new BezierPatch([
[76.1222785474,-24.2013150816,-846.411327418],
[76.1222785474,-25.330698764,-846.766362905],
[75.5239833439,-26.4712856604,-846.338312106],
[74.7859488082,-26.748887218,-845.455249198],
[76.1222785474,-23.0719313991,-846.056291931],
[75.6302555236,-24.3863827866,-845.822618813],
[74.7859488082,-25.9959647631,-845.218558874],
[74.0479142725,-27.0264887757,-844.572186291],
[75.5239833439,-22.3814255555,-845.052614874],
[74.7859488082,-23.4119495681,-844.406242291],
[73.9416420928,-25.0215315446,-843.802182352],
[73.449619069,-26.3359829321,-843.568509234],
[74.7859488082,-22.6590271132,-844.169551966],
[74.0479142725,-22.9366286708,-843.286489059],
[73.449619069,-24.0772155672,-842.85843826],
[73.449619069,-25.2065992497,-843.213473747]
],0,1,[-70.1362239675,-36.1960340283,-960.925782665],[76.1222785474,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[74.78595,-26.74889,-845.4552],
[76.87101,-26.56579,-843.6551],
[78.95607,-26.38269,-841.8549],
[81.04113,-26.1996,-840.0547],
[75.52398,-26.47129,-846.3383],
[77.36303,-26.38072,-844.2438],
[79.20208,-26.29016,-842.1492],
[81.04113,-26.1996,-840.0547],
[76.12228,-25.3307,-846.7664],
[77.7619,-25.62033,-844.5291],
[79.40152,-25.90997,-842.2919],
[81.04113,-26.1996,-840.0547],
[76.12228,-24.20132,-846.4113],
[77.7619,-24.86741,-844.2925],
[79.40152,-25.5335,-842.1736],
[81.04113,-26.1996,-840.0547]
],0,1,[-70.13622,-36.19603,-960.9258],[81.04113,12.48399,-819.4922]));

P.push(new BezierPatch([
[76.12228,-24.20132,-846.4113],
[77.7619,-24.86741,-844.2925],
[79.40152,-25.5335,-842.1736],
[81.04113,-26.1996,-840.0547],
[76.12228,-23.07193,-846.0563],
[77.7619,-24.11449,-844.0558],
[79.40152,-25.15704,-842.0552],
[81.04113,-26.1996,-840.0547],
[75.52398,-22.38143,-845.0526],
[77.36303,-23.65415,-843.3866],
[79.20208,-24.92687,-841.7207],
[81.04113,-26.1996,-840.0547],
[74.78595,-22.65903,-844.1696],
[76.87101,-23.83922,-842.7979],
[78.95607,-25.01941,-841.4263],
[81.04113,-26.1996,-840.0547]
],0,1,[-70.13622,-36.19603,-960.9258],[81.04113,12.48399,-819.4922]));

P.push(new BezierPatch([
[73.44962,-25.2066,-843.2135],
[75.98012,-25.5376,-842.1605],
[78.51063,-25.8686,-841.1076],
[81.04113,-26.1996,-840.0547],
[73.44962,-26.33598,-843.5685],
[75.98012,-26.29052,-842.3972],
[78.51063,-26.24506,-841.226],
[81.04113,-26.1996,-840.0547],
[74.04791,-27.02649,-844.5722],
[76.37899,-26.75086,-843.0664],
[78.71006,-26.47523,-841.5605],
[81.04113,-26.1996,-840.0547],
[74.78595,-26.74889,-845.4552],
[76.87101,-26.56579,-843.6551],
[78.95607,-26.38269,-841.8549],
[81.04113,-26.1996,-840.0547]
],0,1,[-70.13622,-36.19603,-960.9258],[81.04113,12.48399,-819.4922]));

P.push(new BezierPatch([
[74.78595,-22.65903,-844.1696],
[76.87101,-23.83922,-842.7979],
[78.95607,-25.01941,-841.4263],
[81.04113,-26.1996,-840.0547],
[74.04791,-22.93663,-843.2865],
[76.37899,-24.02429,-842.2092],
[78.71006,-25.11194,-841.132],
[81.04113,-26.1996,-840.0547],
[73.44962,-24.07722,-842.8584],
[75.98012,-24.78468,-841.9239],
[78.51063,-25.49214,-840.9893],
[81.04113,-26.1996,-840.0547],
[73.44962,-25.2066,-843.2135],
[75.98012,-25.5376,-842.1605],
[78.51063,-25.8686,-841.1076],
[81.04113,-26.1996,-840.0547]
],0,1,[-70.13622,-36.19603,-960.9258],[81.04113,12.48399,-819.4922]));

P.push(new BezierPatch([
[85.1761209038,-29.2339664852,-840.054698993],
[85.2120609038,-29.2449104852,-840.054698993],
[85.4146653038,-29.0428232852,-840.054698993],
[85.2714357038,-29.2449104852,-840.054698993],
[85.5468293038,-29.1179546852,-840.054698993],
[85.6532097038,-28.8516800852,-840.054698993],
[85.2948729038,-29.2449104852,-840.054698993],
[85.6526901038,-29.2449104852,-840.054698993],
[85.8917541038,-28.9355264852,-840.054698993],
[85.8917541038,-28.6605368852,-840.054698993]
],2,0,[-70.1362239675,-36.1960340283,-960.925782665],[85.8917541038,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[89.9683101038,-22.9745984852,-840.054698993],
[89.7219581038,-22.9428264852,-840.054698993],
[89.4756061038,-22.9110544852,-840.054698993],
[89.2292541038,-22.8792824852,-840.054698993],
[89.9792541038,-22.9152224852,-840.054698993],
[89.7967861038,-22.7572344852,-840.054698993],
[89.5664021038,-22.6195584852,-840.054698993],
[89.3245581038,-22.5214664852,-840.054698993],
[90.0042501038,-22.8433424852,-840.054698993],
[89.9325501038,-22.6669544852,-840.054698993],
[89.7650181038,-22.5311904852,-840.054698993],
[89.5745661038,-22.4745944852,-840.054698993],
[90.0042501038,-22.7730344852,-840.054698993],
[90.0042501038,-22.5933464852,-840.054698993],
[89.8605021038,-22.4745944852,-840.054698993],
[89.6823741038,-22.4745944852,-840.054698993]
],2,0,[-70.1362239675,-36.1960340283,-960.925782665],[90.0042501038,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[84.6276837038,-24.1792904852,-840.054698993],
[84.6276837038,-24.2261624852,-840.054698993],
[84.7271637038,-24.1433504852,-840.054698993],
[84.6761229038,-24.2855264852,-840.054698993],
[84.7650547038,-24.2322764852,-840.054698993],
[84.8266437038,-24.1074104852,-840.054698993],
[84.7589337038,-24.2855264852,-840.054698993],
[84.8667465038,-24.2855264852,-840.054698993],
[84.8776857038,-24.2386544852,-840.054698993],
[84.9261237038,-24.0714704852,-840.054698993]
],2,0,[-70.1362239675,-36.1960340283,-960.925782665],[90.0042501038,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[85.1761209038,-29.2339664852,-840.054698993],
[85.0766425038,-29.1620944852,-840.054698993],
[84.9771641038,-29.0902224852,-840.054698993],
[84.8776857038,-29.0183504852,-840.054698993],
[85.4146653038,-29.0428232852,-840.054698993],
[85.2808118372,-28.7896970185,-840.054698993],
[85.0714343705,-28.5959483519,-840.054698993],
[84.8776857038,-28.4339696852,-840.054698993],
[85.6532097038,-28.8516800852,-840.054698993],
[85.6240435705,-28.5646979519,-840.054698993],
[85.4438294372,-28.3964710185,-840.054698993],
[85.2948729038,-28.2917840852,-840.054698993],
[85.8917541038,-28.6605368852,-840.054698993],
[85.8917541038,-28.3870940852,-840.054698993],
[85.6651821038,-28.2917840852,-840.054698993],
[85.4854965038,-28.2917840852,-840.054698993]
],2,0,[-70.1362239675,-36.1960340283,-960.925782665],[90.0042501038,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[86.1651861038,-22.3558424852,-840.054698993],
[86.1532061038,-22.4350104852,-840.054698993],
[86.1412261038,-22.5141784852,-840.054698993],
[86.1292461038,-22.5933464852,-840.054698993],
[85.0683093038,-22.3558424852,-840.054698993],
[85.2672669038,-22.4350104852,-840.054698993],
[85.4662245038,-22.5141784852,-840.054698993],
[85.6651821038,-22.5933464852,-840.054698993],
[84.6276837038,-24.0839744852,-840.054698993],
[84.8224761038,-23.7459544852,-840.054698993],
[85.0172685038,-23.4079344852,-840.054698993],
[85.2120609038,-23.0699144852,-840.054698993],
[84.6276837038,-24.1792904852,-840.054698993],
[84.7271637038,-24.1433504852,-840.054698993],
[84.8266437038,-24.1074104852,-840.054698993],
[84.9261237038,-24.0714704852,-840.054698993]
],2,0,[-70.1362239675,-36.1960340283,-960.925782665],[90.0042501038,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[86.0698701038,-29.8058504852,-840.054698993],
[86.0698701038,-29.8850184852,-840.054698993],
[86.0698701038,-29.9641864852,-840.054698993],
[86.0698701038,-30.0433544852,-840.054698993],
[85.9276821038,-29.8058504852,-840.054698993],
[85.7485169038,-29.8850184852,-840.054698993],
[85.5693517038,-29.9641864852,-840.054698993],
[85.3901865038,-30.0433544852,-840.054698993],
[85.3542465038,-29.7808544852,-840.054698993],
[85.1953929038,-29.7292904852,-840.054698993],
[85.0365393038,-29.6777264852,-840.054698993],
[84.8776857038,-29.6261624852,-840.054698993],
[85.1761209038,-29.2339664852,-840.054698993],
[85.0766425038,-29.1620944852,-840.054698993],
[84.9771641038,-29.0902224852,-840.054698993],
[84.8776857038,-29.0183504852,-840.054698993]
],2,0,[-70.1362239675,-36.1960340283,-960.925782665],[90.0042501038,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[87.1073661038,-23.3089664852,-840.054698993],
[86.8849741038,-23.2016744852,-840.054698993],
[86.6625821038,-23.0943824852,-840.054698993],
[86.4401901038,-22.9870904852,-840.054698993],
[87.1073661038,-22.8074144852,-840.054698993],
[86.8849741038,-22.7360584852,-840.054698993],
[86.6625821038,-22.6647024852,-840.054698993],
[86.4401901038,-22.5933464852,-840.054698993],
[86.7495621038,-22.3558424852,-840.054698993],
[86.5787301038,-22.4350104852,-840.054698993],
[86.4078981038,-22.5141784852,-840.054698993],
[86.2370661038,-22.5933464852,-840.054698993],
[86.1651861038,-22.3558424852,-840.054698993],
[86.1532061038,-22.4350104852,-840.054698993],
[86.1412261038,-22.5141784852,-840.054698993],
[86.1292461038,-22.5933464852,-840.054698993]
],2,0,[-70.1362239675,-36.1960340283,-960.925782665],[90.0042501038,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[86.9042541038,-24.0605264852,-840.054698993],
[86.6459181038,-24.0719864852,-840.054698993],
[86.3875821038,-24.0834464852,-840.054698993],
[86.1292461038,-24.0949064852,-840.054698993],
[87.0354981038,-23.7136544852,-840.054698993],
[86.7933101038,-23.6902184852,-840.054698993],
[86.5511221038,-23.6667824852,-840.054698993],
[86.3089341038,-23.6433464852,-840.054698993],
[87.1073661038,-23.5464704852,-840.054698993],
[86.8849741038,-23.4631384852,-840.054698993],
[86.6625821038,-23.3798064852,-840.054698993],
[86.4401901038,-23.2964744852,-840.054698993],
[87.1073661038,-23.3089664852,-840.054698993],
[86.8849741038,-23.2016744852,-840.054698993],
[86.6625821038,-23.0943824852,-840.054698993],
[86.4401901038,-22.9870904852,-840.054698993]
],2,0,[-70.1362239675,-36.1960340283,-960.925782665],[90.0042501038,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[86.2605021038,-26.5152224852,-840.054698993],
[86.0219581038,-26.4558464852,-840.054698993],
[85.7834141038,-26.3964704852,-840.054698993],
[85.5448701038,-26.3370944852,-840.054698993],
[86.2605021038,-25.8355304852,-840.054698993],
[86.0219581038,-25.9074064852,-840.054698993],
[85.7834141038,-25.9792824852,-840.054698993],
[85.5448701038,-26.0511584852,-840.054698993],
[86.5823661038,-24.9183464852,-840.054698993],
[86.2365341038,-25.1647024852,-840.054698993],
[85.8907021038,-25.4110584852,-840.054698993],
[85.5448701038,-25.6574144852,-840.054698993],
[86.9042541038,-24.0605264852,-840.054698993],
[86.6459181038,-24.0719864852,-840.054698993],
[86.3875821038,-24.0834464852,-840.054698993],
[86.1292461038,-24.0949064852,-840.054698993]
],2,0,[-70.1362239675,-36.1960340283,-960.925782665],[90.0042501038,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[86.9886261038,-27.4933494452,-840.054698993],
[86.9766461038,-27.5725144852,-840.054698993],
[86.9646661038,-27.6516795252,-840.054698993],
[86.9526861038,-27.7308445652,-840.054698993],
[86.3198661038,-27.4933494452,-840.054698993],
[86.3318461038,-27.5725144852,-840.054698993],
[86.3438261038,-27.6516795252,-840.054698993],
[86.3558061038,-27.7308445652,-840.054698993],
[86.2605021038,-26.8370996852,-840.054698993],
[86.0219581038,-27.0475144852,-840.054698993],
[85.7834141038,-27.2579292852,-840.054698993],
[85.5448701038,-27.4683440852,-840.054698993],
[86.2605021038,-26.5152224852,-840.054698993],
[86.0219581038,-26.4558464852,-840.054698993],
[85.7834141038,-26.3964704852,-840.054698993],
[85.5448701038,-26.3370944852,-840.054698993]
],2,0,[-70.1362239675,-36.1960340283,-960.925782665],[90.0042501038,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[88.1917461038,-26.7542840852,-840.054698993],
[88.1761221038,-26.8969916852,-840.054698993],
[88.1604981038,-27.0396992852,-840.054698993],
[88.1448741038,-27.1824068852,-840.054698993],
[87.9542541038,-27.0870968852,-840.054698993],
[87.8787301038,-27.2662644052,-840.054698993],
[87.8032061038,-27.4454319252,-840.054698993],
[87.7276821038,-27.6245994452,-840.054698993],
[87.5604981038,-27.4933494452,-840.054698993],
[87.4729981038,-27.5725144852,-840.054698993],
[87.3854981038,-27.6516795252,-840.054698993],
[87.2979981038,-27.7308445652,-840.054698993],
[86.9886261038,-27.4933494452,-840.054698993],
[86.9766461038,-27.5725144852,-840.054698993],
[86.9646661038,-27.6516795252,-840.054698993],
[86.9526861038,-27.7308445652,-840.054698993]
],2,0,[-70.1362239675,-36.1960340283,-960.925782665],[90.0042501038,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[88.3464381038,-26.3839664852,-840.054698993],
[88.2828941038,-26.6542808852,-840.054698993],
[88.2193501038,-26.9245952852,-840.054698993],
[88.1558061038,-27.1949096852,-840.054698993],
[88.2870621038,-26.5995968852,-840.054698993],
[88.2420954372,-26.7966452852,-840.054698993],
[88.1971287705,-26.9936936852,-840.054698993],
[88.1521621038,-27.1907420852,-840.054698993],
[88.2870621038,-26.6230340852,-840.054698993],
[88.2408807705,-26.8108808852,-840.054698993],
[88.1946994372,-26.9987276852,-840.054698993],
[88.1485181038,-27.1865744852,-840.054698993],
[88.1917461038,-26.7542840852,-840.054698993],
[88.1761221038,-26.8969916852,-840.054698993],
[88.1604981038,-27.0396992852,-840.054698993],
[88.1448741038,-27.1824068852,-840.054698993]
],2,0,[-70.1362239675,-36.1960340283,-960.925782665],[90.0042501038,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[88.79956,-27.6246,-840.0547],
[89.96831,-22.9746,-840.0547],
[89.22925,-22.87928,-840.0547],
[88.34644,-26.38397,-840.0547]
],2,0,[88.34644,-27.6246,-840.0547],[89.96831,-22.87928,-840.0547]));

P.push(new BezierPatch([
[88.7995581038,-27.6245994452,-840.054698993],
[88.6485181038,-27.2110551252,-840.054698993],
[88.5849741038,-27.4813695252,-840.054698993],
[88.4974781038,-26.7975108052,-840.054698993],
[88.4339341038,-27.0678252052,-840.054698993],
[88.3703901038,-27.3381396052,-840.054698993],
[88.3464381038,-26.3839664852,-840.054698993],
[88.2828941038,-26.6542808852,-840.054698993],
[88.2193501038,-26.9245952852,-840.054698993],
[88.1558061038,-27.1949096852,-840.054698993]
],2,0,[-70.1362239675,-36.1960340283,-960.925782665],[90.0042501038,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[87.4401861038,-28.9464704852,-840.054698993],
[87.8933101038,-28.5058468052,-840.054698993],
[88.3464341038,-28.0652231252,-840.054698993],
[88.7995581038,-27.6245994452,-840.054698993],
[87.1183101038,-29.3995904852,-840.054698993],
[87.5677901038,-29.2485504852,-840.054698993],
[88.0172701038,-29.0975104852,-840.054698993],
[88.4667501038,-28.9464704852,-840.054698993],
[86.6542461038,-29.8058504852,-840.054698993],
[86.8568501038,-29.8850184852,-840.054698993],
[87.0594541038,-29.9641864852,-840.054698993],
[87.2620581038,-30.0433544852,-840.054698993],
[86.0698701038,-29.8058504852,-840.054698993],
[86.0698701038,-29.8850184852,-840.054698993],
[86.0698701038,-29.9641864852,-840.054698993],
[86.0698701038,-30.0433544852,-840.054698993]
],2,0,[-70.1362239675,-36.1960340283,-960.925782665],[90.0042501038,12.4839865713,-819.49215415]));

P.push(new BezierPatch([
[88.7995581038,-27.6245994452,-840.054698993],
[88.5849741038,-27.4813695252,-840.054698993],
[88.3464341038,-28.0652231252,-840.054698993],
[88.3703901038,-27.3381396052,-840.054698993],
[88.1916161038,-27.9520709052,-840.054698993],
[87.8933101038,-28.5058468052,-840.054698993],
[88.1558061038,-27.1949096852,-840.054698993],
[87.9776901038,-27.8980340852,-840.054698993],
[87.8573661038,-28.3636568852,-840.054698993],
[87.4401861038,-28.9464704852,-840.054698993]
],2,0,[-70.1362239675,-36.1960340283,-960.925782665],[90.0042501038,12.4839865713,-819.49215415]));

cylinder([2.373604,80.9585,-872.1159],1,193.222,0,0,
[1.875377,-1.570796],true);

sphere([2.373604,80.9585,-872.1159],1,0,0,
[1.266216,1.570796]);

sphere([2.373604,-103.37,-930.0619],1,0,0,
[1.875377,-1.570796]);

P.push(new BezierPatch([
[1.03727436893,80.4558619776,-870.517018234],
[0.111605571091,80.6771933406,-871.221083149],
[-0.0405020520797,81.0816583877,-872.507704461],
[0.697532483629,81.3592599454,-873.390767369],
[1.96294316677,80.2345306146,-869.81295332],
[1.52929739274,80.6409296827,-871.105726839],
[1.31464501552,81.2117057034,-872.921390759],
[1.43556701934,81.636861503,-874.273830276],
[3.31164119694,80.2801466202,-869.958059864],
[3.43256320075,80.7053024199,-871.310499381],
[3.21791082354,81.2760784406,-873.126163301],
[2.7842650495,81.6824775087,-874.41893682],
[4.04967573265,80.5577481779,-870.841122771],
[4.78771026835,80.8353497355,-871.724185679],
[4.63560264518,81.2398147827,-873.010806991],
[3.70993384734,81.4611461457,-873.714871906]
],0,1,[-70.1362239675,-104.370155785,-960.925782665],[90.0042501038,81.958660367,-819.49215415]));

P.push(new BezierPatch([
[3.709934,81.46115,-873.7149],
[3.264491,83.83753,-872.3822],
[2.819047,86.21391,-871.0495],
[2.373604,88.59029,-869.7168],
[2.784265,81.68248,-874.4189],
[2.647378,83.98508,-872.8516],
[2.510491,86.28768,-871.2842],
[2.373604,88.59029,-869.7168],
[1.435567,81.63686,-874.2738],
[1.748246,83.95467,-872.7548],
[2.060925,86.27248,-871.2358],
[2.373604,88.59029,-869.7168],
[0.6975325,81.35926,-873.3908],
[1.256223,83.7696,-872.1661],
[1.814914,86.17994,-870.9415],
[2.373604,88.59029,-869.7168]
],0,1,[-70.13622,-104.3702,-960.9258],[90.00425,88.59029,-819.4922]));

P.push(new BezierPatch([
[4.049676,80.55775,-870.8411],
[3.490985,83.23526,-870.4663],
[2.932295,85.91277,-870.0916],
[2.373604,88.59029,-869.7168],
[4.78771,80.83535,-871.7242],
[3.983008,83.42033,-871.0551],
[3.178306,86.00531,-870.3859],
[2.373604,88.59029,-869.7168],
[4.635603,81.23981,-873.0108],
[3.881603,83.68997,-871.9128],
[3.127604,86.14013,-870.8148],
[2.373604,88.59029,-869.7168],
[3.709934,81.46115,-873.7149],
[3.264491,83.83753,-872.3822],
[2.819047,86.21391,-871.0495],
[2.373604,88.59029,-869.7168]
],0,1,[-70.13622,-104.3702,-960.9258],[90.00425,88.59029,-819.4922]));

P.push(new BezierPatch([
[0.6975325,81.35926,-873.3908],
[1.256223,83.7696,-872.1661],
[1.814914,86.17994,-870.9415],
[2.373604,88.59029,-869.7168],
[-0.04050205,81.08166,-872.5077],
[0.7642,83.58453,-871.5774],
[1.568902,86.08741,-870.6471],
[2.373604,88.59029,-869.7168],
[0.1116056,80.67719,-871.2211],
[0.8656051,83.31489,-870.7197],
[1.619605,85.95259,-870.2182],
[2.373604,88.59029,-869.7168],
[1.037274,80.45586,-870.517],
[1.482718,83.16734,-870.2503],
[1.928161,85.87881,-869.9835],
[2.373604,88.59029,-869.7168]
],0,1,[-70.13622,-104.3702,-960.9258],[90.00425,88.59029,-819.4922]));

P.push(new BezierPatch([
[1.037274,80.45586,-870.517],
[1.482718,83.16734,-870.2503],
[1.928161,85.87881,-869.9835],
[2.373604,88.59029,-869.7168],
[1.962943,80.23453,-869.813],
[2.09983,83.01978,-869.7809],
[2.236717,85.80503,-869.7489],
[2.373604,88.59029,-869.7168],
[3.311641,80.28015,-869.9581],
[2.998962,83.05019,-869.8776],
[2.686283,85.82024,-869.7972],
[2.373604,88.59029,-869.7168],
[4.049676,80.55775,-870.8411],
[3.490985,83.23526,-870.4663],
[2.932295,85.91277,-870.0916],
[2.373604,88.59029,-869.7168]
],0,1,[-70.13622,-104.3702,-960.9258],[90.00425,88.59029,-819.4922]));

P.push(new BezierPatch([
[4.85875970814,97.4565234311,-869.716801374],
[4.85875970814,97.5518394311,-869.716801374],
[4.76344770814,97.4450674311,-869.716801374],
[4.76344370814,97.5518394311,-869.716801374],
[4.72386370814,97.5213684311,-869.716801374],
[4.66813570814,97.4336114311,-869.716801374],
[4.74000770814,97.5518394311,-869.716801374],
[4.65719570814,97.5518394311,-869.716801374],
[4.63219970814,97.5049554311,-869.716801374],
[4.57282370814,97.4221554311,-869.716801374]
],3,0,[-70.1362239675,-104.370155785,-960.925782665],[90.0042501038,97.5518394311,-819.49215415]));

P.push(new BezierPatch([
[0.877507708137,96.2174634311,-869.716801374],
[0.877507708137,96.1815234311,-869.716801374],
[0.965007708137,96.2252754311,-869.716801374],
[0.890011708137,96.1096554311,-869.716801374],
[0.993003708137,96.1596514311,-869.716801374],
[1.05250770814,96.2330874311,-869.716801374],
[0.997819708137,96.1096554311,-869.716801374],
[1.09313570814,96.1096554311,-869.716801374],
[1.10406770814,96.1580874311,-869.716801374],
[1.14000770814,96.2408994311,-869.716801374]
],3,0,[-70.1362239675,-104.370155785,-960.925782665],[90.0042501038,97.5518394311,-819.49215415]));

P.push(new BezierPatch([
[-0.111551491863,92.2721474711,-869.716801374],
[-0.111551491863,92.2002749111,-869.716801374],
[-0.0120726918628,92.2919399511,-869.716801374],
[-0.0521766918628,92.1768373511,-869.716801374],
[0.0105831081372,92.2062636611,-869.716801374],
[0.0874061081372,92.3117324311,-869.716801374],
[0.0196985081372,92.1768373511,-869.716801374],
[0.0915713081372,92.1768373511,-869.716801374],
[0.102510508137,92.1877724711,-869.716801374],
[0.186884908137,92.3315249111,-869.716801374]
],3,0,[-70.1362239675,-104.370155785,-960.925782665],[90.0042501038,97.5518394311,-819.49215415]));

P.push(new BezierPatch([
[4.45407170814,93.9643314311,-869.716801374],
[4.45407170814,94.0127754311,-869.716801374],
[4.36292770814,93.9487074311,-869.716801374],
[4.44156770814,94.0830834311,-869.716801374],
[4.33362770814,94.0300904311,-869.716801374],
[4.27178370814,93.9330834311,-869.716801374],
[4.32282770814,94.0830834311,-869.716801374],
[4.22751170814,94.0830834311,-869.716801374],
[4.21500770814,94.0362114311,-869.716801374],
[4.18063970814,93.9174594311,-869.716801374]
],3,0,[-70.1362239675,-104.370155785,-960.925782665],[90.0042501038,97.5518394311,-819.49215415]));

P.push(new BezierPatch([
[2.27281970814,97.5518394311,-869.716801374],
[2.22907170814,97.3018394311,-869.716801374],
[2.18532370814,97.0518394311,-869.716801374],
[2.14157570814,96.8018394311,-869.716801374],
[1.40250770814,97.5518394311,-869.716801374],
[1.57333970814,97.3018394311,-869.716801374],
[1.74417170814,97.0518394311,-869.716801374],
[1.91500370814,96.8018394311,-869.716801374],
[0.877507708137,96.4674594311,-869.716801374],
[1.03271570814,96.5507914311,-869.716801374],
[1.18792370814,96.6341234311,-869.716801374],
[1.34313170814,96.7174554311,-869.716801374],
[0.877507708137,96.2174634311,-869.716801374],
[0.965007708137,96.2252754311,-869.716801374],
[1.05250770814,96.2330874311,-869.716801374],
[1.14000770814,96.2408994311,-869.716801374]
],3,0,[-70.1362239675,-104.370155785,-960.925782665],[90.0042501038,97.5518394311,-819.49215415]));

P.push(new BezierPatch([
[2.61813170814,92.1768373511,-869.716801374],
[2.66188370814,92.4268375111,-869.716801374],
[2.70563570814,92.6768376711,-869.716801374],
[2.74938770814,92.9268378311,-869.716801374],
[3.78688370814,92.1768373511,-869.716801374],
[3.59625970814,92.4268375111,-869.716801374],
[3.40563570814,92.6768376711,-869.716801374],
[3.21501170814,92.9268378311,-869.716801374],
[4.45407170814,93.6908994311,-869.716801374],
[4.27542770814,93.5315246311,-869.716801374],
[4.09678370814,93.3721498311,-869.716801374],
[3.91813970814,93.2127750311,-869.716801374],
[4.45407170814,93.9643314311,-869.716801374],
[4.36292770814,93.9487074311,-869.716801374],
[4.27178370814,93.9330834311,-869.716801374],
[4.18063970814,93.9174594311,-869.716801374]
],3,0,[-70.1362239675,-104.370155785,-960.925782665],[90.0042501038,97.5518394311,-819.49215415]));

P.push(new BezierPatch([
[3.07126370814,97.0518354311,-869.716801374],
[3.00771970814,96.9049594311,-869.716801374],
[2.94417570814,96.7580834311,-869.716801374],
[2.88063170814,96.6112074311,-869.716801374],
[2.82125570814,97.3502754311,-869.716801374],
[2.75771570814,97.1393354311,-869.716801374],
[2.69417570814,96.9283954311,-869.716801374],
[2.63063570814,96.7174554311,-869.716801374],
[2.61813170814,97.5518394311,-869.716801374],
[2.54261170814,97.3018394311,-869.716801374],
[2.46709170814,97.0518394311,-869.716801374],
[2.39157170814,96.8018394311,-869.716801374],
[2.27281970814,97.5518394311,-869.716801374],
[2.22907170814,97.3018394311,-869.716801374],
[2.18532370814,97.0518394311,-869.716801374],
[2.14157570814,96.8018394311,-869.716801374]
],3,0,[-70.1362239675,-104.370155785,-960.925782665],[90.0042501038,97.5518394311,-819.49215415]));

P.push(new BezierPatch([
[1.80719570814,92.6893350311,-869.716801374],
[1.87490370814,92.8320426311,-869.716801374],
[1.94261170814,92.9747502311,-869.716801374],
[2.01031970814,93.1174578311,-869.716801374],
[2.03375570814,92.4268350311,-869.716801374],
[2.10927970814,92.6216274311,-869.716801374],
[2.18480370814,92.8164198311,-869.716801374],
[2.26032770814,93.0112122311,-869.716801374],
[2.23689170814,92.1768373511,-869.716801374],
[2.32439170814,92.4268375111,-869.716801374],
[2.41189170814,92.6768376711,-869.716801374],
[2.49939170814,92.9268378311,-869.716801374],
[2.61813170814,92.1768373511,-869.716801374],
[2.66188370814,92.4268375111,-869.716801374],
[2.70563570814,92.6768376711,-869.716801374],
[2.74938770814,92.9268378311,-869.716801374]
],3,0,[-70.1362239675,-104.370155785,-960.925782665],[90.0042501038,97.5518394311,-819.49215415]));

P.push(new BezierPatch([
[3.67907570814,96.6580914311,-869.716801374],
[3.66709570814,96.5789234311,-869.716801374],
[3.65511570814,96.4997554311,-869.716801374],
[3.64313570814,96.4205874311,-869.716801374],
[3.42906770814,96.6580914311,-869.716801374],
[3.46083970814,96.5789234311,-869.716801374],
[3.49261170814,96.4997554311,-869.716801374],
[3.52438370814,96.4205874311,-869.716801374],
[3.29782370814,96.7658994311,-869.716801374],
[3.30980370814,96.6507954311,-869.716801374],
[3.32178370814,96.5356914311,-869.716801374],
[3.33376370814,96.4205874311,-869.716801374],
[3.07126370814,97.0518354311,-869.716801374],
[3.00771970814,96.9049594311,-869.716801374],
[2.94417570814,96.7580834311,-869.716801374],
[2.88063170814,96.6112074311,-869.716801374]
],3,0,[-70.1362239675,-104.370155785,-960.925782665],[90.0042501038,97.5518394311,-819.49215415]));

P.push(new BezierPatch([
[1.19938370814,93.0705822311,-869.716801374],
[1.21553170814,93.1497498311,-869.716801374],
[1.23167970814,93.2289174311,-869.716801374],
[1.24782770814,93.3080850311,-869.716801374],
[1.44939170814,93.0705822311,-869.716801374],
[1.43376370814,93.1497498311,-869.716801374],
[1.41813570814,93.2289174311,-869.716801374],
[1.40250770814,93.3080850311,-869.716801374],
[1.56970370814,92.9862078311,-869.716801374],
[1.55772370814,93.0935002311,-869.716801374],
[1.54574370814,93.2007926311,-869.716801374],
[1.53376370814,93.3080850311,-869.716801374],
[1.80719570814,92.6893350311,-869.716801374],
[1.87490370814,92.8320426311,-869.716801374],
[1.94261170814,92.9747502311,-869.716801374],
[2.01031970814,93.1174578311,-869.716801374]
],3,0,[-70.1362239675,-104.370155785,-960.925782665],[90.0042501038,97.5518394311,-819.49215415]));

P.push(new BezierPatch([
[3.67907570814,96.6580914311,-869.716801374],
[3.66709570814,96.5789234311,-869.716801374],
[3.73063570814,96.5825674311,-869.716801374],
[3.65511570814,96.4997554311,-869.716801374],
[3.72490770814,96.5006664311,-869.716801374],
[3.78219570814,96.5070434311,-869.716801374],
[3.64313570814,96.4205874311,-869.716801374],
[3.72750770814,96.4205874311,-869.716801374],
[3.77439170814,96.4205874311,-869.716801374],
[3.83375570814,96.4315194311,-869.716801374]
],3,0,[-70.1362239675,-104.370155785,-960.925782665],[90.0042501038,97.5518394311,-819.49215415]));

P.push(new BezierPatch([
[1.19938370814,93.0705822311,-869.716801374],
[1.21553170814,93.1497498311,-869.716801374],
[1.09625970814,93.1341246311,-869.716801374],
[1.23167970814,93.2289174311,-869.716801374],
[1.10927870814,93.2250111311,-869.716801374],
[0.993135708137,93.1976670311,-869.716801374],
[1.24782770814,93.3080850311,-869.716801374],
[1.12750370814,93.3080850311,-869.716801374],
[0.997819708137,93.3080850311,-869.716801374],
[0.890011708137,93.2612094311,-869.716801374]
],3,0,[-70.1362239675,-104.370155785,-960.925782665],[90.0042501038,97.5518394311,-819.49215415]));

P.push(new BezierPatch([
[4.85875970814,97.4565234311,-869.716801374],
[4.51709170814,97.1148554311,-869.716801374],
[4.17542370814,96.7731874311,-869.716801374],
[3.83375570814,96.4315194311,-869.716801374],
[4.76344770814,97.4450674311,-869.716801374],
[4.43670904147,97.0459327644,-869.716801374],
[4.1130983748,96.7348220978,-869.716801374],
[3.78219570814,96.5070434311,-869.716801374],
[4.66813570814,97.4336114311,-869.716801374],
[4.3563263748,96.9770100978,-869.716801374],
[4.05077304147,96.6964567644,-869.716801374],
[3.73063570814,96.5825674311,-869.716801374],
[4.57282370814,97.4221554311,-869.716801374],
[4.27594370814,96.9080874311,-869.716801374],
[3.98844770814,96.6580914311,-869.716801374],
[3.67907570814,96.6580914311,-869.716801374]
],3,0,[-70.1362239675,-104.370155785,-960.925782665],[90.0042501038,97.5518394311,-819.49215415]));

P.push(new BezierPatch([
[-0.111551491863,92.2721474711,-869.716801374],
[0.222302908137,92.6018347911,-869.716801374],
[0.556157308137,92.9315221111,-869.716801374],
[0.890011708137,93.2612094311,-869.716801374],
[-0.0120726918628,92.2919399511,-869.716801374],
[0.274038108137,92.6112091378,-869.716801374],
[0.623690108137,92.9778754844,-869.716801374],
[0.993135708137,93.1976670311,-869.716801374],
[0.0874061081372,92.3117324311,-869.716801374],
[0.325773308137,92.6205834844,-869.716801374],
[0.691222908137,93.0242288578,-869.716801374],
[1.09625970814,93.1341246311,-869.716801374],
[0.186884908137,92.3315249111,-869.716801374],
[0.377508508137,92.6299578311,-869.716801374],
[0.758755708137,93.0705822311,-869.716801374],
[1.19938370814,93.0705822311,-869.716801374]
],3,0,[-70.1362239675,-104.370155785,-960.925782665],[90.0042501038,97.5518394311,-819.49215415]));

P.push(new BezierPatch([
[3.60719570814,95.8471554311,-869.716801374],
[4.44156770814,96.6705834311,-869.716801374],
[3.68271570814,96.0419434311,-869.716801374],
[4.85875970814,97.3612074311,-869.716801374],
[4.30849670814,96.7604274311,-869.716801374],
[3.75823570814,96.2367314311,-869.716801374],
[4.85875970814,97.4565234311,-869.716801374],
[4.51709170814,97.1148554311,-869.716801374],
[4.17542370814,96.7731874311,-869.716801374],
[3.83375570814,96.4315194311,-869.716801374]
],3,0,[-70.1362239675,-104.370155785,-960.925782665],[90.0042501038,97.5518394311,-819.49215415]));

P.push(new BezierPatch([
[1.05719570814,93.7971474311,-869.716801374],
[0.329074108137,93.0580878311,-869.716801374],
[1.00146770814,93.6185014311,-869.716801374],
[-0.111551491863,92.3549623511,-869.716801374],
[0.429854908137,92.9461069311,-869.716801374],
[0.945739708137,93.4398554311,-869.716801374],
[-0.111551491863,92.2721474711,-869.716801374],
[0.222302908137,92.6018347911,-869.716801374],
[0.556157308137,92.9315221111,-869.716801374],
[0.890011708137,93.2612094311,-869.716801374]
],3,0,[-70.1362239675,-104.370155785,-960.925782665],[90.0042501038,97.5518394311,-819.49215415]));

P.push(new BezierPatch([
[2.41500770814,94.7518314311,-869.716801374],
[2.36344770814,94.8033954311,-869.716801374],
[2.31188770814,94.8549594311,-869.716801374],
[2.26032770814,94.9065234311,-869.716801374],
[3.13063970814,95.3705874311,-869.716801374],
[3.01969970814,95.3789194311,-869.716801374],
[2.90875970814,95.3872514311,-869.716801374],
[2.79781970814,95.3955834311,-869.716801374],
[3.44157170814,95.6690274311,-869.716801374],
[3.44157170814,95.7685034311,-869.716801374],
[3.44157170814,95.8679794311,-869.716801374],
[3.44157170814,95.9674554311,-869.716801374],
[3.60719570814,95.8471554311,-869.716801374],
[3.68271570814,96.0419434311,-869.716801374],
[3.75823570814,96.2367314311,-869.716801374],
[3.83375570814,96.4315194311,-869.716801374]
],3,0,[-70.1362239675,-104.370155785,-960.925782665],[90.0042501038,97.5518394311,-819.49215415]));

P.push(new BezierPatch([
[2.26032770814,94.9065234311,-869.716801374],
[2.31188770814,94.8549594311,-869.716801374],
[2.36344770814,94.8033954311,-869.716801374],
[2.41500770814,94.7518314311,-869.716801374],
[1.85928370814,94.5367314311,-869.716801374],
[1.84608904147,94.4292634311,-869.716801374],
[1.8328943748,94.3217954311,-869.716801374],
[1.81969970814,94.2143274311,-869.716801374],
[1.45823970814,94.1669394311,-869.716801374],
[1.4396623748,94.0598234311,-869.716801374],
[1.42108504147,93.9527074311,-869.716801374],
[1.40250770814,93.8455914311,-869.716801374],
[1.05719570814,93.7971474311,-869.716801374],
[1.00146770814,93.6185014311,-869.716801374],
[0.945739708137,93.4398554311,-869.716801374],
[0.890011708137,93.2612094311,-869.716801374]
],3,0,[-70.1362239675,-104.370155785,-960.925782665],[90.0042501038,97.5518394311,-819.49215415]));

Materials.push(new Material(
diffuse=[0,0.5,1,0.9],
emissive=[0,0,0,1],
specular=[0.75,0.75,0.75,1],
shininess=0.7,
metallic=0,
fresnel0=0.04
));

P.push(new BezierPatch([
[-8.257069,-74.62477,-1019.833],
[-4.760735,-69.18446,-1015.2],
[-1.2644,-62.88343,-1010.297],
[2.231935,-56.08742,-1005.239],
[-11.04469,-75.61417,-1016.479],
[-7.548358,-70.59255,-1011.978],
[-4.052023,-64.63399,-1007.183],
[-0.5556883,-58.08649,-1002.202],
[-13.83232,-76.02431,-1012.943],
[-10.33598,-71.51752,-1008.604],
[-6.839646,-66.0723,-1003.97],
[-3.343311,-59.93699,-999.1189],
[-16.61994,-75.33937,-1009.062],
[-13.1236,-71.39378,-1004.9],
[-9.627269,-66.55992,-1000.458],
[-6.130934,-60.96457,-995.7769]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[-16.61994,-75.33937,-1009.062],
[-13.1236,-71.39378,-1004.9],
[-9.627269,-66.55992,-1000.458],
[-6.130934,-60.96457,-995.7769],
[-19.40756,-74.65443,-1005.182],
[-15.91123,-71.27004,-1001.196],
[-12.41489,-67.04753,-996.9463],
[-8.918558,-61.99215,-992.4349],
[-22.19518,-72.87441,-1000.957],
[-18.69885,-70.09758,-997.1622],
[-15.20252,-66.58445,-993.1357],
[-11.70618,-62.19681,-988.8342],
[-24.98281,-69.48349,-996.2262],
[-21.48647,-67.31083,-992.6211],
[-17.99014,-64.53221,-988.8255],
[-14.4938,-60.90418,-984.7629]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[2.231935,-56.08742,-1005.239],
[5.728269,-49.29142,-1000.18],
[9.224604,-42.00043,-994.9662],
[12.72094,-34.58019,-989.7114],
[-0.5556883,-58.08649,-1002.202],
[2.940646,-51.53898,-997.2218],
[6.436981,-44.40254,-992.0563],
[9.933316,-37.02513,-986.815],
[-3.343311,-59.93699,-999.1189],
[0.1530232,-53.80168,-994.2681],
[3.649358,-46.97626,-989.2003],
[7.145692,-39.70907,-983.9937],
[-6.130934,-60.96457,-995.7769],
[-2.6346,-55.36922,-991.0958],
[0.8617348,-49.01238,-986.1754],
[4.358069,-42.02084,-981.0554]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[-6.130934,-60.96457,-995.7769],
[-2.6346,-55.36922,-991.0958],
[0.8617348,-49.01238,-986.1754],
[4.358069,-42.02084,-981.0554],
[-8.918558,-61.99215,-992.4349],
[-5.422223,-56.93677,-987.9236],
[-1.925888,-51.04851,-983.1504],
[1.570446,-44.3326,-978.1171],
[-11.70618,-62.19681,-988.8342],
[-8.209846,-57.80917,-984.5328],
[-4.713511,-52.54702,-979.9565],
[-1.217177,-46.27219,-975.0618],
[-14.4938,-60.90418,-984.7629],
[-10.99747,-57.27615,-980.7002],
[-7.501134,-52.79872,-976.3706],
[-4.0048,-47.22843,-971.6974]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[-24.98281,-69.48349,-996.2262],
[-21.48647,-67.31083,-992.6211],
[-17.99014,-64.53221,-988.8255],
[-14.4938,-60.90418,-984.7629],
[-27.77043,-66.09258,-991.4952],
[-24.2741,-64.52408,-988.08],
[-20.77776,-62.47997,-984.5153],
[-17.28143,-59.61155,-980.6915],
[-30.55805,-61.09076,-986.2578],
[-27.06172,-60.12303,-983.0315],
[-23.56538,-58.83858,-979.7056],
[-20.06905,-56.82164,-976.1494],
[-33.34568,-54.18632,-980.4223],
[-29.84934,-53.6954,-977.3459],
[-26.35301,-53.01185,-974.2089],
[-22.85667,-51.73577,-970.8856]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[12.72094,-34.58019,-989.7114],
[16.21727,-27.15995,-984.4566],
[19.71361,-19.61046,-979.1612],
[23.20994,-12.89701,-974.1287],
[9.933316,-37.02513,-986.815],
[13.42965,-29.64773,-981.5737],
[16.92598,-22.02937,-976.2566],
[20.42232,-15.08901,-971.1527],
[7.145692,-39.70907,-983.9937],
[10.64203,-32.44188,-978.787],
[14.13836,-24.73292,-973.4415],
[17.6347,-17.57819,-968.2702],
[4.358069,-42.02084,-981.0554],
[7.854404,-35.02929,-975.9354],
[11.35074,-27.40303,-970.6159],
[14.84707,-20.12608,-965.4061]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[-14.4938,-60.90418,-984.7629],
[-10.99747,-57.27615,-980.7002],
[-7.501134,-52.79872,-976.3706],
[-4.0048,-47.22843,-971.6974],
[-17.28143,-59.61155,-980.6915],
[-13.78509,-56.74313,-976.8676],
[-10.28876,-53.05041,-972.7847],
[-6.792423,-48.18468,-968.333],
[-20.06905,-56.82164,-976.1494],
[-16.57272,-54.8047,-972.5933],
[-13.07638,-52.05527,-968.8068],
[-9.580046,-48.15758,-964.6594],
[-22.85667,-51.73577,-970.8856],
[-19.36034,-50.45969,-967.5623],
[-15.864,-48.59108,-964.0528],
[-12.36767,-45.73005,-960.2313]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[4.358069,-42.02084,-981.0554],
[7.854404,-35.02929,-975.9354],
[11.35074,-27.40303,-970.6159],
[14.84707,-20.12608,-965.4061],
[1.570446,-44.3326,-978.1171],
[5.066781,-37.61669,-973.0837],
[8.563116,-30.07314,-967.7902],
[12.05945,-22.67396,-962.5421],
[-1.217177,-46.27219,-975.0618],
[2.279158,-39.99735,-970.1671],
[5.775492,-32.70982,-964.9541],
[9.271827,-25.28055,-959.6965],
[-4.0048,-47.22843,-971.6974],
[-0.5084652,-41.65815,-967.0242],
[2.987869,-34.99502,-962.0074],
[6.484204,-27.70735,-956.7943]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[-33.34568,-54.18632,-980.4223],
[-29.84934,-53.6954,-977.3459],
[-26.35301,-53.01185,-974.2089],
[-22.85667,-51.73577,-970.8856],
[-36.1333,-47.28188,-974.5868],
[-32.63697,-47.26778,-971.6602],
[-29.14063,-47.18512,-968.7121],
[-25.6443,-46.64989,-965.6218],
[-38.92092,-38.4748,-968.1532],
[-35.42459,-38.81359,-965.3375],
[-31.92825,-39.17305,-962.5284],
[-28.43192,-39.26806,-959.6362],
[-41.70855,-28.26831,-961.2796],
[-38.21221,-28.80759,-958.527],
[-34.71588,-29.39386,-955.7892],
[-31.21954,-29.82244,-953.0018]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[23.20994,-12.89701,-974.1287],
[26.70628,-6.183568,-969.0961],
[30.20261,-0.3061694,-964.3263],
[33.69895,3.533229,-960.1973],
[20.42232,-15.08901,-971.1527],
[23.91865,-8.148642,-966.0488],
[27.41499,-1.88627,-961.158],
[30.91132,2.182266,-956.9569],
[17.6347,-17.57819,-968.2702],
[21.13103,-10.42346,-963.0989],
[24.62737,-3.82298,-958.1018],
[28.1237,0.5521297,-953.8044],
[14.84707,-20.12608,-965.4061],
[18.34341,-12.84912,-960.1964],
[21.83974,-5.921475,-955.0965],
[25.33608,-1.202083,-950.6908]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[-4.0048,-47.22843,-971.6974],
[-0.5084652,-41.65815,-967.0242],
[2.987869,-34.99502,-962.0074],
[6.484204,-27.70735,-956.7943],
[-6.792423,-48.18468,-968.333],
[-3.296088,-43.31895,-963.8812],
[0.2002463,-37.28021,-959.0608],
[3.696581,-30.13416,-953.8922],
[-9.580046,-48.15758,-964.6594],
[-6.083711,-44.25988,-960.512],
[-2.587377,-39.21392,-956.0036],
[0.9089579,-32.38118,-950.9335],
[-12.36767,-45.73005,-960.2313],
[-8.871334,-42.86902,-956.4097],
[-5.375,-39.01558,-952.2762],
[-1.878665,-33.31143,-947.561]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[-22.85667,-51.73577,-970.8856],
[-19.36034,-50.45969,-967.5623],
[-15.864,-48.59108,-964.0528],
[-12.36767,-45.73005,-960.2313],
[-25.6443,-46.64989,-965.6218],
[-22.14796,-46.11467,-962.5314],
[-18.65163,-45.12688,-959.2987],
[-15.15529,-43.30252,-955.8031],
[-28.43192,-39.26806,-959.6362],
[-24.93558,-39.36307,-956.7439],
[-21.43925,-39.19362,-953.7685],
[-17.94292,-38.47457,-950.6204],
[-31.21954,-29.82244,-953.0018],
[-27.72321,-30.25102,-950.2144],
[-24.22687,-30.52192,-947.3774],
[-20.73054,-30.43048,-944.4266]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[14.84707,-20.12608,-965.4061],
[18.34341,-12.84912,-960.1964],
[21.83974,-5.921475,-955.0965],
[25.33608,-1.202083,-950.6908],
[12.05945,-22.67396,-962.5421],
[15.55578,-15.27478,-957.2939],
[19.05212,-8.019971,-952.0912],
[22.54845,-2.956296,-947.5772],
[9.271827,-25.28055,-959.6965],
[12.76816,-17.85127,-954.4389],
[16.2645,-10.28025,-949.1367],
[19.76083,-4.834585,-944.5027],
[6.484204,-27.70735,-956.7943],
[9.980539,-20.41969,-951.5812],
[13.47687,-12.50749,-946.1718],
[16.97321,-6.681855,-941.4183]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[33.69895,3.533229,-960.1973],
[37.19528,7.372627,-956.0682],
[40.69162,9.174024,-952.5798],
[44.18795,8.338036,-949.9205],
[30.91132,2.182266,-956.9569],
[34.40766,6.250801,-952.7558],
[37.90399,8.125498,-949.2444],
[41.40033,7.28951,-946.585],
[28.1237,0.5521297,-953.8044],
[31.62003,4.927239,-949.5069],
[35.11637,7.076972,-945.909],
[38.6127,6.240984,-943.2496],
[25.33608,-1.202083,-950.6908],
[28.83241,3.517309,-946.2851],
[32.32875,6.028446,-942.5735],
[35.82508,5.192457,-939.9142]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[-12.36767,-45.73005,-960.2313],
[-8.871334,-42.86902,-956.4097],
[-5.375,-39.01558,-952.2762],
[-1.878665,-33.31143,-947.561],
[-15.15529,-43.30252,-955.8031],
[-11.65896,-41.47817,-952.3075],
[-8.162623,-38.81724,-948.5489],
[-4.666288,-34.24168,-944.1884],
[-17.94292,-38.47457,-950.6204],
[-14.44658,-37.75552,-947.4722],
[-10.95025,-36.48687,-944.1513],
[-7.453911,-33.85515,-940.4018],
[-20.73054,-30.43048,-944.4266],
[-17.2342,-30.33904,-941.4757],
[-13.73787,-29.88525,-938.4109],
[-10.24153,-28.47944,-935.0469]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[-41.70855,-28.26831,-961.2796],
[-38.21221,-28.80759,-958.527],
[-34.71588,-29.39386,-955.7892],
[-31.21954,-29.82244,-953.0018],
[-44.49617,-18.06183,-954.406],
[-40.99983,-18.8016,-951.7165],
[-37.5035,-19.61466,-949.05],
[-34.00717,-20.37682,-946.3674],
[-47.28379,-6.455927,-947.0926],
[-43.78746,-7.243809,-944.4181],
[-40.29112,-8.068333,-941.7552],
[-36.79479,-8.867406,-939.0843],
[-50.07142,5.149972,-939.7791],
[-46.57508,4.313983,-937.1198],
[-43.07875,3.477995,-934.4604],
[-39.58241,2.642006,-931.8011]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[6.484204,-27.70735,-956.7943],
[9.980539,-20.41969,-951.5812],
[13.47687,-12.50749,-946.1718],
[16.97321,-6.681855,-941.4183],
[3.696581,-30.13416,-953.8922],
[7.192916,-22.98811,-948.7236],
[10.68925,-14.73474,-943.207],
[14.18558,-8.529125,-938.334],
[0.9089579,-32.38118,-950.9335],
[4.405293,-25.54845,-945.8635],
[7.901627,-16.92894,-940.2317],
[11.39796,-10.34538,-935.24],
[-1.878665,-33.31143,-947.561],
[1.617669,-27.60728,-942.8457],
[5.114004,-20.05242,-937.5486],
[8.610339,-13.21628,-932.4774]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[44.18795,8.338036,-949.9205],
[47.68428,7.502047,-947.2611],
[51.18062,4.028673,-945.4309],
[54.67695,-1.482703,-944.2414],
[41.40033,7.28951,-946.585],
[44.89666,6.453521,-943.9257],
[48.393,2.906847,-942.1185],
[51.88933,-2.833666,-941.001],
[38.6127,6.240984,-943.2496],
[42.10904,5.404995,-940.5903],
[45.60537,1.583285,-938.8696],
[49.10171,-4.463802,-937.8485],
[35.82508,5.192457,-939.9142],
[39.32142,4.356469,-937.2549],
[42.81775,0.1733552,-935.6478],
[46.31408,-6.218014,-934.7349]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[25.33608,-1.202083,-950.6908],
[28.83241,3.517309,-946.2851],
[32.32875,6.028446,-942.5735],
[35.82508,5.192457,-939.9142],
[22.54845,-2.956296,-947.5772],
[26.04479,2.10738,-943.0633],
[29.54112,4.97992,-939.2381],
[33.03746,4.143931,-936.5788],
[19.76083,-4.834585,-944.5027],
[23.25717,0.6110822,-939.8686],
[26.7535,3.931394,-935.9027],
[30.24983,3.095405,-933.2434],
[16.97321,-6.681855,-941.4183],
[20.46954,-0.8562153,-936.6649],
[23.96588,2.882868,-932.5673],
[27.46221,2.046879,-929.908]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[54.67695,-1.482703,-944.2414],
[58.17329,-6.994078,-943.0518],
[61.66962,-14.54345,-942.5029],
[65.16596,-22.92887,-942.2169],
[51.88933,-2.833666,-941.001],
[55.38567,-8.574178,-939.8835],
[58.882,-16.50853,-939.4556],
[62.37833,-25.12087,-939.2409],
[49.10171,-4.463802,-937.8485],
[52.59804,-10.51089,-936.8273],
[56.09438,-18.78335,-936.5057],
[59.59071,-27.61005,-936.3584],
[46.31408,-6.218014,-934.7349],
[49.81042,-12.60938,-933.822],
[53.30675,-21.20901,-933.6032],
[56.80309,-30.15794,-933.4943]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[-31.21954,-29.82244,-953.0018],
[-27.72321,-30.25102,-950.2144],
[-24.22687,-30.52192,-947.3774],
[-20.73054,-30.43048,-944.4266],
[-34.00717,-20.37682,-946.3674],
[-30.51083,-21.13897,-943.6849],
[-27.0145,-21.85023,-940.9864],
[-23.51816,-22.38639,-938.2328],
[-36.79479,-8.867406,-939.0843],
[-33.29845,-9.666478,-936.4134],
[-29.80212,-10.4401,-933.7344],
[-26.30578,-11.12618,-931.028],
[-39.58241,2.642006,-931.8011],
[-36.08608,1.806018,-929.1418],
[-32.58974,0.9700291,-926.4825],
[-29.09341,0.1340406,-923.8232]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[65.16596,-22.92887,-942.2169],
[68.66229,-31.3143,-941.9308],
[72.15863,-40.53576,-941.9076],
[75.65496,-49.62798,-941.8437],
[62.37833,-25.12087,-939.2409],
[65.87467,-33.73321,-939.0262],
[69.371,-43.02355,-939.0246],
[72.86734,-52.07293,-938.9473],
[59.59071,-27.61005,-936.3584],
[63.08705,-36.43676,-936.2111],
[66.58338,-45.8177,-936.238],
[70.07972,-54.75687,-936.126],
[56.80309,-30.15794,-933.4943],
[60.29942,-39.10687,-933.3854],
[63.79576,-48.40511,-933.3863],
[67.29209,-57.06863,-933.1877]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[75.65496,-49.62798,-941.8437],
[79.1513,-58.7202,-941.7798],
[82.64763,-67.68316,-941.6753],
[86.14397,-76.15115,-941.4152],
[72.86734,-52.07293,-938.9473],
[76.36367,-61.12231,-938.8699],
[79.86001,-69.93073,-938.7168],
[83.35634,-78.15021,-938.3786],
[70.07972,-54.75687,-936.126],
[73.57605,-63.69603,-936.014],
[77.07239,-72.19342,-935.7631],
[80.56872,-80.00071,-935.2953],
[67.29209,-57.06863,-933.1877],
[70.78843,-65.73215,-932.989],
[74.28476,-73.76097,-932.5909],
[77.7811,-81.02829,-931.9533]
],0,2,[-70.13622,-104.3702,-1019.833],[90.00425,97.55184,-819.4922]));

P.push(new BezierPatch([
[86.14397,-76.15115,-941.4152],
[89.6403,-84.61913,-941.1551],
[93.13664,-92.59214,-940.7394],
[96.63297,-99.70442,-940.0531],
[83.35634,-78.15021,-938.3786],
[86.85268,-86.36969,-938.0404],
[90.34901,-94.00023,-937.517],
[93.84535,-100.6938,-936.6991],
[80.56872,-80.00071,-935.2953],
[84.06505,-87.808,-934.8275],
[87.56139,-94.9252,-934.1428],
[91.05772,-101.104,-933.163],
[77.7811,-81.02829,-931.9533],
[81.27743,-88.29562,-931.3158],
[84.77377,-94.80146,-930.4388],
[88.2701,-100.419,-929.2827]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-1.878665,-33.31143,-947.561],
[1.617669,-27.60728,-942.8457],
[5.114004,-20.05242,-937.5486],
[8.610339,-13.21628,-932.4774],
[-4.666288,-34.24168,-944.1884],
[-1.169954,-29.66611,-939.8279],
[2.326381,-23.1759,-934.8655],
[5.822716,-16.08718,-929.7149],
[-7.453911,-33.85515,-940.4018],
[-3.957577,-31.22344,-936.6524],
[-0.4612421,-27.22866,-932.4745],
[3.035093,-20.01274,-927.2839],
[-10.24153,-28.47944,-935.0469],
[-6.7452,-27.07362,-931.6828],
[-3.248865,-24.71578,-928.0195],
[0.2474695,-18.79945,-923.2375]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[35.82508,5.192457,-939.9142],
[39.32142,4.356469,-937.2549],
[42.81775,0.1733552,-935.6478],
[46.31408,-6.218014,-934.7349],
[33.03746,4.143931,-936.5788],
[36.53379,3.307943,-933.9195],
[40.03013,-1.236574,-932.426],
[43.52646,-7.972227,-931.6213],
[30.24983,3.095405,-933.2434],
[33.74617,2.259417,-930.5841],
[37.2425,-2.732872,-929.2314],
[40.73884,-9.850516,-928.5468],
[27.46221,2.046879,-929.908],
[30.95855,1.210891,-927.2487],
[34.45488,-4.200169,-926.0276],
[37.95122,-11.69779,-925.4624]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[16.97321,-6.681855,-941.4183],
[20.46954,-0.8562153,-936.6649],
[23.96588,2.882868,-932.5673],
[27.46221,2.046879,-929.908],
[14.18558,-8.529125,-938.334],
[17.68192,-2.323513,-933.4611],
[21.17825,1.834341,-929.2319],
[24.67459,0.9983529,-926.5726],
[11.39796,-10.34538,-935.24],
[14.8943,-3.761811,-930.2482],
[18.39063,0.7858153,-925.8965],
[21.88697,-0.05017324,-923.2372],
[8.610339,-13.21628,-932.4774],
[12.10667,-6.380137,-927.4063],
[15.60301,-0.2627109,-922.5611],
[19.09934,-1.098699,-919.9018]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-20.73054,-30.43048,-944.4266],
[-17.2342,-30.33904,-941.4757],
[-13.73787,-29.88525,-938.4109],
[-10.24153,-28.47944,-935.0469],
[-23.51816,-22.38639,-938.2328],
[-20.02183,-22.92256,-935.4792],
[-16.52549,-23.28364,-932.6706],
[-13.02916,-23.10372,-929.6919],
[-26.30578,-11.12618,-931.028],
[-22.80945,-11.81225,-928.3215],
[-19.31312,-12.41079,-925.5876],
[-15.81678,-12.73882,-922.7686],
[-29.09341,0.1340406,-923.8232],
[-25.59707,-0.7019479,-921.1639],
[-22.10074,-1.537936,-918.5045],
[-18.6044,-2.373925,-915.8452]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[46.31408,-6.218014,-934.7349],
[49.81042,-12.60938,-933.822],
[53.30675,-21.20901,-933.6032],
[56.80309,-30.15794,-933.4943],
[43.52646,-7.972227,-931.6213],
[47.0228,-14.70788,-930.8166],
[50.51913,-23.63467,-930.7008],
[54.01547,-32.70582,-930.6303],
[40.73884,-9.850516,-928.5468],
[44.23517,-16.96816,-927.8622],
[47.73151,-26.21116,-927.8457],
[51.22784,-35.31241,-927.7847],
[37.95122,-11.69779,-925.4624],
[41.44755,-19.1954,-924.8973],
[44.94388,-28.77958,-924.9881],
[48.44022,-37.73922,-924.8825]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[56.80309,-30.15794,-933.4943],
[60.29942,-39.10687,-933.3854],
[63.79576,-48.40511,-933.3863],
[67.29209,-57.06863,-933.1877],
[54.01547,-32.70582,-930.6303],
[57.5118,-41.77698,-930.5598],
[61.00814,-50.99251,-930.5347],
[64.50447,-59.38039,-930.2494],
[51.22784,-35.31241,-927.7847],
[54.72418,-44.41366,-927.7236],
[58.22051,-53.37317,-927.618],
[61.71685,-61.31998,-927.1941],
[48.44022,-37.73922,-924.8825],
[51.93655,-46.69886,-924.777],
[55.43289,-55.03397,-924.4751],
[58.92922,-62.27623,-923.8297]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[67.29209,-57.06863,-933.1877],
[70.78843,-65.73215,-932.989],
[74.28476,-73.76097,-932.5909],
[77.7811,-81.02829,-931.9533],
[64.50447,-59.38039,-930.2494],
[68.0008,-67.76828,-929.9641],
[71.49714,-75.32851,-929.4186],
[74.99347,-82.05587,-928.6113],
[61.71685,-61.31998,-927.1941],
[65.21318,-69.26679,-926.7701],
[68.70952,-76.20091,-926.0279],
[72.20585,-82.26053,-925.0106],
[58.92922,-62.27623,-923.8297],
[62.42556,-69.51849,-923.1842],
[65.92189,-75.6679,-922.1953],
[69.41823,-80.9679,-920.9393]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-50.07142,5.149972,-939.7791],
[-46.57508,4.313983,-937.1198],
[-43.07875,3.477995,-934.4604],
[-39.58241,2.642006,-931.8011],
[-52.85904,16.75587,-932.4656],
[-49.3627,15.87178,-929.8214],
[-45.86637,15.02432,-927.1657],
[-42.37003,14.15142,-924.518],
[-55.64666,28.36177,-925.1521],
[-52.15033,27.42957,-922.523],
[-48.65399,26.57065,-919.8709],
[-45.15766,25.66083,-917.2348],
[-58.43428,38.56826,-918.2786],
[-54.93795,37.43556,-915.7125],
[-51.44162,36.34985,-913.1317],
[-47.94528,35.10645,-910.6005]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[77.7811,-81.02829,-931.9533],
[81.27743,-88.29562,-931.3158],
[84.77377,-94.80146,-930.4388],
[88.2701,-100.419,-929.2827],
[74.99347,-82.05587,-928.6113],
[78.48981,-88.78323,-927.804],
[81.98614,-94.67771,-926.7349],
[85.48248,-99.73408,-925.4023],
[72.20585,-82.26053,-925.0106],
[75.70219,-88.32015,-923.9934],
[79.19852,-93.50526,-922.7013],
[82.69485,-97.95406,-921.1777],
[69.41823,-80.9679,-920.9393],
[72.91456,-86.26791,-919.6833],
[76.4109,-90.71851,-918.1602],
[79.90723,-94.56315,-916.4467]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[27.46221,2.046879,-929.908],
[30.95855,1.210891,-927.2487],
[34.45488,-4.200169,-926.0276],
[37.95122,-11.69779,-925.4624],
[24.67459,0.9983529,-926.5726],
[28.17092,0.1623644,-923.9133],
[31.66726,-5.667467,-922.8238],
[35.16359,-13.54506,-922.3781],
[21.88697,-0.05017324,-923.2372],
[25.3833,-0.8861618,-920.5779],
[28.87963,-7.105765,-919.611],
[32.37597,-15.36131,-919.2841],
[19.09934,-1.098699,-919.9018],
[22.59568,-1.934688,-917.2425],
[26.09201,-9.724091,-916.769],
[29.58835,-18.23221,-916.5215]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[8.610339,-13.21628,-932.4774],
[12.10667,-6.380137,-927.4063],
[15.60301,-0.2627109,-922.5611],
[19.09934,-1.098699,-919.9018],
[5.822716,-16.08718,-929.7149],
[9.31905,-8.998464,-924.5644],
[12.81538,-1.311237,-919.2257],
[16.31172,-2.147226,-916.5664],
[3.035093,-20.01274,-927.2839],
[6.531427,-12.79682,-922.0934],
[10.02776,-2.359763,-915.8903],
[13.5241,-3.195752,-913.231],
[0.2474695,-18.79945,-923.2375],
[3.743804,-12.88312,-918.4555],
[7.240139,-3.408289,-912.5549],
[10.73647,-4.244278,-909.8956]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[37.95122,-11.69779,-925.4624],
[41.44755,-19.1954,-924.8973],
[44.94388,-28.77958,-924.9881],
[48.44022,-37.73922,-924.8825],
[35.16359,-13.54506,-922.3781],
[38.65993,-21.42265,-921.9324],
[42.15626,-31.34799,-922.1305],
[45.6526,-40.16602,-921.9804],
[32.37597,-15.36131,-919.2841],
[35.8723,-23.61685,-918.9572],
[39.36864,-33.90833,-919.2703],
[42.86497,-42.41304,-919.0217],
[29.58835,-18.23221,-916.5215],
[33.08468,-26.74033,-916.2741],
[36.58102,-35.96716,-916.2525],
[40.07735,-43.34329,-915.6492]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-10.24153,-28.47944,-935.0469],
[-6.7452,-27.07362,-931.6828],
[-3.248865,-24.71578,-928.0195],
[0.2474695,-18.79945,-923.2375],
[-13.02916,-23.10372,-929.6919],
[-9.532823,-22.92381,-926.7133],
[-6.036488,-22.20291,-923.5645],
[-2.540154,-17.58616,-919.1911],
[-15.81678,-12.73882,-922.7686],
[-12.32045,-13.06686,-919.9496],
[-8.824111,-13.12441,-917.0456],
[-5.327777,-11.23403,-913.5292],
[-18.6044,-2.373925,-915.8452],
[-15.10807,-3.209914,-913.1859],
[-11.61173,-4.045902,-910.5266],
[-8.1154,-4.881891,-907.8673]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[48.44022,-37.73922,-924.8825],
[51.93655,-46.69886,-924.777],
[55.43289,-55.03397,-924.4751],
[58.92922,-62.27623,-923.8297],
[45.6526,-40.16602,-921.9804],
[49.14893,-48.98405,-921.8303],
[52.64527,-56.69476,-921.3322],
[56.1416,-63.23247,-920.4653],
[42.86497,-42.41304,-919.0217],
[46.36131,-50.91776,-918.7732],
[49.85764,-57.6357,-917.9629],
[53.35398,-63.20537,-916.7917],
[40.07735,-43.34329,-915.6492],
[43.57368,-50.71942,-915.0458],
[47.07002,-56.24484,-913.8607],
[50.56635,-60.77784,-912.3636]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-39.58241,2.642006,-931.8011],
[-36.08608,1.806018,-929.1418],
[-32.58974,0.9700291,-926.4825],
[-29.09341,0.1340406,-923.8232],
[-42.37003,14.15142,-924.518],
[-38.8737,13.27851,-921.8703],
[-35.37737,12.38016,-919.2306],
[-31.88103,11.39426,-916.6184],
[-45.15766,25.66083,-917.2348],
[-41.66132,24.75101,-914.5987],
[-38.16499,23.79029,-911.9786],
[-34.66865,22.65447,-909.4136],
[-47.94528,35.10645,-910.6005],
[-44.44895,33.86306,-908.0692],
[-40.95261,32.46198,-905.5875],
[-37.45628,30.69856,-903.2198]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[58.92922,-62.27623,-923.8297],
[62.42556,-69.51849,-923.1842],
[65.92189,-75.6679,-922.1953],
[69.41823,-80.9679,-920.9393],
[56.1416,-63.23247,-920.4653],
[59.63794,-69.77018,-919.5983],
[63.13427,-75.13488,-918.3627],
[66.6306,-79.67528,-916.8679],
[53.35398,-63.20537,-916.7917],
[56.85031,-68.77504,-915.6205],
[60.34665,-73.19645,-914.0883],
[63.84298,-76.88536,-912.3258],
[50.56635,-60.77784,-912.3636],
[54.06269,-65.31085,-910.8664],
[57.55902,-68.85143,-909.0574],
[61.05536,-71.79949,-907.062]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[19.09934,-1.098699,-919.9018],
[22.59568,-1.934688,-917.2425],
[26.09201,-9.724091,-916.769],
[29.58835,-18.23221,-916.5215],
[16.31172,-2.147226,-916.5664],
[19.80805,-2.983214,-913.9071],
[23.30439,-12.34242,-913.9271],
[26.80072,-21.10311,-913.759],
[13.5241,-3.195752,-913.231],
[17.02043,-4.03174,-910.5716],
[20.51677,-16.14077,-911.4561],
[24.0131,-25.02867,-911.328],
[10.73647,-4.244278,-909.8956],
[14.23281,-5.080266,-907.2362],
[17.72914,-16.22707,-907.8182],
[21.22548,-23.81538,-907.2816]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[69.41823,-80.9679,-920.9393],
[72.91456,-86.26791,-919.6833],
[76.4109,-90.71851,-918.1602],
[79.90723,-94.56315,-916.4467],
[66.6306,-79.67528,-916.8679],
[70.12694,-84.21567,-915.3731],
[73.62327,-87.93176,-913.6192],
[77.11961,-91.17223,-911.7157],
[63.84298,-76.88536,-912.3258],
[67.33932,-80.57428,-910.5634],
[70.83565,-83.5307,-908.5706],
[74.33199,-86.17042,-906.4783],
[61.05536,-71.79949,-907.062],
[64.55169,-74.74755,-905.0666],
[68.04803,-77.10308,-902.885],
[71.54436,-79.26598,-900.6428]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[29.58835,-18.23221,-916.5215],
[33.08468,-26.74033,-916.2741],
[36.58102,-35.96716,-916.2525],
[40.07735,-43.34329,-915.6492],
[26.80072,-21.10311,-913.759],
[30.29706,-29.86381,-913.5909],
[33.79339,-38.026,-913.2347],
[37.28973,-44.27354,-912.2766],
[24.0131,-25.02867,-911.328],
[27.50943,-33.91656,-911.1999],
[31.00577,-39.58332,-910.0592],
[34.5021,-43.88701,-908.49],
[21.22548,-23.81538,-907.2816],
[24.72181,-31.40369,-906.745],
[28.21815,-35.43351,-905.0897],
[31.71448,-38.5113,-903.1351]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[0.2474695,-18.79945,-923.2375],
[3.743804,-12.88312,-918.4555],
[7.240139,-3.408289,-912.5549],
[10.73647,-4.244278,-909.8956],
[-2.540154,-17.58616,-919.1911],
[0.956181,-12.96941,-914.8176],
[4.452516,-4.456815,-909.2195],
[7.94885,-5.292804,-906.5601],
[-5.327777,-11.23403,-913.5292],
[-1.831442,-9.343645,-910.0128],
[1.664893,-5.505342,-905.8841],
[5.161227,-6.34133,-903.2247],
[-8.1154,-4.881891,-907.8673],
[-4.619065,-5.717879,-905.208],
[-1.122731,-6.553868,-902.5486],
[2.373604,-7.389856,-899.8893]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-29.09341,0.1340406,-923.8232],
[-25.59707,-0.7019479,-921.1639],
[-22.10074,-1.537936,-918.5045],
[-18.6044,-2.373925,-915.8452],
[-31.88103,11.39426,-916.6184],
[-28.3847,10.40836,-914.0062],
[-24.88836,9.334914,-911.4215],
[-21.39203,7.990975,-908.9219],
[-34.66865,22.65447,-909.4136],
[-31.17232,21.51866,-906.8485],
[-27.67598,20.20776,-904.3385],
[-24.17965,18.35587,-901.9985],
[-37.45628,30.69856,-903.2198],
[-33.95994,28.93514,-900.852],
[-30.46361,26.80938,-898.5981],
[-26.96727,23.73159,-896.6436]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[40.07735,-43.34329,-915.6492],
[43.57368,-50.71942,-915.0458],
[47.07002,-56.24484,-913.8607],
[50.56635,-60.77784,-912.3636],
[37.28973,-44.27354,-912.2766],
[40.78606,-50.52108,-911.3184],
[44.2824,-54.85398,-909.7584],
[47.77873,-58.35032,-907.9354],
[34.5021,-43.88701,-908.49],
[37.99844,-48.19071,-906.9208],
[41.49477,-51.13133,-904.9231],
[44.99111,-53.52236,-902.7527],
[31.71448,-38.5113,-903.1351],
[35.21082,-41.58909,-901.1805],
[38.70715,-43.71485,-898.9266],
[42.20349,-45.47827,-896.5589]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-58.43428,38.56826,-918.2786],
[-54.93795,37.43556,-915.7125],
[-51.44162,36.34985,-913.1317],
[-47.94528,35.10645,-910.6005],
[-61.22191,48.77474,-911.405],
[-57.72557,47.44155,-908.902],
[-54.22924,46.12904,-906.3925],
[-50.7329,44.55207,-903.9661],
[-64.00953,57.58182,-904.9714],
[-60.5132,55.89575,-902.5793],
[-57.01686,54.14111,-900.2088],
[-53.52053,51.93391,-897.9805],
[-66.79715,64.48626,-899.1358],
[-63.30082,62.32337,-896.8937],
[-59.80448,59.96784,-894.712],
[-56.30815,57.01978,-892.7167]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-18.6044,-2.373925,-915.8452],
[-15.10807,-3.209914,-913.1859],
[-11.61173,-4.045902,-910.5266],
[-8.1154,-4.881891,-907.8673],
[-21.39203,7.990975,-908.9219],
[-17.89569,6.647036,-906.4222],
[-14.39936,5.032601,-904.0076],
[-10.90302,1.470244,-902.2054],
[-24.17965,18.35587,-901.9985],
[-20.68332,16.50398,-899.6586],
[-17.18698,14.1111,-897.4887],
[-13.69065,7.822378,-896.5435],
[-26.96727,23.73159,-896.6436],
[-23.47094,20.6538,-894.689],
[-19.9746,16.62398,-893.0337],
[-16.47827,9.035667,-892.497]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[10.73647,-4.244278,-909.8956],
[14.23281,-5.080266,-907.2362],
[17.72914,-16.22707,-907.8182],
[21.22548,-23.81538,-907.2816],
[7.94885,-5.292804,-906.5601],
[11.44518,-6.128793,-903.9008],
[14.94152,-16.31336,-904.1803],
[18.43785,-22.60209,-903.2352],
[5.161227,-6.34133,-903.2247],
[8.657562,-7.177319,-900.5654],
[12.1539,-12.6876,-899.3755],
[15.65023,-16.24996,-897.5733],
[2.373604,-7.389856,-899.8893],
[5.869939,-8.225845,-897.23],
[9.366273,-9.061833,-894.5707],
[12.86261,-9.897822,-891.9114]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[50.56635,-60.77784,-912.3636],
[54.06269,-65.31085,-910.8664],
[57.55902,-68.85143,-909.0574],
[61.05536,-71.79949,-907.062],
[47.77873,-58.35032,-907.9354],
[51.27507,-61.84665,-906.1124],
[54.7714,-64.50642,-904.0264],
[58.26774,-66.71362,-901.7982],
[44.99111,-53.52236,-902.7527],
[48.48744,-55.91339,-900.5822],
[51.98378,-57.75482,-898.2389],
[55.48011,-59.33179,-895.8126],
[42.20349,-45.47827,-896.5589],
[45.69982,-47.24169,-894.1911],
[49.19615,-48.64277,-891.7094],
[52.69249,-49.88616,-889.1782]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-47.94528,35.10645,-910.6005],
[-44.44895,33.86306,-908.0692],
[-40.95261,32.46198,-905.5875],
[-37.45628,30.69856,-903.2198],
[-50.7329,44.55207,-903.9661],
[-47.23657,42.9751,-901.5397],
[-43.74023,41.13368,-899.1965],
[-40.2439,38.74265,-897.026],
[-53.52053,51.93391,-897.9805],
[-50.02419,49.7267,-895.7522],
[-46.52786,47.06694,-893.6662],
[-43.03152,43.5706,-891.8432],
[-56.30815,57.01978,-892.7167],
[-52.81182,54.07172,-890.7213],
[-49.31548,50.53113,-888.9122],
[-45.81915,45.99813,-887.4151]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-8.1154,-4.881891,-907.8673],
[-4.619065,-5.717879,-905.208],
[-1.122731,-6.553868,-902.5486],
[2.373604,-7.389856,-899.8893],
[-10.90302,1.470244,-902.2054],
[-7.406688,-2.092114,-900.4031],
[-3.910354,-7.602394,-899.2132],
[-0.414019,-8.438382,-896.5539],
[-13.69065,7.822378,-896.5435],
[-10.19431,1.533652,-895.5983],
[-6.697977,-8.65092,-895.8778],
[-3.201642,-9.486909,-893.2185],
[-16.47827,9.035667,-892.497],
[-12.98193,1.447357,-891.9604],
[-9.4856,-9.699446,-892.5424],
[-5.989265,-10.53543,-889.8831]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[21.22548,-23.81538,-907.2816],
[24.72181,-31.40369,-906.745],
[28.21815,-35.43351,-905.0897],
[31.71448,-38.5113,-903.1351],
[18.43785,-22.60209,-903.2352],
[21.93419,-28.89082,-902.29],
[25.43052,-31.2837,-900.1201],
[28.92686,-33.13559,-897.7801],
[15.65023,-16.24996,-897.5733],
[19.14657,-19.81231,-895.771],
[22.6429,-21.42675,-893.3564],
[26.13923,-22.77069,-890.8568],
[12.86261,-9.897822,-891.9114],
[16.35894,-10.73381,-889.2521],
[19.85528,-11.5698,-886.5927],
[23.35161,-12.40579,-883.9334]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[61.05536,-71.79949,-907.062],
[64.55169,-74.74755,-905.0666],
[68.04803,-77.10308,-902.885],
[71.54436,-79.26598,-900.6428],
[58.26774,-66.71362,-901.7982],
[61.76407,-68.92082,-899.5699],
[65.2604,-70.67546,-897.1994],
[68.75674,-72.36153,-894.8073],
[55.48011,-59.33179,-895.8126],
[58.97645,-60.90876,-893.3862],
[62.47278,-62.22127,-890.8767],
[65.96912,-63.55446,-888.3737],
[52.69249,-49.88616,-889.1782],
[56.18882,-51.12956,-886.647],
[59.68516,-52.21527,-884.0661],
[63.18149,-53.34797,-881.5001]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-37.45628,30.69856,-903.2198],
[-33.95994,28.93514,-900.852],
[-30.46361,26.80938,-898.5981],
[-26.96727,23.73159,-896.6436],
[-40.2439,38.74265,-897.026],
[-36.74757,36.35162,-894.8555],
[-33.25123,33.41099,-892.8578],
[-29.7549,29.1073,-891.2886],
[-43.03152,43.5706,-891.8432],
[-39.53519,40.07427,-890.0202],
[-36.03885,35.74137,-888.4602],
[-32.54252,29.49383,-887.5021],
[-45.81915,45.99813,-887.4151],
[-42.32281,41.46513,-885.918],
[-38.82648,35.93971,-884.7328],
[-35.33014,28.56358,-884.1295]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[31.71448,-38.5113,-903.1351],
[35.21082,-41.58909,-901.1805],
[38.70715,-43.71485,-898.9266],
[42.20349,-45.47827,-896.5589],
[28.92686,-33.13559,-897.7801],
[32.42319,-34.98748,-895.4402],
[35.91953,-36.29838,-892.9302],
[39.41586,-37.43419,-890.3651],
[26.13923,-22.77069,-890.8568],
[29.63557,-24.11463,-888.3571],
[33.1319,-25.18807,-885.7725],
[36.62824,-26.17397,-883.1603],
[23.35161,-12.40579,-883.9334],
[26.84795,-13.24178,-881.2741],
[30.34428,-14.07776,-878.6148],
[33.84062,-14.91375,-875.9555]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[2.373604,-7.389856,-899.8893],
[5.869939,-8.225845,-897.23],
[9.366273,-9.061833,-894.5707],
[12.86261,-9.897822,-891.9114],
[-0.414019,-8.438382,-896.5539],
[3.082316,-9.274371,-893.8946],
[6.57865,-5.436068,-889.7659],
[10.07498,-3.545688,-886.2495],
[-3.201642,-9.486909,-893.2185],
[0.2946926,-10.3229,-890.5592],
[3.791027,-1.810302,-884.961],
[7.287362,2.806447,-880.5876],
[-5.989265,-10.53543,-889.8831],
[-2.492931,-11.37142,-887.2238],
[1.003404,-1.896597,-881.3231],
[4.499739,4.019736,-876.5411]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-26.96727,23.73159,-896.6436],
[-23.47094,20.6538,-894.689],
[-19.9746,16.62398,-893.0337],
[-16.47827,9.035667,-892.497],
[-29.7549,29.1073,-891.2886],
[-26.25856,24.80361,-889.7194],
[-22.76223,19.13685,-888.5787],
[-19.26589,10.24896,-888.4506],
[-32.54252,29.49383,-887.5021],
[-29.04618,23.24628,-886.544],
[-25.54985,15.0841,-886.1877],
[-22.05352,6.323401,-886.0196],
[-35.33014,28.56358,-884.1295],
[-31.83381,21.18745,-883.5261],
[-28.33747,11.96062,-883.5046],
[-24.84114,3.452498,-883.2571]
],0,2,[-70.13622,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-66.79715,64.48626,-899.1358],
[-63.30082,62.32337,-896.8937],
[-59.80448,59.96784,-894.712],
[-56.30815,57.01978,-892.7167],
[-69.58478,71.39071,-893.3003],
[-66.08844,68.75099,-891.208],
[-62.59211,65.79457,-889.2153],
[-59.09577,62.10565,-887.4528],
[-72.3724,76.39252,-888.0629],
[-68.87607,73.15205,-886.1595],
[-65.37973,69.43596,-884.4056],
[-61.8834,64.89556,-882.9108],
[-75.16002,79.78343,-883.3319],
[-71.66369,75.9388,-881.6184],
[-68.16735,71.4882,-880.0954],
[-64.67102,66.18819,-878.8394]
],0,2,[-75.16002,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-16.47827,9.035667,-892.497],
[-12.98193,1.447357,-891.9604],
[-9.4856,-9.699446,-892.5424],
[-5.989265,-10.53543,-889.8831],
[-19.26589,10.24896,-888.4506],
[-15.76956,1.361062,-888.3225],
[-12.27322,-10.74797,-889.207],
[-8.776888,-11.58396,-886.5477],
[-22.05352,6.323401,-886.0196],
[-18.55718,-2.437294,-885.8515],
[-15.06085,-11.7965,-885.8716],
[-11.56451,-12.63249,-883.2123],
[-24.84114,3.452498,-883.2571],
[-21.3448,-5.055621,-883.0096],
[-17.84847,-12.84502,-882.5362],
[-14.35213,-13.68101,-879.8769]
],0,2,[-75.16002,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-56.30815,57.01978,-892.7167],
[-52.81182,54.07172,-890.7213],
[-49.31548,50.53113,-888.9122],
[-45.81915,45.99813,-887.4151],
[-59.09577,62.10565,-887.4528],
[-55.59944,58.41674,-885.6904],
[-52.1031,53.99533,-884.1582],
[-48.60677,48.42566,-882.9869],
[-61.8834,64.89556,-882.9108],
[-58.38706,60.35517,-881.416],
[-54.89073,54.99047,-880.1803],
[-51.39439,48.45276,-879.3134],
[-64.67102,66.18819,-878.8394],
[-61.17468,60.88819,-877.5834],
[-57.67835,54.73877,-876.5944],
[-54.18202,47.49651,-875.949]
],0,2,[-75.16002,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[42.20349,-45.47827,-896.5589],
[45.69982,-47.24169,-894.1911],
[49.19615,-48.64277,-891.7094],
[52.69249,-49.88616,-889.1782],
[39.41586,-37.43419,-890.3651],
[42.9122,-38.57,-887.8],
[46.40853,-39.53072,-885.1799],
[49.90487,-40.44054,-882.5438],
[36.62824,-26.17397,-883.1603],
[40.12457,-27.15987,-880.5481],
[43.62091,-28.05823,-877.9084],
[47.11724,-28.93113,-875.2607],
[33.84062,-14.91375,-875.9555],
[37.33695,-15.74974,-873.2962],
[40.83329,-16.58573,-870.6368],
[44.32962,-17.42172,-867.9775]
],0,2,[-75.16002,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-45.81915,45.99813,-887.4151],
[-42.32281,41.46513,-885.918],
[-38.82648,35.93971,-884.7328],
[-35.33014,28.56358,-884.1295],
[-48.60677,48.42566,-882.9869],
[-45.11043,42.85599,-881.8157],
[-41.6141,36.13804,-881.0055],
[-38.11777,27.63333,-880.7569],
[-51.39439,48.45276,-879.3134],
[-47.89806,41.91505,-878.4465],
[-44.40172,34.20434,-877.9483],
[-40.90539,25.38631,-877.7983],
[-54.18202,47.49651,-875.949],
[-50.68568,40.25425,-875.3036],
[-47.18935,31.91915,-875.0017],
[-43.69301,22.9595,-874.8961]
],0,2,[-75.16002,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[12.86261,-9.897822,-891.9114],
[16.35894,-10.73381,-889.2521],
[19.85528,-11.5698,-886.5927],
[23.35161,-12.40579,-883.9334],
[10.07498,-3.545688,-886.2495],
[13.57132,-1.655307,-882.7331],
[17.06765,-1.71285,-879.8291],
[20.56399,-2.040888,-877.0101],
[7.287362,2.806447,-880.5876],
[10.7837,7.423196,-876.2141],
[14.28003,8.144099,-873.0654],
[17.77637,8.324012,-870.0867],
[4.499739,4.019736,-876.5411],
[7.996073,9.93607,-871.7592],
[11.49241,12.29391,-868.0958],
[14.98874,13.69973,-864.7318]
],0,2,[-75.16002,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-35.33014,28.56358,-884.1295],
[-31.83381,21.18745,-883.5261],
[-28.33747,11.96062,-883.5046],
[-24.84114,3.452498,-883.2571],
[-38.11777,27.63333,-880.7569],
[-34.62143,19.12862,-880.5083],
[-31.1251,8.837137,-880.8215],
[-27.62876,0.5815948,-880.4946],
[-40.90539,25.38631,-877.7983],
[-37.40905,16.56828,-877.6482],
[-33.91272,6.642933,-877.8462],
[-30.41638,-1.234656,-877.4005],
[-43.69301,22.9595,-874.8961],
[-40.19668,13.99986,-874.7906],
[-36.70034,4.41569,-874.8814],
[-33.20401,-3.081926,-874.3162]
],0,2,[-75.16002,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-5.989265,-10.53543,-889.8831],
[-2.492931,-11.37142,-887.2238],
[1.003404,-1.896597,-881.3231],
[4.499739,4.019736,-876.5411],
[-8.776888,-11.58396,-886.5477],
[-5.280554,-12.41995,-883.8884],
[-1.784219,-1.982893,-877.6852],
[1.712116,5.233026,-872.4947],
[-11.56451,-12.63249,-883.2123],
[-8.068177,-13.46848,-880.553],
[-4.571842,-5.781248,-875.2143],
[-1.075507,1.30747,-870.0637],
[-14.35213,-13.68101,-879.8769],
[-10.8558,-14.517,-877.2176],
[-7.359465,-8.399575,-872.3724],
[-3.86313,-1.563433,-867.3012]
],0,2,[-75.16002,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-24.84114,3.452498,-883.2571],
[-21.3448,-5.055621,-883.0096],
[-17.84847,-12.84502,-882.5362],
[-14.35213,-13.68101,-879.8769],
[-27.62876,0.5815948,-880.4946],
[-24.13243,-7.673948,-880.1677],
[-20.63609,-13.89355,-879.2008],
[-17.13976,-14.72954,-876.5415],
[-30.41638,-1.234656,-877.4005],
[-26.92005,-9.112245,-876.9548],
[-23.42372,-14.94208,-875.8654],
[-19.92738,-15.77807,-873.2061],
[-33.20401,-3.081926,-874.3162],
[-29.70767,-10.57954,-873.7511],
[-26.21134,-15.9906,-872.53],
[-22.715,-16.82659,-869.8707]
],0,2,[-75.16002,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-75.16002,79.78343,-883.3319],
[-71.66369,75.9388,-881.6184],
[-68.16735,71.4882,-880.0954],
[-64.67102,66.18819,-878.8394],
[-77.94765,83.17435,-878.6009],
[-74.45131,78.72555,-877.0773],
[-70.95498,73.54044,-875.7852],
[-67.45864,67.48082,-874.768],
[-80.73527,84.95437,-874.3763],
[-77.23893,79.898,-873.0437],
[-73.7426,74.00352,-871.9746],
[-70.24627,67.27616,-871.1673],
[-83.52289,85.63931,-870.496],
[-80.02656,80.02174,-869.3398],
[-76.53022,73.51591,-868.4629],
[-73.03389,66.24858,-867.8253]
],0,2,[-83.52289,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[52.69249,-49.88616,-889.1782],
[56.18882,-51.12956,-886.647],
[59.68516,-52.21527,-884.0661],
[63.18149,-53.34797,-881.5001],
[49.90487,-40.44054,-882.5438],
[53.4012,-41.35036,-879.9077],
[56.89754,-42.20928,-877.2556],
[60.39387,-43.14148,-874.6265],
[47.11724,-28.93113,-875.2607],
[50.61358,-29.80404,-872.613],
[54.10991,-30.65149,-869.9572],
[57.60625,-31.53558,-867.3131],
[44.32962,-17.42172,-867.9775],
[47.82595,-18.25771,-865.3182],
[51.32229,-19.0937,-862.6589],
[54.81862,-19.92968,-859.9996]
],0,2,[-83.52289,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-64.67102,66.18819,-878.8394],
[-61.17468,60.88819,-877.5834],
[-57.67835,54.73877,-876.5944],
[-54.18202,47.49651,-875.949],
[-67.45864,67.48082,-874.768],
[-63.96231,61.4212,-873.7508],
[-60.46597,54.48708,-873.0085],
[-56.96964,46.54027,-872.5846],
[-70.24627,67.27616,-871.1673],
[-66.74993,60.5488,-870.36],
[-63.2536,52.98856,-869.8146],
[-59.75726,44.60068,-869.5293],
[-73.03389,66.24858,-867.8253],
[-69.53755,58.98126,-867.1878],
[-66.04122,50.95244,-866.7896],
[-62.54488,42.28892,-866.591]
],0,2,[-83.52289,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-54.18202,47.49651,-875.949],
[-50.68568,40.25425,-875.3036],
[-47.18935,31.91915,-875.0017],
[-43.69301,22.9595,-874.8961],
[-56.96964,46.54027,-872.5846],
[-53.4733,38.59346,-872.1606],
[-49.97697,29.63395,-872.055],
[-46.48063,20.5327,-871.994],
[-59.75726,44.60068,-869.5293],
[-56.26093,36.2128,-869.244],
[-52.76459,26.99727,-869.2189],
[-49.26826,17.92611,-869.1484],
[-62.54488,42.28892,-866.591],
[-59.04855,33.62539,-866.3923],
[-55.55222,24.32716,-866.3932],
[-52.05588,15.37823,-866.2843]
],0,2,[-83.52289,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-43.69301,22.9595,-874.8961],
[-40.19668,13.99986,-874.7906],
[-36.70034,4.41569,-874.8814],
[-33.20401,-3.081926,-874.3162],
[-46.48063,20.5327,-871.994],
[-42.9843,11.43145,-871.933],
[-39.48797,2.188448,-871.9165],
[-35.99163,-4.929196,-871.2319],
[-49.26826,17.92611,-869.1484],
[-45.77192,8.854954,-869.0779],
[-42.27559,-0.07183348,-868.962],
[-38.77925,-6.807486,-868.1573],
[-52.05588,15.37823,-866.2843],
[-48.55955,6.429296,-866.1754],
[-45.06321,-2.170329,-865.9567],
[-41.56688,-8.561698,-865.0438]
],0,2,[-83.52289,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[23.35161,-12.40579,-883.9334],
[26.84795,-13.24178,-881.2741],
[30.34428,-14.07776,-878.6148],
[33.84062,-14.91375,-875.9555],
[20.56399,-2.040888,-877.0101],
[24.06032,-2.368926,-874.1911],
[27.55666,-2.967459,-871.4571],
[31.05299,-3.653536,-868.7507],
[17.77637,8.324012,-870.0867],
[21.2727,8.503925,-867.108],
[24.76903,8.142847,-864.2994],
[28.26537,7.606681,-861.5459],
[14.98874,13.69973,-864.7318],
[18.48508,15.10554,-861.3677],
[21.98141,15.55933,-858.3029],
[25.47775,15.65077,-855.3521]
],0,2,[-83.52289,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-14.35213,-13.68101,-879.8769],
[-10.8558,-14.517,-877.2176],
[-7.359465,-8.399575,-872.3724],
[-3.86313,-1.563433,-867.3012],
[-17.13976,-14.72954,-876.5415],
[-13.64342,-15.56553,-873.8822],
[-10.14709,-11.0179,-869.5304],
[-6.650754,-4.434336,-864.5387],
[-19.92738,-15.77807,-873.2061],
[-16.43105,-16.61405,-870.5467],
[-12.93471,-12.4562,-866.3176],
[-9.438377,-6.250587,-861.4446],
[-22.715,-16.82659,-869.8707],
[-19.21867,-17.66258,-867.2113],
[-15.72233,-13.9235,-863.1138],
[-12.226,-8.097858,-858.3603]
],0,2,[-83.52289,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-33.20401,-3.081926,-874.3162],
[-29.70767,-10.57954,-873.7511],
[-26.21134,-15.9906,-872.53],
[-22.715,-16.82659,-869.8707],
[-35.99163,-4.929196,-871.2319],
[-32.4953,-12.04684,-870.5473],
[-28.99896,-17.03913,-869.1946],
[-25.50263,-17.87512,-866.5352],
[-38.77925,-6.807486,-868.1573],
[-35.28292,-13.54314,-867.3526],
[-31.78658,-18.08766,-865.8592],
[-28.29025,-18.92364,-863.1998],
[-41.56688,-8.561698,-865.0438],
[-38.07054,-14.95307,-864.1308],
[-34.57421,-19.13618,-862.5237],
[-31.07787,-19.97217,-859.8644]
],0,2,[-83.52289,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[4.499739,4.019736,-876.5411],
[7.996073,9.93607,-871.7592],
[11.49241,12.29391,-868.0958],
[14.98874,13.69973,-864.7318],
[1.712116,5.233026,-872.4947],
[5.20845,12.44894,-867.3042],
[8.704785,16.44372,-863.1262],
[12.20112,19.07544,-859.3768],
[-1.075507,1.30747,-870.0637],
[2.420827,8.396189,-864.9132],
[5.917162,14.8864,-859.9508],
[9.413496,19.46196,-855.5903],
[-3.86313,-1.563433,-867.3012],
[-0.3667959,5.272709,-862.2301],
[3.129539,12.82757,-856.933],
[6.625873,18.53172,-852.2177]
],0,2,[-83.52289,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-83.52289,85.63931,-870.496],
[-80.02656,80.02174,-869.3398],
[-76.53022,73.51591,-868.4629],
[-73.03389,66.24858,-867.8253],
[-86.31052,86.32425,-866.6156],
[-82.81418,80.14548,-865.6359],
[-79.31785,73.02829,-864.9511],
[-75.82151,65.221,-864.4833],
[-89.09814,85.91411,-863.0795],
[-85.6018,79.22051,-862.2616],
[-82.10547,71.58998,-861.7383],
[-78.60913,63.3705,-861.4],
[-91.88576,84.92471,-859.7255],
[-88.38943,77.81242,-859.0393],
[-84.89309,69.83942,-858.6235],
[-81.39676,61.37144,-858.3634]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-73.03389,66.24858,-867.8253],
[-69.53755,58.98126,-867.1878],
[-66.04122,50.95244,-866.7896],
[-62.54488,42.28892,-866.591],
[-75.82151,65.221,-864.4833],
[-72.32518,57.41371,-864.0155],
[-68.82884,48.91632,-863.7647],
[-65.33251,39.97715,-863.6527],
[-78.60913,63.3705,-861.4],
[-75.1128,55.15102,-861.0618],
[-71.61647,46.34259,-860.9087],
[-68.12013,37.29321,-860.8314],
[-81.39676,61.37144,-858.3634],
[-77.90042,52.90345,-858.1033],
[-74.40409,43.94048,-857.9988],
[-70.90775,34.84827,-857.935]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-62.54488,42.28892,-866.591],
[-59.04855,33.62539,-866.3923],
[-55.55222,24.32716,-866.3932],
[-52.05588,15.37823,-866.2843],
[-65.33251,39.97715,-863.6527],
[-61.83617,31.03799,-863.5407],
[-58.33984,21.65704,-863.5676],
[-54.8435,12.83034,-863.4203],
[-68.12013,37.29321,-860.8314],
[-64.6238,28.24383,-860.754],
[-61.12746,18.9535,-860.7525],
[-57.63113,10.34116,-860.5377],
[-70.90775,34.84827,-857.935],
[-67.41142,25.75605,-857.8711],
[-63.91508,16.53458,-857.8478],
[-60.41875,8.149162,-857.5618]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[33.84062,-14.91375,-875.9555],
[37.33695,-15.74974,-873.2962],
[40.83329,-16.58573,-870.6368],
[44.32962,-17.42172,-867.9775],
[31.05299,-3.653536,-868.7507],
[34.54933,-4.339613,-866.0442],
[38.04566,-5.113235,-863.3653],
[41.542,-5.912307,-860.6944],
[28.26537,7.606681,-861.5459],
[31.7617,7.070515,-858.7923],
[35.25804,6.359261,-856.0938],
[38.75437,5.597105,-853.4112],
[25.47775,15.65077,-855.3521],
[28.97408,15.74221,-852.4012],
[32.47042,15.47131,-849.5642],
[35.96675,15.04273,-846.7769]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-52.05588,15.37823,-866.2843],
[-48.55955,6.429296,-866.1754],
[-45.06321,-2.170329,-865.9567],
[-41.56688,-8.561698,-865.0438],
[-54.8435,12.83034,-863.4203],
[-51.34717,4.003638,-863.2729],
[-47.85083,-4.268825,-862.9513],
[-44.3545,-10.31591,-861.9302],
[-57.63113,10.34116,-860.5377],
[-54.13479,1.728815,-860.323],
[-50.63846,-6.205535,-859.8951],
[-47.14212,-11.94605,-858.7776],
[-60.41875,8.149162,-857.5618],
[-56.92242,-0.2362592,-857.2757],
[-53.42608,-7.785635,-856.7268],
[-49.92975,-13.29701,-855.5373]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-22.715,-16.82659,-869.8707],
[-19.21867,-17.66258,-867.2113],
[-15.72233,-13.9235,-863.1138],
[-12.226,-8.097858,-858.3603],
[-25.50263,-17.87512,-866.5352],
[-22.00629,-18.71111,-863.8759],
[-18.50996,-15.39079,-859.91],
[-15.01362,-9.945128,-855.276],
[-28.29025,-18.92364,-863.1998],
[-24.79392,-19.75963,-860.5405],
[-21.29758,-16.88709,-856.7154],
[-17.80125,-11.82342,-852.2014],
[-31.07787,-19.97217,-859.8644],
[-27.58154,-20.80816,-857.2051],
[-24.0852,-18.29702,-853.4936],
[-20.58887,-13.57763,-849.0879]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-41.56688,-8.561698,-865.0438],
[-38.07054,-14.95307,-864.1308],
[-34.57421,-19.13618,-862.5237],
[-31.07787,-19.97217,-859.8644],
[-44.3545,-10.31591,-861.9302],
[-40.85817,-16.363,-860.9091],
[-37.36183,-20.18471,-859.1883],
[-33.8655,-21.0207,-856.529],
[-47.14212,-11.94605,-858.7776],
[-43.64579,-17.68656,-857.6601],
[-40.14945,-21.23323,-855.8529],
[-36.65312,-22.06922,-853.1936],
[-49.92975,-13.29701,-855.5373],
[-46.43341,-18.80839,-854.3477],
[-42.93708,-22.28176,-852.5175],
[-39.44074,-23.11775,-849.8582]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-3.86313,-1.563433,-867.3012],
[-0.3667959,5.272709,-862.2301],
[3.129539,12.82757,-856.933],
[6.625873,18.53172,-852.2177],
[-6.650754,-4.434336,-864.5387],
[-3.154419,2.149229,-859.5469],
[0.3419157,10.76873,-853.9152],
[3.83825,17.60147,-848.8451],
[-9.438377,-6.250587,-861.4446],
[-5.942042,-0.04497512,-856.5717],
[-2.445707,8.208395,-851.055],
[1.050627,15.35445,-845.8865],
[-12.226,-8.097858,-858.3603],
[-8.729665,-2.272218,-853.6068],
[-5.23333,5.639979,-848.1974],
[-1.736996,12.92764,-842.9843]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[44.32962,-17.42172,-867.9775],
[47.82595,-18.25771,-865.3182],
[51.32229,-19.0937,-862.6589],
[54.81862,-19.92968,-859.9996],
[41.542,-5.912307,-860.6944],
[45.03833,-6.711379,-858.0235],
[48.53467,-7.535903,-855.3605],
[52.031,-8.323785,-852.6861],
[38.75437,5.597105,-853.4112],
[42.25071,4.834948,-850.7287],
[45.74704,4.02189,-848.0622],
[49.24338,3.282114,-845.3726],
[35.96675,15.04273,-846.7769],
[39.46309,14.61414,-843.9895],
[42.95942,14.02788,-841.2516],
[46.45575,13.4886,-838.4991]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[14.98874,13.69973,-864.7318],
[18.48508,15.10554,-861.3677],
[21.98141,15.55933,-858.3029],
[25.47775,15.65077,-855.3521],
[12.20112,19.07544,-859.3768],
[15.69745,21.70715,-855.6274],
[19.19379,22.9758,-852.3065],
[22.69012,23.69485,-849.1583],
[9.413496,19.46196,-855.5903],
[12.90983,24.03753,-851.2298],
[16.40617,26.69845,-847.4712],
[19.9025,28.52281,-843.9755],
[6.625873,18.53172,-852.2177],
[10.12221,24.23587,-847.5024],
[13.61854,28.08931,-843.3689],
[17.11488,30.95034,-839.5474]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-31.07787,-19.97217,-859.8644],
[-27.58154,-20.80816,-857.2051],
[-24.0852,-18.29702,-853.4936],
[-20.58887,-13.57763,-849.0879],
[-33.8655,-21.0207,-856.529],
[-30.36916,-21.85668,-853.8697],
[-26.87283,-19.70695,-850.2718],
[-23.37649,-15.33184,-845.9743],
[-36.65312,-22.06922,-853.1936],
[-33.15678,-22.90521,-850.5343],
[-29.66045,-21.03051,-847.0228],
[-26.16412,-16.96198,-842.8217],
[-39.44074,-23.11775,-849.8582],
[-35.94441,-23.95374,-847.1989],
[-32.44807,-22.15234,-843.7105],
[-28.95174,-18.31294,-839.5814]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-12.226,-8.097858,-858.3603],
[-8.729665,-2.272218,-853.6068],
[-5.23333,5.639979,-848.1974],
[-1.736996,12.92764,-842.9843],
[-15.01362,-9.945128,-855.276],
[-11.51729,-4.499461,-850.642],
[-8.020954,3.071562,-845.3398],
[-4.524619,10.50084,-840.0822],
[-17.80125,-11.82342,-852.2014],
[-14.30491,-6.759742,-847.6875],
[-10.80858,0.4950685,-842.4847],
[-7.312242,7.894249,-837.2366],
[-20.58887,-13.57763,-849.0879],
[-17.09253,-8.858237,-844.6821],
[-13.5962,-1.93059,-839.5822],
[-10.09987,5.346364,-834.3725]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[25.47775,15.65077,-855.3521],
[28.97408,15.74221,-852.4012],
[32.47042,15.47131,-849.5642],
[35.96675,15.04273,-846.7769],
[22.69012,23.69485,-849.1583],
[26.18646,24.4139,-846.0101],
[29.68279,24.58336,-843.0347],
[33.17913,24.48835,-840.1425],
[19.9025,28.52281,-843.9755],
[23.39884,30.34717,-840.4799],
[26.89517,31.33496,-837.2473],
[30.3915,31.87018,-834.1569],
[17.11488,30.95034,-839.5474],
[20.61121,33.81136,-835.7259],
[24.10755,35.67997,-832.2163],
[27.60388,36.95605,-828.8931]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[6.625873,18.53172,-852.2177],
[10.12221,24.23587,-847.5024],
[13.61854,28.08931,-843.3689],
[17.11488,30.95034,-839.5474],
[3.83825,17.60147,-848.8451],
[7.334585,24.4342,-843.775],
[10.83092,29.48017,-839.2666],
[14.32725,33.37787,-835.1192],
[1.050627,15.35445,-845.8865],
[4.546962,22.5005,-840.7179],
[8.043297,28.53923,-835.8974],
[11.53963,33.40496,-831.4457],
[-1.736996,12.92764,-842.9843],
[1.759339,20.21531,-837.7712],
[5.255673,26.87844,-832.7545],
[8.752008,32.44872,-828.0813]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[-20.58887,-13.57763,-849.0879],
[-17.09253,-8.858237,-844.6821],
[-13.5962,-1.93059,-839.5822],
[-10.09987,5.346364,-834.3725],
[-23.37649,-15.33184,-845.9743],
[-19.88016,-10.95673,-841.6768],
[-16.38382,-4.356248,-836.6797],
[-12.88749,2.798478,-831.5085],
[-26.16412,-16.96198,-842.8217],
[-22.66778,-12.89344,-838.6206],
[-19.17145,-6.63107,-833.7298],
[-15.67511,0.309295,-828.6259],
[-28.95174,-18.31294,-839.5814],
[-25.4554,-14.47354,-835.4523],
[-21.95907,-8.596145,-830.6826],
[-18.46273,-1.8827,-825.65]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-819.4922]));

P.push(new BezierPatch([
[35.96675,15.04273,-846.7769],
[39.46309,14.61414,-843.9895],
[42.95942,14.02788,-841.2516],
[46.45575,13.4886,-838.4991],
[33.17913,24.48835,-840.1425],
[36.67546,24.39334,-837.2502],
[40.1718,24.03387,-834.4411],
[43.66813,23.69509,-831.6255],
[30.3915,31.87018,-834.1569],
[33.88784,32.40541,-831.0665],
[37.38417,32.48807,-828.1184],
[40.88051,32.50216,-825.1919],
[27.60388,36.95605,-828.8931],
[31.10022,38.23214,-825.5698],
[34.59655,38.91569,-822.4328],
[38.09289,39.40661,-819.3563]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-819.3563]));

P.push(new BezierPatch([
[-1.736996,12.92764,-842.9843],
[1.759339,20.21531,-837.7712],
[5.255673,26.87844,-832.7545],
[8.752008,32.44872,-828.0813],
[-4.524619,10.50084,-840.0822],
[-1.028284,17.93011,-834.8246],
[2.46805,25.21764,-829.6116],
[5.964385,31.49248,-824.7169],
[-7.312242,7.894249,-837.2366],
[-3.815907,15.29343,-831.9884],
[-0.3195727,22.83698,-826.6949],
[3.176762,29.55289,-821.6616],
[-10.09987,5.346364,-834.3725],
[-6.60353,12.62332,-829.1628],
[-3.107196,20.24958,-823.8433],
[0.3891388,27.24112,-818.7233]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-818.7233]));

P.push(new BezierPatch([
[17.11488,30.95034,-839.5474],
[20.61121,33.81136,-835.7259],
[24.10755,35.67997,-832.2163],
[27.60388,36.95605,-828.8931],
[14.32725,33.37787,-835.1192],
[17.82359,37.27556,-830.9718],
[21.31992,40.02499,-827.1854],
[24.81626,42.04193,-823.6292],
[11.53963,33.40496,-831.4457],
[15.03597,38.27069,-826.994],
[18.5323,41.96342,-822.911],
[22.02864,44.83184,-819.0872],
[8.752008,32.44872,-828.0813],
[12.24834,38.019,-823.4081],
[15.74468,42.49644,-819.0784],
[19.24101,46.12447,-815.0158]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-815.0158]));

P.push(new BezierPatch([
[-10.09987,5.346364,-834.3725],
[-6.60353,12.62332,-829.1628],
[-3.107196,20.24958,-823.8433],
[0.3891388,27.24112,-818.7233],
[-12.88749,2.798478,-831.5085],
[-9.391154,9.953204,-826.3372],
[-5.894819,17.66217,-820.9916],
[-2.398484,24.92936,-815.785],
[-15.67511,0.309295,-828.6259],
[-12.17878,7.24966,-823.522],
[-8.682442,14.86802,-818.205],
[-5.186107,22.24542,-812.9637],
[-18.46273,-1.8827,-825.65],
[-14.9664,4.830744,-820.6174],
[-11.47007,12.38023,-815.322],
[-7.97373,19.80047,-810.0673]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-810.0673]));

P.push(new BezierPatch([
[27.60388,36.95605,-828.8931],
[31.10022,38.23214,-825.5698],
[34.59655,38.91569,-822.4328],
[38.09289,39.40661,-819.3563],
[24.81626,42.04193,-823.6292],
[28.31259,44.05886,-820.0731],
[31.80893,45.34331,-816.7472],
[35.30526,46.31105,-813.5208],
[22.02864,44.83184,-819.0872],
[25.52497,47.70026,-815.2633],
[29.0213,49.74437,-811.6986],
[32.51764,51.31286,-808.2834],
[19.24101,46.12447,-815.0158],
[22.73735,49.7525,-810.9531],
[26.23368,52.53112,-807.1575],
[29.73002,54.70378,-803.5524]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-803.5524]));

P.push(new BezierPatch([
[8.752008,32.44872,-828.0813],
[12.24834,38.019,-823.4081],
[15.74468,42.49644,-819.0784],
[19.24101,46.12447,-815.0158],
[5.964385,31.49248,-824.7169],
[9.46072,37.76731,-819.8222],
[12.95705,43.02945,-815.2458],
[16.45339,47.41709,-810.9444],
[3.176762,29.55289,-821.6616],
[6.673097,36.26879,-816.6282],
[10.16943,42.15705,-811.8551],
[13.66577,47.21244,-807.3437],
[0.3891388,27.24112,-818.7233],
[3.885473,34.23267,-813.6033],
[7.381808,40.58951,-808.6828],
[10.87814,46.18486,-804.0017]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-803.5524]));

P.push(new BezierPatch([
[0.3891388,27.24112,-818.7233],
[3.885473,34.23267,-813.6033],
[7.381808,40.58951,-808.6828],
[10.87814,46.18486,-804.0017],
[-2.398484,24.92936,-815.785],
[1.09785,32.19655,-810.5783],
[4.594185,39.02196,-805.5106],
[8.09052,45.15728,-800.6597],
[-5.186107,22.24542,-812.9637],
[-1.689773,29.62282,-807.7224],
[1.806562,36.75927,-802.5568],
[5.302897,43.30677,-797.5764],
[-7.97373,19.80047,-810.0673],
[-4.477396,27.22071,-804.8125],
[-0.9810612,34.5117,-799.5984],
[2.515273,41.30771,-794.5398]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-794.5398]));

P.push(new BezierPatch([
[19.24101,46.12447,-815.0158],
[22.73735,49.7525,-810.9531],
[26.23368,52.53112,-807.1575],
[29.73002,54.70378,-803.5524],
[16.45339,47.41709,-810.9444],
[19.94972,51.80473,-806.643],
[23.44606,55.31787,-802.6165],
[26.94239,58.09469,-798.8214],
[13.66577,47.21244,-807.3437],
[17.1621,52.26782,-802.8324],
[20.65844,56.49032,-798.5829],
[24.15477,59.87472,-794.5968],
[10.87814,46.18486,-804.0017],
[14.37448,51.7802,-799.3206],
[17.87081,56.61406,-794.8789],
[21.36715,60.55966,-790.7165]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-790.7165]));

P.push(new BezierPatch([
[10.87814,46.18486,-804.0017],
[14.37448,51.7802,-799.3206],
[17.87081,56.61406,-794.8789],
[21.36715,60.55966,-790.7165],
[8.09052,45.15728,-800.6597],
[11.58685,51.29259,-795.8089],
[15.08319,56.7378,-791.175],
[18.57952,61.2446,-786.8361],
[5.302897,43.30677,-797.5764],
[8.799231,49.85428,-792.596],
[12.29557,55.81283,-787.8008],
[15.7919,60.83446,-783.3],
[2.515273,41.30771,-794.5398],
[6.011608,48.10372,-789.4813],
[9.507943,54.40474,-784.5784],
[13.00428,59.84505,-779.946]
],0,2,[-91.88576,-104.3702,-1019.833],[96.63297,97.55184,-779.946]));

Materials.push(new Material(
diffuse=[0,0,0,1],
emissive=[1,1,1,1],
specular=[0,0,0,1],
shininess=1,
metallic=0,
fresnel0=0.04
));

tube([[-8.257069,-74.62477,-1019.833],
[-4.760735,-69.18446,-1015.2],
[-1.2644,-62.88343,-1010.297],
[2.231935,-56.08742,-1005.239]
],0.25,0,3,[-8.574582,-77.36621,-1020.184],[2.558409,-53.36583,-1004.886],true);

disk([-8.257069,-74.62477,-1019.833],0.25,0,3,
[2.192386,-2.142003]);

disk([2.231935,-56.08742,-1005.239],0.25,0,3,
[0.9861139,1.095641]);

tube([[-16.61994,-75.33937,-1009.062],
[-13.1236,-71.39378,-1004.9],
[-9.627269,-66.55992,-1000.458],
[-6.130934,-60.96457,-995.7769]
],0.25,0,3,[-16.92165,-78.10367,-1009.41],[-5.812192,-58.23128,-995.4251],true);

disk([-16.61994,-75.33937,-1009.062],0.25,0,3,
[2.239145,-2.295899]);

disk([-6.130934,-60.96457,-995.7769],0.25,0,3,
[0.9537354,1.012294]);

tube([[-8.257069,-74.62477,-1019.833],
[-11.04469,-75.61417,-1016.479],
[-13.83232,-76.02431,-1012.943],
[-16.61994,-75.33937,-1009.062]
],0.25,0,3,[-16.8741,-78.07764,-1020.059],[-8.059457,-71.92529,-1008.774],true);

disk([-8.257069,-74.62477,-1019.833],0.25,0,3,
[2.418849,0.3410576]);

disk([-16.61994,-75.33937,-1009.062],0.25,0,3,
[0.6369175,2.900658]);

tube([[2.231935,-56.08742,-1005.239],
[-0.5556883,-58.08649,-1002.202],
[-3.343311,-59.93699,-999.1189],
[-6.130934,-60.96457,-995.7769]
],0.25,0,3,[-6.327207,-61.25014,-1006.047],[2.461349,-55.7951,-995.0098],true);

disk([2.231935,-56.08742,-1005.239],0.25,0,3,
[2.295387,0.6221241]);

disk([-6.130934,-60.96457,-995.7769],0.25,0,3,
[0.7266969,-2.788425]);

tube([[-16.61994,-75.33937,-1009.062],
[-13.1236,-71.39378,-1004.9],
[-9.627269,-66.55992,-1000.458],
[-6.130934,-60.96457,-995.7769]
],0.25,0,3,[-16.92165,-78.10367,-1009.41],[-5.812185,-58.23128,-995.4251],true);

disk([-16.61994,-75.33937,-1009.062],0.25,0,3,
[2.239145,-2.295899]);

disk([-6.130934,-60.96457,-995.7769],0.25,0,3,
[0.9537354,1.012294]);

tube([[-24.98281,-69.48349,-996.2262],
[-21.48647,-67.31083,-992.6211],
[-17.99014,-64.53221,-988.8255],
[-14.4938,-60.90418,-984.7629]
],0.25,0,3,[-25.25132,-72.27876,-996.5597],[-14.19663,-58.14589,-984.4171],true);

disk([-24.98281,-69.48349,-996.2262],0.25,0,3,
[2.290075,-2.585579]);

disk([-14.4938,-60.90418,-984.7629],0.25,0,3,
[0.8922191,0.8038812]);

tube([[-16.61994,-75.33937,-1009.062],
[-19.40756,-74.65443,-1005.182],
[-22.19518,-72.87441,-1000.957],
[-24.98281,-69.48349,-996.2262]
],0.25,0,3,[-25.29047,-78.79471,-1009.35],[-16.36595,-66.10401,-995.8914],true);

disk([-16.61994,-75.33937,-1009.062],0.25,0,3,
[2.504675,-0.2409348]);

disk([-24.98281,-69.48349,-996.2262],0.25,0,3,
[0.7479911,2.25886]);

tube([[-6.130934,-60.96457,-995.7769],
[-8.918558,-61.99215,-992.4349],
[-11.70618,-62.19681,-988.8342],
[-14.4938,-60.90418,-984.7629]
],0.25,0,3,[-14.76407,-64.42336,-996.0013],[-5.934841,-57.50268,-984.459],true);

disk([-6.130934,-60.96457,-995.7769],0.25,0,3,
[2.414896,0.3531676]);

disk([-14.4938,-60.90418,-984.7629],0.25,0,3,
[0.6465133,2.707402]);

tube([[2.231935,-56.08742,-1005.239],
[5.728269,-49.29142,-1000.18],
[9.224604,-42.00043,-994.9662],
[12.72094,-34.58019,-989.7114]
],0.25,0,3,[1.905716,-58.81172,-1005.591],[13.05019,-31.86349,-989.3587],true);

disk([2.231935,-56.08742,-1005.239],0.25,0,3,
[2.155479,-2.045952]);

disk([12.72094,-34.58019,-989.7114],0.25,0,3,
[1.001048,1.130462]);

tube([[-6.130934,-60.96457,-995.7769],
[-2.6346,-55.36922,-991.0958],
[0.8617348,-49.01238,-986.1754],
[4.358069,-42.02084,-981.0554]
],0.25,0,3,[-6.449449,-63.70385,-996.1285],[4.68532,-39.30133,-980.7027],true);

disk([-6.130934,-60.96457,-995.7769],0.25,0,3,
[2.187857,-2.129298]);

disk([4.358069,-42.02084,-981.0554],0.25,0,3,
[0.9909287,1.107085]);

tube([[2.231935,-56.08742,-1005.239],
[-0.5556883,-58.08649,-1002.202],
[-3.343311,-59.93699,-999.1189],
[-6.130934,-60.96457,-995.7769]
],0.25,0,3,[-6.32709,-61.2501,-1006.047],[2.461208,-55.79528,-995.0099],true);

disk([2.231935,-56.08742,-1005.239],0.25,0,3,
[2.295387,0.6221241]);

disk([-6.130934,-60.96457,-995.7769],0.25,0,3,
[0.7266969,-2.788425]);

tube([[12.72094,-34.58019,-989.7114],
[9.933316,-37.02513,-986.815],
[7.145692,-39.70907,-983.9937],
[4.358069,-42.02084,-981.0554]
],0.25,0,3,[4.118315,-42.31293,-991.3402],[12.96493,-34.28852,-979.4323],true);

disk([12.72094,-34.58019,-989.7114],0.25,0,3,
[2.233936,0.7200025]);

disk([4.358069,-42.02084,-981.0554],0.25,0,3,
[0.8891713,-2.449242]);

tube([[-6.130934,-60.96457,-995.7769],
[-2.6346,-55.36922,-991.0958],
[0.8617348,-49.01238,-986.1754],
[4.358069,-42.02084,-981.0554]
],0.25,0,3,[-6.449675,-63.70401,-996.1287],[4.685552,-39.30118,-980.7025],true);

disk([-6.130934,-60.96457,-995.7769],0.25,0,3,
[2.187857,-2.129298]);

disk([4.358069,-42.02084,-981.0554],0.25,0,3,
[0.9909287,1.107085]);

tube([[-14.4938,-60.90418,-984.7629],
[-10.99747,-57.27615,-980.7002],
[-7.501134,-52.79872,-976.3706],
[-4.0048,-47.22843,-971.6974]
],0.25,0,3,[-14.79095,-63.67441,-985.1086],[-3.686277,-44.49589,-971.3456],true);

disk([-14.4938,-60.90418,-984.7629],0.25,0,3,
[2.249374,-2.337711]);

disk([-4.0048,-47.22843,-971.6974],0.25,0,3,
[0.9530084,1.010275]);

tube([[-6.130934,-60.96457,-995.7769],
[-8.918558,-61.99215,-992.4349],
[-11.70618,-62.19681,-988.8342],
[-14.4938,-60.90418,-984.7629]
],0.25,0,3,[-14.76428,-64.42361,-996.0015],[-5.934692,-57.50248,-984.4587],true);

disk([-6.130934,-60.96457,-995.7769],0.25,0,3,
[2.414896,0.3531676]);

disk([-14.4938,-60.90418,-984.7629],0.25,0,3,
[0.6465133,2.707402]);

tube([[4.358069,-42.02084,-981.0554],
[1.570446,-44.3326,-978.1171],
[-1.217177,-46.27219,-975.0618],
[-4.0048,-47.22843,-971.6974]
],0.25,0,3,[-4.203551,-47.52107,-981.982],[4.597684,-41.72891,-970.8247],true);

disk([4.358069,-42.02084,-981.0554],0.25,0,3,
[2.252421,0.6923506]);

disk([-4.0048,-47.22843,-971.6974],0.25,0,3,
[0.7193698,-2.811139]);

tube([[-24.98281,-69.48349,-996.2262],
[-21.48647,-67.31083,-992.6211],
[-17.99014,-64.53221,-988.8255],
[-14.4938,-60.90418,-984.7629]
],0.25,0,3,[-25.25131,-72.27875,-996.5597],[-14.19665,-58.1459,-984.4171],true);

disk([-24.98281,-69.48349,-996.2262],0.25,0,3,
[2.290075,-2.585579]);

disk([-14.4938,-60.90418,-984.7629],0.25,0,3,
[0.8922191,0.8038812]);

tube([[-33.34568,-54.18632,-980.4223],
[-29.84934,-53.6954,-977.3459],
[-26.35301,-53.01185,-974.2089],
[-22.85667,-51.73577,-970.8856]
],0.25,0,3,[-33.55957,-57.00135,-980.7245],[-22.6141,-48.94159,-970.566],true);

disk([-33.34568,-54.18632,-980.4223],0.25,0,3,
[2.28756,-3.002096]);

disk([-22.85667,-51.73577,-970.8856],0.25,0,3,
[0.8419233,0.3499545]);

tube([[-24.98281,-69.48349,-996.2262],
[-27.77043,-66.09258,-991.4952],
[-30.55805,-61.09076,-986.2578],
[-33.34568,-54.18632,-980.4223]
],0.25,0,3,[-33.67846,-72.89268,-996.5613],[-24.67499,-50.83903,-980.0739],true);

disk([-24.98281,-69.48349,-996.2262],0.25,0,3,
[2.393602,-0.8827329]);

disk([-33.34568,-54.18632,-980.4223],0.25,0,3,
[0.906063,1.954526]);

tube([[-14.4938,-60.90418,-984.7629],
[-17.28143,-59.61155,-980.6915],
[-20.06905,-56.82164,-976.1494],
[-22.85667,-51.73577,-970.8856]
],0.25,0,3,[-23.1801,-64.3537,-985.067],[-14.22335,-48.37909,-970.541],true);

disk([-14.4938,-60.90418,-984.7629],0.25,0,3,
[2.495079,-0.4341902]);

disk([-22.85667,-51.73577,-970.8856],0.25,0,3,
[0.8337991,2.072188]);

tube([[12.72094,-34.58019,-989.7114],
[16.21727,-27.15995,-984.4566],
[19.71361,-19.61046,-979.1612],
[23.20994,-12.89701,-974.1287]
],0.25,0,3,[12.39142,-37.29694,-990.0644],[23.53598,-10.17157,-973.7759],true);

disk([12.72094,-34.58019,-989.7114],0.25,0,3,
[2.140545,-2.011131]);

disk([23.20994,-12.89701,-974.1287],0.25,0,3,
[0.9840427,1.090651]);

tube([[4.358069,-42.02084,-981.0554],
[7.854404,-35.02929,-975.9354],
[11.35074,-27.40303,-970.6159],
[14.84707,-20.12608,-965.4061]
],0.25,0,3,[4.030574,-44.74292,-981.4083],[15.17594,-17.40746,-965.0532],true);

disk([4.358069,-42.02084,-981.0554],0.25,0,3,
[2.150664,-2.034508]);

disk([14.84707,-20.12608,-965.4061],0.25,0,3,
[0.9977315,1.122897]);

tube([[12.72094,-34.58019,-989.7114],
[9.933316,-37.02513,-986.815],
[7.145692,-39.70907,-983.9937],
[4.358069,-42.02084,-981.0554]
],0.25,0,3,[4.118301,-42.31295,-991.3402],[12.96494,-34.2885,-979.4322],true);

disk([12.72094,-34.58019,-989.7114],0.25,0,3,
[2.233936,0.7200025]);

disk([4.358069,-42.02084,-981.0554],0.25,0,3,
[0.8891713,-2.449242]);

tube([[23.20994,-12.89701,-974.1287],
[20.42232,-15.08901,-971.1527],
[17.6347,-17.57819,-968.2702],
[14.84707,-20.12608,-965.4061]
],0.25,0,3,[14.59988,-20.4173,-975.6801],[23.44581,-12.60469,-963.8395],true);

disk([23.20994,-12.89701,-974.1287],0.25,0,3,
[2.26898,0.6663507]);

disk([14.84707,-20.12608,-965.4061],0.25,0,3,
[0.9219564,-2.401097]);

tube([[-14.4938,-60.90418,-984.7629],
[-10.99747,-57.27615,-980.7002],
[-7.501134,-52.79872,-976.3706],
[-4.0048,-47.22843,-971.6974]
],0.25,0,3,[-14.79094,-63.6744,-985.1086],[-3.686286,-44.4959,-971.3456],true);

disk([-14.4938,-60.90418,-984.7629],0.25,0,3,
[2.249374,-2.337711]);

disk([-4.0048,-47.22843,-971.6974],0.25,0,3,
[0.9530084,1.010275]);

tube([[-22.85667,-51.73577,-970.8856],
[-19.36034,-50.45969,-967.5623],
[-15.864,-48.59108,-964.0528],
[-12.36767,-45.73005,-960.2313]
],0.25,0,3,[-23.09927,-54.54671,-971.2052],[-12.08378,-42.96249,-959.8907],true);

disk([-22.85667,-51.73577,-970.8856],0.25,0,3,
[2.299669,-2.791638]);

disk([-12.36767,-45.73005,-960.2313],0.25,0,3,
[0.8686905,0.6857961]);

tube([[-14.4938,-60.90418,-984.7629],
[-17.28143,-59.61155,-980.6915],
[-20.06905,-56.82164,-976.1494],
[-22.85667,-51.73577,-970.8856]
],0.25,0,3,[-23.17996,-64.35356,-985.0669],[-14.22347,-48.37918,-970.5412],true);

disk([-14.4938,-60.90418,-984.7629],0.25,0,3,
[2.495079,-0.4341902]);

disk([-22.85667,-51.73577,-970.8856],0.25,0,3,
[0.8337991,2.072188]);

tube([[-4.0048,-47.22843,-971.6974],
[-6.792423,-48.18468,-968.333],
[-9.580046,-48.15758,-964.6594],
[-12.36767,-45.73005,-960.2313]
],0.25,0,3,[-12.66164,-50.69627,-971.9251],[-3.805944,-42.35155,-959.9066],true);

disk([-4.0048,-47.22843,-971.6974],0.25,0,3,
[2.422223,0.330454]);

disk([-12.36767,-45.73005,-960.2313],0.25,0,3,
[0.6955807,2.425133]);

tube([[4.358069,-42.02084,-981.0554],
[7.854404,-35.02929,-975.9354],
[11.35074,-27.40303,-970.6159],
[14.84707,-20.12608,-965.4061]
],0.25,0,3,[4.030508,-44.74296,-981.4084],[15.17601,-17.40741,-965.0531],true);

disk([4.358069,-42.02084,-981.0554],0.25,0,3,
[2.150664,-2.034508]);

disk([14.84707,-20.12608,-965.4061],0.25,0,3,
[0.9977315,1.122897]);

tube([[-4.0048,-47.22843,-971.6974],
[-0.5084652,-41.65815,-967.0242],
[2.987869,-34.99502,-962.0074],
[6.484204,-27.70735,-956.7943]
],0.25,0,3,[-4.323417,-49.96863,-972.0492],[6.813184,-24.99095,-956.4413],true);

disk([-4.0048,-47.22843,-971.6974],0.25,0,3,
[2.188584,-2.131318]);

disk([6.484204,-27.70735,-956.7943],0.25,0,3,
[0.9979816,1.123471]);

tube([[4.358069,-42.02084,-981.0554],
[1.570446,-44.3326,-978.1171],
[-1.217177,-46.27219,-975.0618],
[-4.0048,-47.22843,-971.6974]
],0.25,0,3,[-4.203658,-47.52117,-981.9822],[4.597812,-41.72876,-970.8245],true);

disk([4.358069,-42.02084,-981.0554],0.25,0,3,
[2.252421,0.6923506]);

disk([-4.0048,-47.22843,-971.6974],0.25,0,3,
[0.7193698,-2.811139]);

tube([[14.84707,-20.12608,-965.4061],
[12.05945,-22.67396,-962.5421],
[9.271827,-25.28055,-959.6965],
[6.484204,-27.70735,-956.7943]
],0.25,0,3,[6.240794,-27.99912,-967.0824],[15.09424,-19.83484,-955.1231],true);

disk([14.84707,-20.12608,-965.4061],0.25,0,3,
[2.219636,0.7404959]);

disk([6.484204,-27.70735,-956.7943],0.25,0,3,
[0.9051367,-2.42528]);

tube([[-33.34568,-54.18632,-980.4223],
[-29.84934,-53.6954,-977.3459],
[-26.35301,-53.01185,-974.2089],
[-22.85667,-51.73577,-970.8856]
],0.25,0,3,[-33.55957,-57.00135,-980.7245],[-22.61411,-48.94159,-970.566],true);

disk([-33.34568,-54.18632,-980.4223],0.25,0,3,
[2.28756,-3.002096]);

disk([-22.85667,-51.73577,-970.8856],0.25,0,3,
[0.8419233,0.3499545]);

tube([[-41.70855,-28.26831,-961.2796],
[-38.21221,-28.80759,-958.527],
[-34.71588,-29.39386,-955.7892],
[-31.21954,-29.82244,-953.0018]
],0.25,0,3,[-41.87799,-31.07731,-961.5512],[-31.04508,-27.01515,-952.7265],true);

disk([-41.70855,-28.26831,-961.2796],0.25,0,3,
[2.232026,2.988557]);

disk([-31.21954,-29.82244,-953.0018],0.25,0,3,
[0.9013772,-0.1219719]);

tube([[-33.34568,-54.18632,-980.4223],
[-36.1333,-47.28188,-974.5868],
[-38.92092,-38.4748,-968.1532],
[-41.70855,-28.26831,-961.2796]
],0.25,0,3,[-42.04984,-57.54624,-980.7708],[-33.01278,-24.93977,-960.9299],true);

disk([-33.34568,-54.18632,-980.4223],0.25,0,3,
[2.23553,-1.187067]);

disk([-41.70855,-28.26831,-961.2796],0.25,0,3,
[0.9946637,1.837416]);

tube([[-22.85667,-51.73577,-970.8856],
[-25.6443,-46.64989,-965.6218],
[-28.43192,-39.26806,-959.6362],
[-31.21954,-29.82244,-953.0018]
],0.25,0,3,[-31.55945,-55.11859,-971.2302],[-22.5332,-26.49216,-952.6521],true);

disk([-22.85667,-51.73577,-970.8856],0.25,0,3,
[2.307794,-1.069405]);

disk([-31.21954,-29.82244,-953.0018],0.25,0,3,
[0.977974,1.857773]);

tube([[23.20994,-12.89701,-974.1287],
[26.70628,-6.183568,-969.0961],
[30.20261,-0.3061694,-964.3263],
[33.69895,3.533229,-960.1973]
],0.25,0,3,[22.88396,-15.61542,-974.4814],[33.99915,6.301214,-959.8505],true);

disk([23.20994,-12.89701,-974.1287],0.25,0,3,
[2.15755,-2.050942]);

disk([33.69895,3.533229,-960.1973],0.25,0,3,
[0.899018,0.8321302]);

tube([[14.84707,-20.12608,-965.4061],
[18.34341,-12.84912,-960.1964],
[21.83974,-5.921475,-955.0965],
[25.33608,-1.202083,-950.6908]
],0.25,0,3,[14.51826,-22.84063,-965.759],[25.64691,1.55101,-950.3408],true);

disk([14.84707,-20.12608,-965.4061],0.25,0,3,
[2.143861,-2.018696]);

disk([25.33608,-1.202083,-950.6908],0.25,0,3,
[0.9272237,0.9331807]);

tube([[23.20994,-12.89701,-974.1287],
[20.42232,-15.08901,-971.1527],
[17.6347,-17.57819,-968.2702],
[14.84707,-20.12608,-965.4061]
],0.25,0,3,[14.59997,-20.41719,-975.68],[23.44572,-12.6048,-963.8396],true);

disk([23.20994,-12.89701,-974.1287],0.25,0,3,
[2.26898,0.6663507]);

disk([14.84707,-20.12608,-965.4061],0.25,0,3,
[0.9219564,-2.401097]);

tube([[33.69895,3.533229,-960.1973],
[30.91132,2.182266,-956.9569],
[28.1237,0.5521297,-953.8044],
[25.33608,-1.202083,-950.6908]
],0.25,0,3,[25.11529,-1.493585,-960.9328],[33.90536,3.821924,-949.9373],true);

disk([33.69895,3.533229,-960.1973],0.25,0,3,
[2.37869,0.4512754]);

disk([25.33608,-1.202083,-950.6908],0.25,0,3,
[0.8134964,-2.579917]);

tube([[-4.0048,-47.22843,-971.6974],
[-0.5084652,-41.65815,-967.0242],
[2.987869,-34.99502,-962.0074],
[6.484204,-27.70735,-956.7943]
],0.25,0,3,[-4.323324,-49.96856,-972.0491],[6.813088,-24.99101,-956.4414],true);

disk([-4.0048,-47.22843,-971.6974],0.25,0,3,
[2.188584,-2.131318]);

disk([6.484204,-27.70735,-956.7943],0.25,0,3,
[0.9979816,1.123471]);

tube([[-12.36767,-45.73005,-960.2313],
[-8.871334,-42.86902,-956.4097],
[-5.375,-39.01558,-952.2762],
[-1.878665,-33.31143,-947.561]
],0.25,0,3,[-12.65151,-48.51598,-960.5717],[-1.559124,-30.58397,-947.209],true);

disk([-12.36767,-45.73005,-960.2313],0.25,0,3,
[2.272902,-2.455797]);

disk([-1.878665,-33.31143,-947.561],0.25,0,3,
[0.9568679,1.020912]);

tube([[-4.0048,-47.22843,-971.6974],
[-6.792423,-48.18468,-968.333],
[-9.580046,-48.15758,-964.6594],
[-12.36767,-45.73005,-960.2313]
],0.25,0,3,[-12.66159,-50.69621,-971.925],[-3.805959,-42.35159,-959.9067],true);

disk([-4.0048,-47.22843,-971.6974],0.25,0,3,
[2.422223,0.330454]);

disk([-12.36767,-45.73005,-960.2313],0.25,0,3,
[0.6955807,2.425133]);

tube([[6.484204,-27.70735,-956.7943],
[3.696581,-30.13416,-953.8922],
[0.9089579,-32.38118,-950.9335],
[-1.878665,-33.31143,-947.561]
],0.25,0,3,[-2.078495,-33.60595,-957.8506],[6.727615,-27.4156,-946.5629],true);

disk([6.484204,-27.70735,-956.7943],0.25,0,3,
[2.236456,0.7163122]);

disk([-1.878665,-33.31143,-947.561],0.25,0,3,
[0.7167649,-2.819506]);

tube([[-22.85667,-51.73577,-970.8856],
[-19.36034,-50.45969,-967.5623],
[-15.864,-48.59108,-964.0528],
[-12.36767,-45.73005,-960.2313]
],0.25,0,3,[-23.09924,-54.54667,-971.2052],[-12.08382,-42.96252,-959.8908],true);

disk([-22.85667,-51.73577,-970.8856],0.25,0,3,
[2.299669,-2.791638]);

disk([-12.36767,-45.73005,-960.2313],0.25,0,3,
[0.8686905,0.6857961]);

tube([[-31.21954,-29.82244,-953.0018],
[-27.72321,-30.25102,-950.2144],
[-24.22687,-30.52192,-947.3774],
[-20.73054,-30.43048,-944.4266]
],0.25,0,3,[-31.39396,-32.63633,-953.277],[-20.53318,-27.62644,-944.1353],true);

disk([-31.21954,-29.82244,-953.0018],0.25,0,3,
[2.240215,3.019621]);

disk([-20.73054,-30.43048,-944.4266],0.25,0,3,
[0.8699712,0.02614784]);

tube([[-22.85667,-51.73577,-970.8856],
[-25.6443,-46.64989,-965.6218],
[-28.43192,-39.26806,-959.6362],
[-31.21954,-29.82244,-953.0018]
],0.25,0,3,[-31.5593,-55.11844,-971.2301],[-22.53334,-26.49227,-952.6522],true);

disk([-22.85667,-51.73577,-970.8856],0.25,0,3,
[2.307794,-1.069405]);

disk([-31.21954,-29.82244,-953.0018],0.25,0,3,
[0.977974,1.857773]);

tube([[-12.36767,-45.73005,-960.2313],
[-15.15529,-43.30252,-955.8031],
[-17.94292,-38.47457,-950.6204],
[-20.73054,-30.43048,-944.4266]
],0.25,0,3,[-21.0672,-49.1604,-960.5559],[-12.07367,-27.09979,-944.0771],true);

disk([-12.36767,-45.73005,-960.2313],0.25,0,3,
[2.446012,-0.7164596]);

disk([-20.73054,-30.43048,-944.4266],0.25,0,3,
[0.9418303,1.904388]);

tube([[14.84707,-20.12608,-965.4061],
[18.34341,-12.84912,-960.1964],
[21.83974,-5.921475,-955.0965],
[25.33608,-1.202083,-950.6908]
],0.25,0,3,[14.51831,-22.84066,-965.759],[25.64687,1.551045,-950.3408],true);

disk([14.84707,-20.12608,-965.4061],0.25,0,3,
[2.143861,-2.018696]);

disk([25.33608,-1.202083,-950.6908],0.25,0,3,
[0.9272237,0.9331807]);

tube([[6.484204,-27.70735,-956.7943],
[9.980539,-20.41969,-951.5812],
[13.47687,-12.50749,-946.1718],
[16.97321,-6.681855,-941.4183]
],0.25,0,3,[6.155358,-30.42409,-957.1472],[17.2936,-3.945373,-941.0663],true);

disk([6.484204,-27.70735,-956.7943],0.25,0,3,
[2.143611,-2.018122]);

disk([16.97321,-6.681855,-941.4183],0.25,0,3,
[0.9603199,1.030257]);

tube([[14.84707,-20.12608,-965.4061],
[12.05945,-22.67396,-962.5421],
[9.271827,-25.28055,-959.6965],
[6.484204,-27.70735,-956.7943]
],0.25,0,3,[6.240884,-27.99896,-967.0823],[15.09416,-19.835,-955.1233],true);

disk([14.84707,-20.12608,-965.4061],0.25,0,3,
[2.219636,0.7404959]);

disk([6.484204,-27.70735,-956.7943],0.25,0,3,
[0.9051367,-2.42528]);

tube([[25.33608,-1.202083,-950.6908],
[22.54845,-2.956296,-947.5772],
[19.76083,-4.834585,-944.5027],
[16.97321,-6.681855,-941.4183]
],0.25,0,3,[16.74912,-6.973719,-951.6773],[25.55692,-0.9105643,-940.4277],true);

disk([25.33608,-1.202083,-950.6908],0.25,0,3,
[2.328096,0.5616756]);

disk([16.97321,-6.681855,-941.4183],0.25,0,3,
[0.8257939,-2.556363]);

tube([[33.69895,3.533229,-960.1973],
[37.19528,7.372627,-956.0682],
[40.69162,9.174024,-952.5798],
[44.18795,8.338036,-949.9205]
],0.25,0,3,[33.3987,0.8059008,-960.5441],[44.3438,11.17764,-949.659],true);

disk([33.69895,3.533229,-960.1973],0.25,0,3,
[2.242575,-2.309462]);

disk([44.18795,8.338036,-949.9205],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[25.33608,-1.202083,-950.6908],
[28.83241,3.517309,-946.2851],
[32.32875,6.028446,-942.5735],
[35.82508,5.192457,-939.9142]
],0.25,0,3,[25.02521,-3.913907,-951.0408],[35.98092,8.034588,-939.6528],true);

disk([25.33608,-1.202083,-950.6908],0.25,0,3,
[2.214369,-2.208412]);

disk([35.82508,5.192457,-939.9142],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[33.69895,3.533229,-960.1973],
[30.91132,2.182266,-956.9569],
[28.1237,0.5521297,-953.8044],
[25.33608,-1.202083,-950.6908]
],0.25,0,3,[25.11517,-1.493685,-960.9328],[33.90544,3.822009,-949.9371],true);

disk([33.69895,3.533229,-960.1973],0.25,0,3,
[2.37869,0.4512754]);

disk([25.33608,-1.202083,-950.6908],0.25,0,3,
[0.8134964,-2.579917]);

tube([[44.18795,8.338036,-949.9205],
[41.40033,7.28951,-946.585],
[38.6127,6.240984,-943.2496],
[35.82508,5.192457,-939.9142]
],0.25,0,3,[35.62961,4.907225,-950.1441],[44.38342,8.623268,-939.6906],true);

disk([44.18795,8.338036,-949.9205],0.25,0,3,
[2.412696,0.3597665]);

disk([35.82508,5.192457,-939.9142],0.25,0,3,
[0.728897,-2.781826]);

tube([[-12.36767,-45.73005,-960.2313],
[-8.871334,-42.86902,-956.4097],
[-5.375,-39.01558,-952.2762],
[-1.878665,-33.31143,-947.561]
],0.25,0,3,[-12.65154,-48.51601,-960.5718],[-1.559088,-30.58394,-947.209],true);

disk([-12.36767,-45.73005,-960.2313],0.25,0,3,
[2.272902,-2.455797]);

disk([-1.878665,-33.31143,-947.561],0.25,0,3,
[0.9568679,1.020912]);

tube([[-20.73054,-30.43048,-944.4266],
[-17.2342,-30.33904,-941.4757],
[-13.73787,-29.88525,-938.4109],
[-10.24153,-28.47944,-935.0469]
],0.25,0,3,[-20.92792,-33.25186,-944.7179],[-9.994748,-25.69181,-934.7249],true);

disk([-20.73054,-30.43048,-944.4266],0.25,0,3,
[2.271621,-3.115445]);

disk([-10.24153,-28.47944,-935.0469],0.25,0,3,
[0.8420255,0.3823001]);

tube([[-12.36767,-45.73005,-960.2313],
[-15.15529,-43.30252,-955.8031],
[-17.94292,-38.47457,-950.6204],
[-20.73054,-30.43048,-944.4266]
],0.25,0,3,[-21.06714,-49.16035,-960.5559],[-12.07371,-27.09982,-944.0772],true);

disk([-12.36767,-45.73005,-960.2313],0.25,0,3,
[2.446012,-0.7164596]);

disk([-20.73054,-30.43048,-944.4266],0.25,0,3,
[0.9418303,1.904388]);

tube([[-1.878665,-33.31143,-947.561],
[-4.666288,-34.24168,-944.1884],
[-7.453911,-33.85515,-940.4018],
[-10.24153,-28.47944,-935.0469]
],0.25,0,3,[-10.56687,-36.79101,-947.7898],[-1.678857,-25.15356,-934.7014],true);

disk([-1.878665,-33.31143,-947.561],0.25,0,3,
[2.424828,0.3220863]);

disk([-10.24153,-28.47944,-935.0469],0.25,0,3,
[0.8467174,2.04918]);

tube([[-41.70855,-28.26831,-961.2796],
[-38.21221,-28.80759,-958.527],
[-34.71588,-29.39386,-955.7892],
[-31.21954,-29.82244,-953.0018]
],0.25,0,3,[-41.87789,-31.07719,-961.5511],[-31.04519,-27.01527,-952.7266],true);

disk([-41.70855,-28.26831,-961.2796],0.25,0,3,
[2.232026,2.988557]);

disk([-31.21954,-29.82244,-953.0018],0.25,0,3,
[0.9013772,-0.1219719]);

tube([[-50.07142,5.149972,-939.7791],
[-46.57508,4.313983,-937.1198],
[-43.07875,3.477995,-934.4604],
[-39.58241,2.642006,-931.8011]
],0.25,0,3,[-50.22727,2.344879,-940.0405],[-39.42655,5.447099,-931.5397],true);

disk([-50.07142,5.149972,-939.7791],0.25,0,3,
[2.207705,2.906895]);

disk([-39.58241,2.642006,-931.8011],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[-41.70855,-28.26831,-961.2796],
[-44.49617,-18.06183,-954.406],
[-47.28379,-6.455927,-947.0926],
[-50.07142,5.149972,-939.7791]
],0.25,0,3,[-50.41458,-31.60071,-961.6292],[-41.36734,8.473289,-939.4297],true);

disk([-41.70855,-28.26831,-961.2796],0.25,0,3,
[2.146929,-1.304176]);

disk([-50.07142,5.149972,-939.7791],0.25,0,3,
[1.021072,1.806521]);

tube([[-31.21954,-29.82244,-953.0018],
[-34.00717,-20.37682,-946.3674],
[-36.79479,-8.867406,-939.0843],
[-39.58241,2.642006,-931.8011]
],0.25,0,3,[-39.92546,-33.16007,-953.3515],[-30.87969,5.965338,-931.4518],true);

disk([-31.21954,-29.82244,-953.0018],0.25,0,3,
[2.163619,-1.28382]);

disk([-39.58241,2.642006,-931.8011],0.25,0,3,
[1.019408,1.808424]);

tube([[6.484204,-27.70735,-956.7943],
[9.980539,-20.41969,-951.5812],
[13.47687,-12.50749,-946.1718],
[16.97321,-6.681855,-941.4183]
],0.25,0,3,[6.155244,-30.4241,-957.1473],[17.29372,-3.945375,-941.0662],true);

disk([6.484204,-27.70735,-956.7943],0.25,0,3,
[2.143611,-2.018122]);

disk([16.97321,-6.681855,-941.4183],0.25,0,3,
[0.9603199,1.030257]);

tube([[-1.878665,-33.31143,-947.561],
[1.617669,-27.60728,-942.8457],
[5.114004,-20.05242,-937.5486],
[8.610339,-13.21628,-932.4774]
],0.25,0,3,[-2.198285,-36.04906,-947.9129],[8.937076,-10.49474,-932.1246],true);

disk([-1.878665,-33.31143,-947.561],0.25,0,3,
[2.184725,-2.12068]);

disk([8.610339,-13.21628,-932.4774],0.25,0,3,
[0.9871125,1.098032]);

tube([[6.484204,-27.70735,-956.7943],
[3.696581,-30.13416,-953.8922],
[0.9089579,-32.38118,-950.9335],
[-1.878665,-33.31143,-947.561]
],0.25,0,3,[-2.078464,-33.60579,-957.8505],[6.727587,-27.41575,-946.5629],true);

disk([6.484204,-27.70735,-956.7943],0.25,0,3,
[2.236456,0.7163122]);

disk([-1.878665,-33.31143,-947.561],0.25,0,3,
[0.7167649,-2.819506]);

tube([[16.97321,-6.681855,-941.4183],
[14.18558,-8.529125,-938.334],
[11.39796,-10.34538,-935.24],
[8.610339,-13.21628,-932.4774]
],0.25,0,3,[8.35366,-13.50538,-942.7356],[17.19736,-6.389881,-931.1165],true);

disk([16.97321,-6.681855,-941.4183],0.25,0,3,
[2.315799,0.5852296]);

disk([8.610339,-13.21628,-932.4774],0.25,0,3,
[0.9665752,-2.341478]);

tube([[44.18795,8.338036,-949.9205],
[47.68428,7.502047,-947.2611],
[51.18062,4.028673,-945.4309],
[54.67695,-1.482703,-944.2414]
],0.25,0,3,[44.03206,-1.736661,-952.4564],[54.97728,8.635225,-941.5711],true);

disk([44.18795,8.338036,-949.9205],0.25,0,3,
[2.207705,2.906895]);

disk([54.67695,-1.482703,-944.2414],0.25,0,3,
[1.39052,-1.005477]);

tube([[35.82508,5.192457,-939.9142],
[39.32142,4.356469,-937.2549],
[42.81775,0.1733552,-935.6478],
[46.31408,-6.218014,-934.7349]
],0.25,0,3,[35.66928,-6.458929,-942.9449],[46.62492,5.489542,-931.557],true);

disk([35.82508,5.192457,-939.9142],0.25,0,3,
[2.207705,2.906895]);

disk([46.31408,-6.218014,-934.7349],0.25,0,3,
[1.446135,-1.070229]);

tube([[44.18795,8.338036,-949.9205],
[41.40033,7.28951,-946.585],
[38.6127,6.240984,-943.2496],
[35.82508,5.192457,-939.9142]
],0.25,0,3,[35.62961,4.907214,-950.1441],[44.38342,8.623279,-939.6906],true);

disk([44.18795,8.338036,-949.9205],0.25,0,3,
[2.412696,0.3597665]);

disk([35.82508,5.192457,-939.9142],0.25,0,3,
[0.728897,-2.781826]);

tube([[54.67695,-1.482703,-944.2414],
[51.88933,-2.833666,-941.001],
[49.10171,-4.463802,-937.8485],
[46.31408,-6.218014,-934.7349]
],0.25,0,3,[46.09322,-6.509595,-944.9769],[54.88343,-1.193933,-933.9813],true);

disk([54.67695,-1.482703,-944.2414],0.25,0,3,
[2.37869,0.4512754]);

disk([46.31408,-6.218014,-934.7349],0.25,0,3,
[0.8134964,-2.579917]);

tube([[25.33608,-1.202083,-950.6908],
[28.83241,3.517309,-946.2851],
[32.32875,6.028446,-942.5735],
[35.82508,5.192457,-939.9142]
],0.25,0,3,[25.02524,-3.913921,-951.0408],[35.98085,8.034641,-939.6529],true);

disk([25.33608,-1.202083,-950.6908],0.25,0,3,
[2.214369,-2.208412]);

disk([35.82508,5.192457,-939.9142],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[16.97321,-6.681855,-941.4183],
[18.72138,-3.769035,-939.0416],
[20.46954,-1.377855,-936.8288],
[22.21771,0.1806226,-934.8779]
],0.25,0,3,[16.65274,-8.154836,-941.7705],[22.50642,1.707517,-934.5353],true);

tube([[22.21771,0.1806226,-934.8779],
[23.96588,1.7391,-932.9269],
[25.71404,2.464873,-931.2377],
[27.46221,2.046879,-929.908]
],0.25,0,3,[21.929,-1.306912,-935.2204],[27.61806,3.629753,-929.6465],true);

disk([16.97321,-6.681855,-941.4183],0.25,0,3,
[2.181273,-2.111336]);

disk([27.46221,2.046879,-929.908],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[25.33608,-1.202083,-950.6908],
[22.54845,-2.956296,-947.5772],
[19.76083,-4.834585,-944.5027],
[16.97321,-6.681855,-941.4183]
],0.25,0,3,[16.74905,-6.973802,-951.6774],[25.55699,-0.9104812,-940.4276],true);

disk([25.33608,-1.202083,-950.6908],0.25,0,3,
[2.328096,0.5616756]);

disk([16.97321,-6.681855,-941.4183],0.25,0,3,
[0.8257939,-2.556363]);

tube([[35.82508,5.192457,-939.9142],
[33.03746,4.143931,-936.5788],
[30.24983,3.095405,-933.2434],
[27.46221,2.046879,-929.908]
],0.25,0,3,[27.26674,1.761647,-940.1379],[36.02056,5.47769,-929.6843],true);

disk([35.82508,5.192457,-939.9142],0.25,0,3,
[2.412696,0.3597665]);

disk([27.46221,2.046879,-929.908],0.25,0,3,
[0.728897,-2.781826]);

tube([[54.67695,-1.482703,-944.2414],
[58.17329,-6.994078,-943.0518],
[61.66962,-14.54345,-942.5029],
[65.16596,-22.92887,-942.2169]
],0.25,0,3,[54.3767,-23.14551,-950.528],[65.49199,-1.228808,-935.897],true);

disk([54.67695,-1.482703,-944.2414],0.25,0,3,
[1.751073,2.136116]);

disk([65.16596,-22.92887,-942.2169],0.25,0,3,
[1.53932,-1.17576]);

tube([[46.31408,-6.218014,-934.7349],
[49.81042,-12.60938,-933.822],
[53.30675,-21.20901,-933.6032],
[56.80309,-30.15794,-933.4943]
],0.25,0,3,[46.00319,-30.36892,-941.8116],[57.13196,-5.977072,-926.3932],true);

disk([46.31408,-6.218014,-934.7349],0.25,0,3,
[1.695458,2.071364]);

disk([56.80309,-30.15794,-933.4943],0.25,0,3,
[1.559461,-1.198334]);

tube([[54.67695,-1.482703,-944.2414],
[51.88933,-2.833666,-941.001],
[49.10171,-4.463802,-937.8485],
[46.31408,-6.218014,-934.7349]
],0.25,0,3,[46.09324,-6.509613,-944.9769],[54.88343,-1.193908,-933.9813],true);

disk([54.67695,-1.482703,-944.2414],0.25,0,3,
[2.37869,0.4512754]);

disk([46.31408,-6.218014,-934.7349],0.25,0,3,
[0.8134964,-2.579917]);

tube([[65.16596,-22.92887,-942.2169],
[62.37833,-25.12087,-939.2409],
[59.59071,-27.61005,-936.3584],
[56.80309,-30.15794,-933.4943]
],0.25,0,3,[56.55592,-30.44914,-943.7683],[65.4018,-22.63658,-931.9277],true);

disk([65.16596,-22.92887,-942.2169],0.25,0,3,
[2.26898,0.6663507]);

disk([56.80309,-30.15794,-933.4943],0.25,0,3,
[0.9219564,-2.401097]);

tube([[-31.21954,-29.82244,-953.0018],
[-27.72321,-30.25102,-950.2144],
[-24.22687,-30.52192,-947.3774],
[-20.73054,-30.43048,-944.4266]
],0.25,0,3,[-31.39401,-32.63642,-953.2771],[-20.53312,-27.62635,-944.1352],true);

disk([-31.21954,-29.82244,-953.0018],0.25,0,3,
[2.240215,3.019621]);

disk([-20.73054,-30.43048,-944.4266],0.25,0,3,
[0.8699712,0.02614784]);

tube([[-39.58241,2.642006,-931.8011],
[-36.08608,1.806018,-929.1418],
[-32.58974,0.9700291,-926.4825],
[-29.09341,0.1340406,-923.8232]
],0.25,0,3,[-39.7383,-0.1631462,-932.0626],[-28.93752,2.939193,-923.5617],true);

disk([-39.58241,2.642006,-931.8011],0.25,0,3,
[2.207705,2.906895]);

disk([-29.09341,0.1340406,-923.8232],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[-31.21954,-29.82244,-953.0018],
[-34.00717,-20.37682,-946.3674],
[-36.79479,-8.867406,-939.0843],
[-39.58241,2.642006,-931.8011]
],0.25,0,3,[-39.92546,-33.16007,-953.3515],[-30.87969,5.965338,-931.4518],true);

disk([-31.21954,-29.82244,-953.0018],0.25,0,3,
[2.163619,-1.28382]);

disk([-39.58241,2.642006,-931.8011],0.25,0,3,
[1.019408,1.808424]);

tube([[-20.73054,-30.43048,-944.4266],
[-23.51816,-22.38639,-938.2328],
[-26.30578,-11.12618,-931.028],
[-29.09341,0.1340406,-923.8232]
],0.25,0,3,[-29.43614,-33.77948,-944.7759],[-20.39397,3.457525,-923.4738],true);

disk([-20.73054,-30.43048,-944.4266],0.25,0,3,
[2.199762,-1.237204]);

disk([-29.09341,0.1340406,-923.8232],0.25,0,3,
[1.015009,1.813481]);

tube([[65.16596,-22.92887,-942.2169],
[68.66229,-31.3143,-941.9308],
[72.15863,-40.53576,-941.9076],
[75.65496,-49.62798,-941.8437]
],0.25,0,3,[64.84005,-49.83752,-950.1718],[75.98435,-22.71232,-933.8836],true);

disk([65.16596,-22.92887,-942.2169],0.25,0,3,
[1.602273,1.965832]);

disk([75.65496,-49.62798,-941.8437],0.25,0,3,
[1.56424,-1.203687]);

tube([[56.80309,-30.15794,-933.4943],
[60.29942,-39.10687,-933.3854],
[63.79576,-48.40511,-933.3863],
[67.29209,-57.06863,-933.1877]
],0.25,0,3,[56.47435,-57.28234,-941.5194],[67.61946,-29.94704,-925.1646],true);

disk([56.80309,-30.15794,-933.4943],0.25,0,3,
[1.582132,1.943259]);

disk([67.29209,-57.06863,-933.1877],0.25,0,3,
[1.549538,-1.187217]);

tube([[65.16596,-22.92887,-942.2169],
[62.37833,-25.12087,-939.2409],
[59.59071,-27.61005,-936.3584],
[56.80309,-30.15794,-933.4943]
],0.25,0,3,[56.55593,-30.44912,-943.7683],[65.40179,-22.63659,-931.9277],true);

disk([65.16596,-22.92887,-942.2169],0.25,0,3,
[2.26898,0.6663507]);

disk([56.80309,-30.15794,-933.4943],0.25,0,3,
[0.9219564,-2.401097]);

tube([[75.65496,-49.62798,-941.8437],
[72.86734,-52.07293,-938.9473],
[70.07972,-54.75687,-936.126],
[67.29209,-57.06863,-933.1877]
],0.25,0,3,[67.05236,-57.3607,-943.4725],[75.89893,-49.33634,-931.5646],true);

disk([75.65496,-49.62798,-941.8437],0.25,0,3,
[2.233936,0.7200025]);

disk([67.29209,-57.06863,-933.1877],0.25,0,3,
[0.8891713,-2.449242]);

tube([[75.65496,-49.62798,-941.8437],
[79.1513,-58.7202,-941.7798],
[82.64763,-67.68316,-941.6753],
[86.14397,-76.15115,-941.4152]
],0.25,0,3,[75.32555,-76.36686,-949.7482],[86.47035,-49.41843,-933.5152],true);

disk([75.65496,-49.62798,-941.8437],0.25,0,3,
[1.577353,1.937906]);

disk([86.14397,-76.15115,-941.4152],0.25,0,3,
[1.542412,-1.179229]);

tube([[67.29209,-57.06863,-933.1877],
[70.78843,-65.73215,-932.989],
[74.28476,-73.76097,-932.5909],
[77.7811,-81.02829,-931.9533]
],0.25,0,3,[66.9647,-81.25762,-940.2896],[78.09975,-56.85491,-924.8636],true);

disk([67.29209,-57.06863,-933.1877],0.25,0,3,
[1.592055,1.954376]);

disk([77.7811,-81.02829,-931.9533],0.25,0,3,
[1.491906,-1.12238]);

tube([[75.65496,-49.62798,-941.8437],
[72.86734,-52.07293,-938.9473],
[70.07972,-54.75687,-936.126],
[67.29209,-57.06863,-933.1877]
],0.25,0,3,[67.05243,-57.36061,-943.4724],[75.89885,-49.33643,-931.5647],true);

disk([75.65496,-49.62798,-941.8437],0.25,0,3,
[2.233936,0.7200025]);

disk([67.29209,-57.06863,-933.1877],0.25,0,3,
[0.8891713,-2.449242]);

tube([[86.14397,-76.15115,-941.4152],
[83.35634,-78.15021,-938.3786],
[80.56872,-80.00071,-935.2953],
[77.7811,-81.02829,-931.9533]
],0.25,0,3,[77.58492,-81.31382,-942.2236],[86.37321,-75.85899,-931.1863],true);

disk([86.14397,-76.15115,-941.4152],0.25,0,3,
[2.295387,0.6221241]);

disk([77.7811,-81.02829,-931.9533],0.25,0,3,
[0.7266969,-2.788425]);

tube([[86.14397,-76.15115,-941.4152],
[89.6403,-84.61913,-941.1551],
[93.13664,-92.59214,-940.7394],
[96.63297,-99.70442,-940.0531]
],0.25,0,3,[85.81748,-99.93576,-948.3895],[96.95049,-75.93537,-933.0912],true);

disk([86.14397,-76.15115,-941.4152],0.25,0,3,
[1.59918,1.962364]);

disk([96.63297,-99.70442,-940.0531],0.25,0,3,
[1.484416,-1.113899]);

tube([[77.7811,-81.02829,-931.9533],
[81.27743,-88.29562,-931.3158],
[84.77377,-94.80146,-930.4388],
[88.2701,-100.419,-929.2827]
],0.25,0,3,[77.46235,-100.6713,-937.6209],[88.57182,-80.7989,-923.6365],true);

disk([77.7811,-81.02829,-931.9533],0.25,0,3,
[1.649687,2.019213]);

disk([88.2701,-100.419,-929.2827],0.25,0,3,
[1.397809,-1.014074]);

tube([[86.14397,-76.15115,-941.4152],
[83.35634,-78.15021,-938.3786],
[80.56872,-80.00071,-935.2953],
[77.7811,-81.02829,-931.9533]
],0.25,0,3,[77.58492,-81.31374,-942.2236],[86.37319,-75.859,-931.1863],true);

disk([86.14397,-76.15115,-941.4152],0.25,0,3,
[2.295387,0.6221241]);

disk([77.7811,-81.02829,-931.9533],0.25,0,3,
[0.7266969,-2.788425]);

tube([[96.63297,-99.70442,-940.0531],
[93.84535,-100.6938,-936.6991],
[91.05772,-101.104,-933.163],
[88.2701,-100.419,-929.2827]
],0.25,0,3,[88.01604,-103.1572,-940.2793],[96.83053,-97.00504,-928.9944],true);

disk([96.63297,-99.70442,-940.0531],0.25,0,3,
[2.418849,0.3410576]);

disk([88.2701,-100.419,-929.2827],0.25,0,3,
[0.6369175,2.900658]);

tube([[-1.878665,-33.31143,-947.561],
[1.617669,-27.60728,-942.8457],
[5.114004,-20.05242,-937.5486],
[8.610339,-13.21628,-932.4774]
],0.25,0,3,[-2.198252,-36.04916,-947.9129],[8.937042,-10.49464,-932.1246],true);

disk([-1.878665,-33.31143,-947.561],0.25,0,3,
[2.184725,-2.12068]);

disk([8.610339,-13.21628,-932.4774],0.25,0,3,
[0.9871125,1.098032]);

tube([[-10.24153,-28.47944,-935.0469],
[-6.7452,-27.07362,-931.6828],
[-3.248865,-24.71578,-928.0195],
[0.2474695,-18.79945,-923.2375]
],0.25,0,3,[-10.48832,-31.29665,-935.3689],[0.568572,-16.08375,-922.8853],true);

disk([-10.24153,-28.47944,-935.0469],0.25,0,3,
[2.299567,-2.759293]);

disk([0.2474695,-18.79945,-923.2375],0.25,0,3,
[0.962865,1.037048]);

tube([[-1.878665,-33.31143,-947.561],
[-4.666288,-34.24168,-944.1884],
[-7.453911,-33.85515,-940.4018],
[-10.24153,-28.47944,-935.0469]
],0.25,0,3,[-10.56689,-36.79103,-947.7898],[-1.678851,-25.15366,-934.7014],true);

disk([-1.878665,-33.31143,-947.561],0.25,0,3,
[2.424828,0.3220863]);

disk([-10.24153,-28.47944,-935.0469],0.25,0,3,
[0.8467174,2.04918]);

tube([[8.610339,-13.21628,-932.4774],
[5.822716,-16.08718,-929.7149],
[3.035093,-20.01274,-927.2839],
[0.2474695,-18.79945,-923.2375]
],0.25,0,3,[-0.02102946,-19.92786,-933.7971],[8.867056,-12.92718,-922.1662],true);

disk([8.610339,-13.21628,-932.4774],0.25,0,3,
[2.175017,0.8001147]);

disk([0.2474695,-18.79945,-923.2375],0.25,0,3,
[0.6443527,2.731079]);

tube([[35.82508,5.192457,-939.9142],
[39.32142,4.356469,-937.2549],
[42.81775,0.1733552,-935.6478],
[46.31408,-6.218014,-934.7349]
],0.25,0,3,[35.66921,-6.458992,-942.945],[46.62502,5.489616,-931.5568],true);

disk([35.82508,5.192457,-939.9142],0.25,0,3,
[2.207705,2.906895]);

disk([46.31408,-6.218014,-934.7349],0.25,0,3,
[1.446135,-1.070229]);

tube([[27.46221,2.046879,-929.908],
[29.21038,1.628885,-928.5783],
[30.95855,0.06712264,-927.6082],
[32.70671,-2.327343,-926.8999]
],0.25,0,3,[27.30641,-2.592641,-931.1303],[32.99535,2.343977,-925.5566],true);

tube([[32.70671,-2.327343,-926.8999],
[34.45488,-4.721809,-926.1916],
[36.20305,-7.948978,-925.745],
[37.95122,-11.69779,-925.4624]
],0.25,0,3,[32.41808,-11.92432,-929.7793],[38.2716,-2.062085,-922.5444],true);

disk([27.46221,2.046879,-929.908],0.25,0,3,
[2.207705,2.906895]);

disk([37.95122,-11.69779,-925.4624],0.25,0,3,
[1.502587,-1.134449]);

tube([[35.82508,5.192457,-939.9142],
[33.03746,4.143931,-936.5788],
[30.24983,3.095405,-933.2434],
[27.46221,2.046879,-929.908]
],0.25,0,3,[27.26671,1.761612,-940.1379],[36.02058,5.477725,-929.6843],true);

disk([35.82508,5.192457,-939.9142],0.25,0,3,
[2.412696,0.3597665]);

disk([27.46221,2.046879,-929.908],0.25,0,3,
[0.728897,-2.781826]);

tube([[46.31408,-6.218014,-934.7349],
[43.52646,-7.972227,-931.6213],
[40.73884,-9.850516,-928.5468],
[37.95122,-11.69779,-925.4624]
],0.25,0,3,[37.72707,-11.98972,-935.7215],[46.53498,-5.926422,-924.4717],true);

disk([46.31408,-6.218014,-934.7349],0.25,0,3,
[2.328096,0.5616756]);

disk([37.95122,-11.69779,-925.4624],0.25,0,3,
[0.8257939,-2.556363]);

tube([[16.97321,-6.681855,-941.4183],
[18.72138,-3.769035,-939.0416],
[20.46954,-1.377855,-936.8288],
[22.21771,0.1806226,-934.8779]
],0.25,0,3,[16.65276,-8.154827,-941.7704],[22.50641,1.707505,-934.5354],true);

tube([[22.21771,0.1806226,-934.8779],
[23.96588,1.7391,-932.9269],
[25.71404,2.464873,-931.2377],
[27.46221,2.046879,-929.908]
],0.25,0,3,[21.92901,-1.30695,-935.2204],[27.61806,3.629792,-929.6465],true);

disk([16.97321,-6.681855,-941.4183],0.25,0,3,
[2.181273,-2.111336]);

disk([27.46221,2.046879,-929.908],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[8.610339,-13.21628,-932.4774],
[10.35851,-9.798208,-929.9419],
[12.10667,-6.559816,-927.4628],
[13.85484,-4.28044,-925.2852]
],0.25,0,3,[8.283579,-14.68148,-932.8304],[14.16409,-2.779011,-924.9355],true);

tube([[13.85484,-4.28044,-925.2852],
[14.72892,-3.140753,-924.1964],
[15.60301,-2.240819,-923.1829],
[16.47709,-1.678056,-922.2755]
],0.25,0,3,[13.5456,-5.141427,-925.6348],[16.7476,-0.7625148,-921.941],true);

tube([[16.47709,-1.678056,-922.2755],
[17.35118,-1.115294,-921.3681],
[18.22526,-0.8897023,-920.5666],
[19.09934,-1.098699,-919.9018]
],0.25,0,3,[16.20659,-2.556928,-922.6099],[19.25523,-0.1471267,-919.6403],true);

disk([8.610339,-13.21628,-932.4774],0.25,0,3,
[2.15448,-2.043561]);

disk([19.09934,-1.098699,-919.9018],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[16.97321,-6.681855,-941.4183],
[14.18558,-8.529125,-938.334],
[11.39796,-10.34538,-935.24],
[8.610339,-13.21628,-932.4774]
],0.25,0,3,[8.353636,-13.50537,-942.7356],[17.19737,-6.389908,-931.1165],true);

disk([16.97321,-6.681855,-941.4183],0.25,0,3,
[2.315799,0.5852296]);

disk([8.610339,-13.21628,-932.4774],0.25,0,3,
[0.9665752,-2.341478]);

tube([[27.46221,2.046879,-929.908],
[24.67459,0.9983529,-926.5726],
[21.88697,-0.05017324,-923.2372],
[19.09934,-1.098699,-919.9018]
],0.25,0,3,[18.90387,-1.383931,-930.1317],[27.65769,2.332111,-919.6781],true);

disk([27.46221,2.046879,-929.908],0.25,0,3,
[2.412696,0.3597665]);

disk([19.09934,-1.098699,-919.9018],0.25,0,3,
[0.728897,-2.781826]);

tube([[-20.73054,-30.43048,-944.4266],
[-17.2342,-30.33904,-941.4757],
[-13.73787,-29.88525,-938.4109],
[-10.24153,-28.47944,-935.0469]
],0.25,0,3,[-20.92792,-33.25186,-944.7179],[-9.994748,-25.69181,-934.7249],true);

disk([-20.73054,-30.43048,-944.4266],0.25,0,3,
[2.271621,-3.115445]);

disk([-10.24153,-28.47944,-935.0469],0.25,0,3,
[0.8420255,0.3823001]);

tube([[-29.09341,0.1340406,-923.8232],
[-25.59707,-0.7019479,-921.1639],
[-22.10074,-1.537936,-918.5045],
[-18.6044,-2.373925,-915.8452]
],0.25,0,3,[-29.24926,-2.67104,-924.0846],[-18.44855,0.4311552,-915.5838],true);

disk([-29.09341,0.1340406,-923.8232],0.25,0,3,
[2.207705,2.906895]);

disk([-18.6044,-2.373925,-915.8452],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[-20.73054,-30.43048,-944.4266],
[-23.51816,-22.38639,-938.2328],
[-26.30578,-11.12618,-931.028],
[-29.09341,0.1340406,-923.8232]
],0.25,0,3,[-29.43614,-33.77948,-944.7759],[-20.39397,3.457525,-923.4738],true);

disk([-20.73054,-30.43048,-944.4266],0.25,0,3,
[2.199762,-1.237204]);

disk([-29.09341,0.1340406,-923.8232],0.25,0,3,
[1.015009,1.813481]);

tube([[-10.24153,-28.47944,-935.0469],
[-13.02916,-23.10372,-929.6919],
[-15.81678,-12.73882,-922.7686],
[-18.6044,-2.373925,-915.8452]
],0.25,0,3,[-18.94587,-31.85859,-935.3924],[-9.916225,0.9507239,-915.4956],true);

disk([-10.24153,-28.47944,-935.0469],0.25,0,3,
[2.294875,-1.092412]);

disk([-18.6044,-2.373925,-915.8452],0.25,0,3,
[0.9979157,1.833528]);

tube([[46.31408,-6.218014,-934.7349],
[49.81042,-12.60938,-933.822],
[53.30675,-21.20901,-933.6032],
[56.80309,-30.15794,-933.4943]
],0.25,0,3,[46.0032,-30.36892,-941.8116],[57.13195,-5.977077,-926.3932],true);

disk([46.31408,-6.218014,-934.7349],0.25,0,3,
[1.695458,2.071364]);

disk([56.80309,-30.15794,-933.4943],0.25,0,3,
[1.559461,-1.198334]);

tube([[37.95122,-11.69779,-925.4624],
[41.44755,-19.1954,-924.8973],
[44.94388,-28.77958,-924.9881],
[48.44022,-37.73922,-924.8825]
],0.25,0,3,[37.63075,-37.9501,-933.207],[48.76913,-11.47119,-917.126],true);

disk([37.95122,-11.69779,-925.4624],0.25,0,3,
[1.639005,2.007144]);

disk([48.44022,-37.73922,-924.8825],0.25,0,3,
[1.559822,-1.198739]);

tube([[46.31408,-6.218014,-934.7349],
[43.52646,-7.972227,-931.6213],
[40.73884,-9.850516,-928.5468],
[37.95122,-11.69779,-925.4624]
],0.25,0,3,[37.72702,-11.98978,-935.7215],[46.53503,-5.926368,-924.4717],true);

disk([46.31408,-6.218014,-934.7349],0.25,0,3,
[2.328096,0.5616756]);

disk([37.95122,-11.69779,-925.4624],0.25,0,3,
[0.8257939,-2.556363]);

tube([[56.80309,-30.15794,-933.4943],
[54.01547,-32.70582,-930.6303],
[51.22784,-35.31241,-927.7847],
[48.44022,-37.73922,-924.8825]
],0.25,0,3,[48.1968,-38.03095,-935.1706],[57.05027,-29.86673,-923.2113],true);

disk([56.80309,-30.15794,-933.4943],0.25,0,3,
[2.219636,0.7404959]);

disk([48.44022,-37.73922,-924.8825],0.25,0,3,
[0.9051367,-2.42528]);

tube([[56.80309,-30.15794,-933.4943],
[60.29942,-39.10687,-933.3854],
[63.79576,-48.40511,-933.3863],
[67.29209,-57.06863,-933.1877]
],0.25,0,3,[56.47424,-57.28241,-941.5196],[67.61957,-29.94697,-925.1645],true);

disk([56.80309,-30.15794,-933.4943],0.25,0,3,
[1.582132,1.943259]);

disk([67.29209,-57.06863,-933.1877],0.25,0,3,
[1.549538,-1.187217]);

tube([[48.44022,-37.73922,-924.8825],
[51.93655,-46.69886,-924.777],
[55.43289,-55.03397,-924.4751],
[58.92922,-62.27623,-923.8297]
],0.25,0,3,[48.11132,-62.50592,-932.1673],[59.24777,-37.52835,-916.5595],true);

disk([48.44022,-37.73922,-924.8825],0.25,0,3,
[1.58177,1.942853]);

disk([58.92922,-62.27623,-923.8297],0.25,0,3,
[1.490711,-1.121029]);

tube([[56.80309,-30.15794,-933.4943],
[54.01547,-32.70582,-930.6303],
[51.22784,-35.31241,-927.7847],
[48.44022,-37.73922,-924.8825]
],0.25,0,3,[48.19685,-38.03086,-935.1706],[57.05023,-29.86682,-923.2114],true);

disk([56.80309,-30.15794,-933.4943],0.25,0,3,
[2.219636,0.7404959]);

disk([48.44022,-37.73922,-924.8825],0.25,0,3,
[0.9051367,-2.42528]);

tube([[67.29209,-57.06863,-933.1877],
[64.50447,-59.38039,-930.2494],
[61.71685,-61.31998,-927.1941],
[58.92922,-62.27623,-923.8297]
],0.25,0,3,[58.73045,-62.56846,-934.1143],[67.53176,-56.77664,-922.9569],true);

disk([67.29209,-57.06863,-933.1877],0.25,0,3,
[2.252421,0.6923506]);

disk([58.92922,-62.27623,-923.8297],0.25,0,3,
[0.7193698,-2.811139]);

tube([[67.29209,-57.06863,-933.1877],
[70.78843,-65.73215,-932.989],
[74.28476,-73.76097,-932.5909],
[77.7811,-81.02829,-931.9533]
],0.25,0,3,[66.96462,-81.25768,-940.2897],[78.09983,-56.85486,-924.8635],true);

disk([67.29209,-57.06863,-933.1877],0.25,0,3,
[1.592055,1.954376]);

disk([77.7811,-81.02829,-931.9533],0.25,0,3,
[1.491906,-1.12238]);

tube([[58.92922,-62.27623,-923.8297],
[62.42556,-69.51849,-923.1842],
[65.92189,-75.6679,-922.1953],
[69.41823,-80.9679,-920.9393]
],0.25,0,3,[58.61069,-81.22507,-929.2792],[69.71539,-62.04653,-915.5162],true);

disk([58.92922,-62.27623,-923.8297],0.25,0,3,
[1.650881,2.020564]);

disk([69.41823,-80.9679,-920.9393],0.25,0,3,
[1.375503,-0.9876428]);

tube([[67.29209,-57.06863,-933.1877],
[64.50447,-59.38039,-930.2494],
[61.71685,-61.31998,-927.1941],
[58.92922,-62.27623,-923.8297]
],0.25,0,3,[58.73038,-62.56874,-934.1143],[67.53177,-56.77683,-922.9569],true);

disk([67.29209,-57.06863,-933.1877],0.25,0,3,
[2.252421,0.6923506]);

disk([58.92922,-62.27623,-923.8297],0.25,0,3,
[0.7193698,-2.811139]);

tube([[77.7811,-81.02829,-931.9533],
[74.99347,-82.05587,-928.6113],
[72.20585,-82.26053,-925.0106],
[69.41823,-80.9679,-920.9393]
],0.25,0,3,[69.14806,-84.48697,-932.1777],[77.97713,-77.5665,-920.6355],true);

disk([77.7811,-81.02829,-931.9533],0.25,0,3,
[2.414896,0.3531676]);

disk([69.41823,-80.9679,-920.9393],0.25,0,3,
[0.6465133,2.707402]);

tube([[-50.07142,5.149972,-939.7791],
[-46.57508,4.313983,-937.1198],
[-43.07875,3.477995,-934.4604],
[-39.58241,2.642006,-931.8011]
],0.25,0,3,[-50.22729,2.344839,-940.0406],[-39.42653,5.447139,-931.5396],true);

disk([-50.07142,5.149972,-939.7791],0.25,0,3,
[2.207705,2.906895]);

disk([-39.58241,2.642006,-931.8011],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[-58.43428,38.56826,-918.2786],
[-54.93795,37.43556,-915.7125],
[-51.44162,36.34985,-913.1317],
[-47.94528,35.10645,-910.6005]
],0.25,0,3,[-58.60367,34.80594,-918.8498],[-47.77089,38.86808,-910.0252],true);

disk([-58.43428,38.56826,-918.2786],0.25,0,3,
[2.180312,2.828296]);

disk([-47.94528,35.10645,-910.6005],0.25,0,3,
[0.9721714,-0.34168]);

tube([[-50.07142,5.149972,-939.7791],
[-52.85904,16.75587,-932.4656],
[-55.64666,28.36177,-925.1521],
[-58.43428,38.56826,-918.2786]
],0.25,0,3,[-58.7755,1.826651,-940.1284],[-49.72825,41.90065,-917.9289],true);

disk([-50.07142,5.149972,-939.7791],0.25,0,3,
[2.12052,-1.335072]);

disk([-58.43428,38.56826,-918.2786],0.25,0,3,
[0.9946637,1.837416]);

tube([[-39.58241,2.642006,-931.8011],
[-42.37003,14.15142,-924.518],
[-45.15766,25.66083,-917.2348],
[-47.94528,35.10645,-910.6005]
],0.25,0,3,[-48.28514,-0.6813298,-932.1505],[-39.23936,38.44409,-910.2508],true);

disk([-39.58241,2.642006,-931.8011],0.25,0,3,
[2.122185,-1.333169]);

disk([-47.94528,35.10645,-910.6005],0.25,0,3,
[0.977974,1.857773]);

tube([[77.7811,-81.02829,-931.9533],
[81.27743,-88.29562,-931.3158],
[84.77377,-94.80146,-930.4388],
[88.2701,-100.419,-929.2827]
],0.25,0,3,[77.46229,-100.6713,-937.621],[88.57187,-80.79886,-923.6365],true);

disk([77.7811,-81.02829,-931.9533],0.25,0,3,
[1.649687,2.019213]);

disk([88.2701,-100.419,-929.2827],0.25,0,3,
[1.397809,-1.014074]);

tube([[69.41823,-80.9679,-920.9393],
[72.91456,-86.26791,-919.6833],
[76.4109,-90.71851,-918.1602],
[79.90723,-94.56315,-916.4467]
],0.25,0,3,[69.121,-94.84365,-924.78],[80.17579,-80.71068,-912.6373],true);

disk([69.41823,-80.9679,-920.9393],0.25,0,3,
[1.76609,2.15395]);

disk([79.90723,-94.56315,-916.4467],0.25,0,3,
[1.252291,-0.8328089]);

tube([[77.7811,-81.02829,-931.9533],
[74.99347,-82.05587,-928.6113],
[72.20585,-82.26053,-925.0106],
[69.41823,-80.9679,-920.9393]
],0.25,0,3,[69.14776,-84.48731,-932.1779],[77.97733,-77.56622,-920.6351],true);

disk([77.7811,-81.02829,-931.9533],0.25,0,3,
[2.414896,0.3531676]);

disk([69.41823,-80.9679,-920.9393],0.25,0,3,
[0.6465133,2.707402]);

tube([[88.2701,-100.419,-929.2827],
[85.48248,-99.73408,-925.4023],
[82.69485,-97.95406,-921.1777],
[79.90723,-94.56315,-916.4467]
],0.25,0,3,[79.59938,-103.8746,-929.571],[88.52424,-91.18352,-916.1117],true);

disk([88.2701,-100.419,-929.2827],0.25,0,3,
[2.504675,-0.2409348]);

disk([79.90723,-94.56315,-916.4467],0.25,0,3,
[0.7479911,2.25886]);

tube([[27.46221,2.046879,-929.908],
[29.21038,1.628885,-928.5783],
[30.95855,0.06712264,-927.6082],
[32.70671,-2.327343,-926.8999]
],0.25,0,3,[27.30639,-2.592646,-931.1303],[32.99537,2.343981,-925.5566],true);

tube([[32.70671,-2.327343,-926.8999],
[34.45488,-4.721809,-926.1916],
[36.20305,-7.948978,-925.745],
[37.95122,-11.69779,-925.4624]
],0.25,0,3,[32.41806,-11.92433,-929.7793],[38.27162,-2.062073,-922.5443],true);

disk([27.46221,2.046879,-929.908],0.25,0,3,
[2.207705,2.906895]);

disk([37.95122,-11.69779,-925.4624],0.25,0,3,
[1.502587,-1.134449]);

tube([[19.09934,-1.098699,-919.9018],
[19.97343,-1.307697,-919.2369],
[20.84751,-1.951282,-918.7087],
[21.72159,-2.932039,-918.2865]
],0.25,0,3,[18.94349,-3.211314,-920.5282],[21.99207,-0.8015369,-917.5586],true);

tube([[21.72159,-2.932039,-918.2865],
[22.59568,-3.912796,-917.8643],
[23.46976,-5.230724,-917.5481],
[24.34384,-6.788406,-917.3072]
],0.25,0,3,[21.45114,-7.031619,-919.6219],[24.65303,-2.652803,-915.9282],true);

tube([[24.34384,-6.788406,-917.3072],
[26.09201,-9.90377,-916.8255],
[27.84018,-13.97815,-916.6453],
[29.58835,-18.23221,-916.5215]
],0.25,0,3,[24.03466,-18.44758,-920.8501],[29.91505,-6.545193,-912.9553],true);

disk([19.09934,-1.098699,-919.9018],0.25,0,3,
[2.207705,2.906895]);

disk([29.58835,-18.23221,-916.5215],0.25,0,3,
[1.543897,-1.180894]);

tube([[27.46221,2.046879,-929.908],
[24.67459,0.9983529,-926.5726],
[21.88697,-0.05017324,-923.2372],
[19.09934,-1.098699,-919.9018]
],0.25,0,3,[18.90384,-1.38398,-930.1317],[27.65771,2.33216,-919.6781],true);

disk([27.46221,2.046879,-929.908],0.25,0,3,
[2.412696,0.3597665]);

disk([19.09934,-1.098699,-919.9018],0.25,0,3,
[0.728897,-2.781826]);

tube([[37.95122,-11.69779,-925.4624],
[35.16359,-13.54506,-922.3781],
[32.37597,-15.36131,-919.2841],
[29.58835,-18.23221,-916.5215]
],0.25,0,3,[29.3316,-18.52135,-926.7798],[38.17542,-11.40579,-915.1605],true);

disk([37.95122,-11.69779,-925.4624],0.25,0,3,
[2.315799,0.5852296]);

disk([29.58835,-18.23221,-916.5215],0.25,0,3,
[0.9665752,-2.341478]);

tube([[8.610339,-13.21628,-932.4774],
[10.35851,-9.798208,-929.9419],
[12.10667,-6.559816,-927.4628],
[13.85484,-4.28044,-925.2852]
],0.25,0,3,[8.283637,-14.68143,-932.8303],[14.16403,-2.779056,-924.9356],true);

tube([[13.85484,-4.28044,-925.2852],
[14.72892,-3.140753,-924.1964],
[15.60301,-2.240819,-923.1829],
[16.47709,-1.678056,-922.2755]
],0.25,0,3,[13.54565,-5.141386,-925.6348],[16.74755,-0.7625665,-921.9411],true);

tube([[16.47709,-1.678056,-922.2755],
[17.35118,-1.115294,-921.3681],
[18.22526,-0.8897023,-920.5666],
[19.09934,-1.098699,-919.9018]
],0.25,0,3,[16.20663,-2.556931,-922.6099],[19.2552,-0.1471259,-919.6403],true);

disk([8.610339,-13.21628,-932.4774],0.25,0,3,
[2.15448,-2.043561]);

disk([19.09934,-1.098699,-919.9018],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[0.2474695,-18.79945,-923.2375],
[1.995637,-15.84128,-920.8465],
[3.743804,-11.99349,-918.1759],
[5.491971,-8.989742,-915.7705]
],0.25,0,3,[-0.07360234,-20.27906,-923.5897],[5.81367,-7.511504,-915.4183],true);

tube([[5.491971,-8.989742,-915.7705],
[6.366055,-7.487868,-914.5679],
[7.240139,-6.197003,-913.4315],
[8.114222,-5.333856,-912.4297]
],0.25,0,3,[5.170272,-9.834625,-916.1228],[8.408628,-4.440421,-912.0849],true);

tube([[8.114222,-5.333856,-912.4297],
[8.551264,-4.902283,-911.9287],
[8.988306,-4.577639,-911.4614],
[9.425348,-4.387013,-911.0362]
],0.25,0,3,[7.819817,-5.896808,-912.7744],[9.675828,-3.773079,-910.7122],true);

tube([[9.425348,-4.387013,-911.0362],
[9.86239,-4.196387,-910.611],
[10.29943,-4.139779,-910.228],
[10.73647,-4.244278,-909.8956]
],0.25,0,3,[9.174845,-4.967814,-911.3603],[10.89231,-3.611009,-909.6341],true);

disk([0.2474695,-18.79945,-923.2375],0.25,0,3,
[2.178728,-2.104545]);

disk([10.73647,-4.244278,-909.8956],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[8.610339,-13.21628,-932.4774],
[5.822716,-16.08718,-929.7149],
[3.035093,-20.01274,-927.2839],
[0.2474695,-18.79945,-923.2375]
],0.25,0,3,[-0.0210133,-19.92784,-933.7971],[8.867041,-12.92719,-922.1662],true);

disk([8.610339,-13.21628,-932.4774],0.25,0,3,
[2.175017,0.8001147]);

disk([0.2474695,-18.79945,-923.2375],0.25,0,3,
[0.6443527,2.731079]);

tube([[19.09934,-1.098699,-919.9018],
[16.31172,-2.147226,-916.5664],
[13.5241,-3.195752,-913.231],
[10.73647,-4.244278,-909.8956]
],0.25,0,3,[10.541,-4.52951,-920.1254],[19.29482,-0.8134674,-909.6719],true);

disk([19.09934,-1.098699,-919.9018],0.25,0,3,
[2.412696,0.3597665]);

disk([10.73647,-4.244278,-909.8956],0.25,0,3,
[0.728897,-2.781826]);

tube([[37.95122,-11.69779,-925.4624],
[41.44755,-19.1954,-924.8973],
[44.94388,-28.77958,-924.9881],
[48.44022,-37.73922,-924.8825]
],0.25,0,3,[37.63075,-37.9501,-933.207],[48.76914,-11.47119,-917.126],true);

disk([37.95122,-11.69779,-925.4624],0.25,0,3,
[1.639005,2.007144]);

disk([48.44022,-37.73922,-924.8825],0.25,0,3,
[1.559822,-1.198739]);

tube([[29.58835,-18.23221,-916.5215],
[33.08468,-26.74033,-916.2741],
[36.58102,-35.96716,-916.2525],
[40.07735,-43.34329,-915.6492]
],0.25,0,3,[29.26165,-43.57135,-923.9845],[40.39693,-18.01685,-908.1962],true);

disk([29.58835,-18.23221,-916.5215],0.25,0,3,
[1.597695,1.960699]);

disk([40.07735,-43.34329,-915.6492],0.25,0,3,
[1.497017,-1.128159]);

tube([[37.95122,-11.69779,-925.4624],
[35.16359,-13.54506,-922.3781],
[32.37597,-15.36131,-919.2841],
[29.58835,-18.23221,-916.5215]
],0.25,0,3,[29.33169,-18.52125,-926.7797],[38.17534,-11.40589,-915.1606],true);

disk([37.95122,-11.69779,-925.4624],0.25,0,3,
[2.315799,0.5852296]);

disk([29.58835,-18.23221,-916.5215],0.25,0,3,
[0.9665752,-2.341478]);

tube([[48.44022,-37.73922,-924.8825],
[45.6526,-40.16602,-921.9804],
[42.86497,-42.41304,-919.0217],
[40.07735,-43.34329,-915.6492]
],0.25,0,3,[39.87758,-43.6377,-925.9387],[48.68358,-37.44757,-914.6511],true);

disk([48.44022,-37.73922,-924.8825],0.25,0,3,
[2.236456,0.7163122]);

disk([40.07735,-43.34329,-915.6492],0.25,0,3,
[0.7167649,-2.819506]);

tube([[-10.24153,-28.47944,-935.0469],
[-6.7452,-27.07362,-931.6828],
[-3.248865,-24.71578,-928.0195],
[0.2474695,-18.79945,-923.2375]
],0.25,0,3,[-10.48829,-31.2966,-935.3689],[0.5685325,-16.08377,-922.8853],true);

disk([-10.24153,-28.47944,-935.0469],0.25,0,3,
[2.299567,-2.759293]);

disk([0.2474695,-18.79945,-923.2375],0.25,0,3,
[0.962865,1.037048]);

tube([[-18.6044,-2.373925,-915.8452],
[-15.10807,-3.209914,-913.1859],
[-11.61173,-4.045902,-910.5266],
[-8.1154,-4.881891,-907.8673]
],0.25,0,3,[-18.7603,-5.179081,-916.1067],[-7.959508,-2.076734,-907.6058],true);

disk([-18.6044,-2.373925,-915.8452],0.25,0,3,
[2.207705,2.906895]);

disk([-8.1154,-4.881891,-907.8673],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[-10.24153,-28.47944,-935.0469],
[-13.02916,-23.10372,-929.6919],
[-15.81678,-12.73882,-922.7686],
[-18.6044,-2.373925,-915.8452]
],0.25,0,3,[-18.94587,-31.85859,-935.3924],[-9.916225,0.9507239,-915.4956],true);

disk([-10.24153,-28.47944,-935.0469],0.25,0,3,
[2.294875,-1.092412]);

disk([-18.6044,-2.373925,-915.8452],0.25,0,3,
[0.9979157,1.833528]);

tube([[0.2474695,-18.79945,-923.2375],
[-1.146342,-18.1928,-921.2143],
[-2.540154,-16.30145,-918.7872],
[-3.933965,-13.76774,-916.1582]
],0.25,0,3,[-4.257269,-20.67776,-923.5398],[0.5159522,-11.98431,-915.8137],true);

tube([[-3.933965,-13.76774,-916.1582],
[-5.327777,-11.23403,-913.5292],
[-6.721588,-8.057958,-910.6982],
[-8.1154,-4.881891,-907.8673]
],0.25,0,3,[-8.445901,-15.57449,-916.5027],[-3.610661,-3.095181,-907.5196],true);

disk([0.2474695,-18.79945,-923.2375],0.25,0,3,
[2.49724,-0.4105133]);

disk([-8.1154,-4.881891,-907.8673],0.25,0,3,
[0.8862546,1.984338]);

tube([[48.44022,-37.73922,-924.8825],
[51.93655,-46.69886,-924.777],
[55.43289,-55.03397,-924.4751],
[58.92922,-62.27623,-923.8297]
],0.25,0,3,[48.11136,-62.50585,-932.1672],[59.24772,-37.52841,-916.5595],true);

disk([48.44022,-37.73922,-924.8825],0.25,0,3,
[1.58177,1.942853]);

disk([58.92922,-62.27623,-923.8297],0.25,0,3,
[1.490711,-1.121029]);

tube([[40.07735,-43.34329,-915.6492],
[43.57368,-50.71942,-915.0458],
[47.07002,-56.24484,-913.8607],
[50.56635,-60.77784,-912.3636]
],0.25,0,3,[39.75783,-61.0472,-920.7091],[50.85019,-43.11531,-907.3464],true);

disk([40.07735,-43.34329,-915.6492],0.25,0,3,
[1.644576,2.013434]);

disk([50.56635,-60.77784,-912.3636],0.25,0,3,
[1.315007,-0.913798]);

tube([[48.44022,-37.73922,-924.8825],
[45.6526,-40.16602,-921.9804],
[42.86497,-42.41304,-919.0217],
[40.07735,-43.34329,-915.6492]
],0.25,0,3,[39.87756,-43.63765,-925.9387],[48.68361,-37.44751,-914.6511],true);

disk([48.44022,-37.73922,-924.8825],0.25,0,3,
[2.236456,0.7163122]);

disk([40.07735,-43.34329,-915.6492],0.25,0,3,
[0.7167649,-2.819506]);

tube([[58.92922,-62.27623,-923.8297],
[56.1416,-63.23247,-920.4653],
[53.35398,-63.20537,-916.7917],
[50.56635,-60.77784,-912.3636]
],0.25,0,3,[50.27243,-65.74401,-924.0573],[59.12807,-57.39938,-912.039],true);

disk([58.92922,-62.27623,-923.8297],0.25,0,3,
[2.422223,0.330454]);

disk([50.56635,-60.77784,-912.3636],0.25,0,3,
[0.6955807,2.425133]);

tube([[-39.58241,2.642006,-931.8011],
[-36.08608,1.806018,-929.1418],
[-32.58974,0.9700291,-926.4825],
[-29.09341,0.1340406,-923.8232]
],0.25,0,3,[-39.73826,-0.1630776,-932.0626],[-28.93755,2.939125,-923.5617],true);

disk([-39.58241,2.642006,-931.8011],0.25,0,3,
[2.207705,2.906895]);

disk([-29.09341,0.1340406,-923.8232],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[-47.94528,35.10645,-910.6005],
[-44.44895,33.86306,-908.0692],
[-40.95261,32.46198,-905.5875],
[-37.45628,30.69856,-903.2198]
],0.25,0,3,[-48.11964,30.39695,-911.4715],[-37.25889,35.40692,-902.3298],true);

disk([-47.94528,35.10645,-910.6005],0.25,0,3,
[2.169421,2.799913]);

disk([-37.45628,30.69856,-903.2198],0.25,0,3,
[1.026957,-0.4671314]);

tube([[-39.58241,2.642006,-931.8011],
[-42.37003,14.15142,-924.518],
[-45.15766,25.66083,-917.2348],
[-47.94528,35.10645,-910.6005]
],0.25,0,3,[-48.28518,-0.6813515,-932.1505],[-39.23932,38.44411,-910.2507],true);

disk([-39.58241,2.642006,-931.8011],0.25,0,3,
[2.122185,-1.333169]);

disk([-47.94528,35.10645,-910.6005],0.25,0,3,
[0.977974,1.857773]);

tube([[-29.09341,0.1340406,-923.8232],
[-31.88103,11.39426,-916.6184],
[-34.66865,22.65447,-909.4136],
[-37.45628,30.69856,-903.2198]
],0.25,0,3,[-37.7929,-3.189471,-924.1727],[-28.75062,34.04759,-902.8704],true);

disk([-29.09341,0.1340406,-923.8232],0.25,0,3,
[2.126584,-1.328112]);

disk([-37.45628,30.69856,-903.2198],0.25,0,3,
[0.9418303,1.904388]);

tube([[58.92922,-62.27623,-923.8297],
[62.42556,-69.51849,-923.1842],
[65.92189,-75.6679,-922.1953],
[69.41823,-80.9679,-920.9393]
],0.25,0,3,[58.61062,-81.22512,-929.2793],[69.71545,-62.04648,-915.5161],true);

disk([58.92922,-62.27623,-923.8297],0.25,0,3,
[1.650881,2.020564]);

disk([69.41823,-80.9679,-920.9393],0.25,0,3,
[1.375503,-0.9876428]);

tube([[50.56635,-60.77784,-912.3636],
[54.06269,-65.31085,-910.8664],
[57.55902,-68.85143,-909.0574],
[61.05536,-71.79949,-907.062]
],0.25,0,3,[50.28243,-72.09263,-915.391],[61.29799,-60.50834,-904.0765],true);

disk([50.56635,-60.77784,-912.3636],0.25,0,3,
[1.826585,2.227795]);

disk([61.05536,-71.79949,-907.062],0.25,0,3,
[1.159391,-0.7005246]);

tube([[58.92922,-62.27623,-923.8297],
[56.1416,-63.23247,-920.4653],
[53.35398,-63.20537,-916.7917],
[50.56635,-60.77784,-912.3636]
],0.25,0,3,[50.27244,-65.74388,-924.0573],[59.1281,-57.39946,-912.039],true);

disk([58.92922,-62.27623,-923.8297],0.25,0,3,
[2.422223,0.330454]);

disk([50.56635,-60.77784,-912.3636],0.25,0,3,
[0.6955807,2.425133]);

tube([[69.41823,-80.9679,-920.9393],
[66.6306,-79.67528,-916.8679],
[63.84298,-76.88536,-912.3258],
[61.05536,-71.79949,-907.062]
],0.25,0,3,[60.73218,-84.41719,-921.2432],[69.68848,-68.44297,-906.7177],true);

disk([69.41823,-80.9679,-920.9393],0.25,0,3,
[2.495079,-0.4341902]);

disk([61.05536,-71.79949,-907.062],0.25,0,3,
[0.8337991,2.072188]);

tube([[19.09934,-1.098699,-919.9018],
[19.97343,-1.307697,-919.2369],
[20.84751,-1.951282,-918.7087],
[21.72159,-2.932039,-918.2865]
],0.25,0,3,[18.94349,-3.211276,-920.5282],[21.99204,-0.801577,-917.5587],true);

tube([[21.72159,-2.932039,-918.2865],
[22.59568,-3.912796,-917.8643],
[23.46976,-5.230724,-917.5481],
[24.34384,-6.788406,-917.3072]
],0.25,0,3,[21.45114,-7.031615,-919.6219],[24.65303,-2.652809,-915.9282],true);

tube([[24.34384,-6.788406,-917.3072],
[26.09201,-9.90377,-916.8255],
[27.84018,-13.97815,-916.6453],
[29.58835,-18.23221,-916.5215]
],0.25,0,3,[24.03466,-18.44758,-920.8501],[29.91504,-6.545197,-912.9553],true);

disk([19.09934,-1.098699,-919.9018],0.25,0,3,
[2.207705,2.906895]);

disk([29.58835,-18.23221,-916.5215],0.25,0,3,
[1.543897,-1.180894]);

tube([[10.73647,-4.244278,-909.8956],
[11.17352,-4.348776,-909.5631],
[11.61056,-4.614381,-909.2814],
[12.0476,-5.014004,-909.0417]
],0.25,0,3,[10.58063,-5.303986,-910.2911],[12.29813,-3.9471,-908.5648],true);

tube([[12.0476,-5.014004,-909.0417],
[12.48464,-5.413628,-908.8021],
[12.92168,-5.947269,-908.6046],
[13.35872,-6.587839,-908.4407]
],0.25,0,3,[11.79707,-6.847841,-909.7493],[13.6532,-4.724023,-907.687],true);

tube([[13.35872,-6.587839,-908.4407],
[14.23281,-7.86898,-908.1129],
[15.10689,-9.577839,-907.9196],
[15.98098,-11.49771,-907.7926]
],0.25,0,3,[13.06429,-11.72215,-910.1186],[16.30271,-6.327896,-906.0807],true);

tube([[15.98098,-11.49771,-907.7926],
[17.72914,-15.33745,-907.5386],
[19.47731,-20.02122,-907.5499],
[21.22548,-23.81538,-907.2816]
],0.25,0,3,[15.65924,-24.04089,-911.6233],[21.54658,-11.27327,-903.4518],true);

disk([10.73647,-4.244278,-909.8956],0.25,0,3,
[2.207705,2.906895]);

disk([21.22548,-23.81538,-907.2816],0.25,0,3,
[1.506655,-1.139037]);

tube([[19.09934,-1.098699,-919.9018],
[16.31172,-2.147226,-916.5664],
[13.5241,-3.195752,-913.231],
[10.73647,-4.244278,-909.8956]
],0.25,0,3,[10.54098,-4.52954,-920.1255],[19.29484,-0.813435,-909.6719],true);

disk([19.09934,-1.098699,-919.9018],0.25,0,3,
[2.412696,0.3597665]);

disk([10.73647,-4.244278,-909.8956],0.25,0,3,
[0.728897,-2.781826]);

tube([[29.58835,-18.23221,-916.5215],
[26.80072,-21.10311,-913.759],
[24.0131,-25.02867,-911.328],
[21.22548,-23.81538,-907.2816]
],0.25,0,3,[20.95702,-24.9438,-917.8412],[29.84503,-17.94314,-906.2104],true);

disk([29.58835,-18.23221,-916.5215],0.25,0,3,
[2.175017,0.8001147]);

disk([21.22548,-23.81538,-907.2816],0.25,0,3,
[0.6443527,2.731079]);

tube([[69.41823,-80.9679,-920.9393],
[72.91456,-86.26791,-919.6833],
[76.4109,-90.71851,-918.1602],
[79.90723,-94.56315,-916.4467]
],0.25,0,3,[69.12113,-94.84344,-924.7798],[80.17569,-80.71087,-912.6374],true);

disk([69.41823,-80.9679,-920.9393],0.25,0,3,
[1.76609,2.15395]);

disk([79.90723,-94.56315,-916.4467],0.25,0,3,
[1.252291,-0.8328089]);

tube([[61.05536,-71.79949,-907.062],
[64.55169,-74.74755,-905.0666],
[68.04803,-77.10308,-902.885],
[71.54436,-79.26598,-900.6428]
],0.25,0,3,[60.81282,-79.566,-908.9447],[71.75826,-71.5066,-898.7863],true);

disk([61.05536,-71.79949,-907.062],0.25,0,3,
[1.982202,2.441068]);

disk([71.54436,-79.26598,-900.6428],0.25,0,3,
[1.071509,-0.5539965]);

tube([[69.41823,-80.9679,-920.9393],
[66.6306,-79.67528,-916.8679],
[63.84298,-76.88536,-912.3258],
[61.05536,-71.79949,-907.062]
],0.25,0,3,[60.73184,-84.41751,-921.2435],[69.68876,-68.44275,-906.7173],true);

disk([69.41823,-80.9679,-920.9393],0.25,0,3,
[2.495079,-0.4341902]);

disk([61.05536,-71.79949,-907.062],0.25,0,3,
[0.8337991,2.072188]);

tube([[79.90723,-94.56315,-916.4467],
[77.11961,-91.17223,-911.7157],
[74.33199,-86.17042,-906.4783],
[71.54436,-79.26598,-900.6428]
],0.25,0,3,[71.21146,-97.97244,-916.7819],[80.21517,-75.91861,-900.2943],true);

disk([79.90723,-94.56315,-916.4467],0.25,0,3,
[2.393602,-0.8827329]);

disk([71.54436,-79.26598,-900.6428],0.25,0,3,
[0.906063,1.954526]);

tube([[29.58835,-18.23221,-916.5215],
[33.08468,-26.74033,-916.2741],
[36.58102,-35.96716,-916.2525],
[40.07735,-43.34329,-915.6492]
],0.25,0,3,[29.26167,-43.57134,-923.9845],[40.39691,-18.01686,-908.1962],true);

disk([29.58835,-18.23221,-916.5215],0.25,0,3,
[1.597695,1.960699]);

disk([40.07735,-43.34329,-915.6492],0.25,0,3,
[1.497017,-1.128159]);

tube([[21.22548,-23.81538,-907.2816],
[24.72181,-31.40369,-906.745],
[28.21815,-35.43351,-905.0897],
[31.71448,-38.5113,-903.1351]
],0.25,0,3,[20.90439,-38.80276,-911.4916],[31.96125,-23.58988,-899.008],true);

disk([21.22548,-23.81538,-907.2816],0.25,0,3,
[1.634938,2.002556]);

disk([31.71448,-38.5113,-903.1351],0.25,0,3,
[1.173495,-0.7218187]);

tube([[29.58835,-18.23221,-916.5215],
[26.80072,-21.10311,-913.759],
[24.0131,-25.02867,-911.328],
[21.22548,-23.81538,-907.2816]
],0.25,0,3,[20.95699,-24.94381,-917.8412],[29.84506,-17.94309,-906.2103],true);

disk([29.58835,-18.23221,-916.5215],0.25,0,3,
[2.175017,0.8001147]);

disk([21.22548,-23.81538,-907.2816],0.25,0,3,
[0.6443527,2.731079]);

tube([[40.07735,-43.34329,-915.6492],
[37.28973,-44.27354,-912.2766],
[34.5021,-43.88701,-908.49],
[31.71448,-38.5113,-903.1351]
],0.25,0,3,[31.38912,-46.8229,-915.878],[40.27717,-35.18548,-902.7896],true);

disk([40.07735,-43.34329,-915.6492],0.25,0,3,
[2.424828,0.3220863]);

disk([31.71448,-38.5113,-903.1351],0.25,0,3,
[0.8467174,2.04918]);

tube([[0.2474695,-18.79945,-923.2375],
[1.995637,-15.84128,-920.8465],
[3.743804,-11.99349,-918.1759],
[5.491971,-8.989742,-915.7705]
],0.25,0,3,[-0.07371612,-20.27915,-923.5898],[5.813784,-7.511413,-915.4181],true);

tube([[5.491971,-8.989742,-915.7705],
[6.366055,-7.487868,-914.5679],
[7.240139,-6.197003,-913.4315],
[8.114222,-5.333856,-912.4297]
],0.25,0,3,[5.170158,-9.834702,-916.1229],[8.408732,-4.440327,-912.0848],true);

tube([[8.114222,-5.333856,-912.4297],
[8.551264,-4.902283,-911.9287],
[8.988306,-4.577639,-911.4614],
[9.425348,-4.387013,-911.0362]
],0.25,0,3,[7.819713,-5.896855,-912.7745],[9.675917,-3.773023,-910.7121],true);

tube([[9.425348,-4.387013,-911.0362],
[9.86239,-4.196387,-910.611],
[10.29943,-4.139779,-910.228],
[10.73647,-4.244278,-909.8956]
],0.25,0,3,[9.174779,-4.967858,-911.3604],[10.89236,-3.610959,-909.634],true);

disk([0.2474695,-18.79945,-923.2375],0.25,0,3,
[2.178728,-2.104545]);

disk([10.73647,-4.244278,-909.8956],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[-8.1154,-4.881891,-907.8673],
[-4.619065,-5.717879,-905.208],
[-1.122731,-6.553868,-902.5486],
[2.373604,-7.389856,-899.8893]
],0.25,0,3,[-8.271288,-7.687041,-908.1288],[2.529492,-4.584706,-899.6278],true);

disk([-8.1154,-4.881891,-907.8673],0.25,0,3,
[2.207705,2.906895]);

disk([2.373604,-7.389856,-899.8893],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[0.2474695,-18.79945,-923.2375],
[-1.146342,-18.1928,-921.2143],
[-2.540154,-16.30145,-918.7872],
[-3.933965,-13.76774,-916.1582]
],0.25,0,3,[-4.257269,-20.67776,-923.5398],[0.5159522,-11.98431,-915.8137],true);

tube([[-3.933965,-13.76774,-916.1582],
[-5.327777,-11.23403,-913.5292],
[-6.721588,-8.057958,-910.6982],
[-8.1154,-4.881891,-907.8673]
],0.25,0,3,[-8.445901,-15.57449,-916.5027],[-3.610661,-3.095181,-907.5196],true);

disk([0.2474695,-18.79945,-923.2375],0.25,0,3,
[2.49724,-0.4105133]);

disk([-8.1154,-4.881891,-907.8673],0.25,0,3,
[0.8862546,1.984338]);

tube([[10.73647,-4.244278,-909.8956],
[7.94885,-5.292804,-906.5601],
[5.161227,-6.34133,-903.2247],
[2.373604,-7.389856,-899.8893]
],0.25,0,3,[2.17813,-7.675088,-910.1192],[10.93195,-3.959046,-899.6657],true);

disk([10.73647,-4.244278,-909.8956],0.25,0,3,
[2.412696,0.3597665]);

disk([2.373604,-7.389856,-899.8893],0.25,0,3,
[0.728897,-2.781826]);

tube([[-29.09341,0.1340406,-923.8232],
[-25.59707,-0.7019479,-921.1639],
[-22.10074,-1.537936,-918.5045],
[-18.6044,-2.373925,-915.8452]
],0.25,0,3,[-29.24926,-2.67104,-924.0846],[-18.44855,0.4311552,-915.5838],true);

disk([-29.09341,0.1340406,-923.8232],0.25,0,3,
[2.207705,2.906895]);

disk([-18.6044,-2.373925,-915.8452],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[-37.45628,30.69856,-903.2198],
[-33.95994,28.93514,-900.852],
[-30.46361,26.80938,-898.5981],
[-26.96727,23.73159,-896.6436]
],0.25,0,3,[-37.65366,23.44011,-904.9059],[-26.72049,31.00016,-894.9129],true);

disk([-37.45628,30.69856,-903.2198],0.25,0,3,
[2.114635,2.674461]);

disk([-26.96727,23.73159,-896.6436],0.25,0,3,
[1.173495,-0.7218187]);

tube([[-29.09341,0.1340406,-923.8232],
[-31.88103,11.39426,-916.6184],
[-34.66865,22.65447,-909.4136],
[-37.45628,30.69856,-903.2198]
],0.25,0,3,[-37.79275,-3.18938,-924.1725],[-28.75077,34.04749,-902.8705],true);

disk([-29.09341,0.1340406,-923.8232],0.25,0,3,
[2.126584,-1.328112]);

disk([-37.45628,30.69856,-903.2198],0.25,0,3,
[0.9418303,1.904388]);

tube([[-18.6044,-2.373925,-915.8452],
[-21.39203,7.990975,-908.9219],
[-24.17965,18.35587,-901.9985],
[-26.96727,23.73159,-896.6436]
],0.25,0,3,[-27.29261,-5.698532,-916.1948],[-18.26291,27.11067,-896.2981],true);

disk([-18.6044,-2.373925,-915.8452],0.25,0,3,
[2.143677,-1.308065]);

disk([-26.96727,23.73159,-896.6436],0.25,0,3,
[0.8467174,2.04918]);

tube([[40.07735,-43.34329,-915.6492],
[43.57368,-50.71942,-915.0458],
[47.07002,-56.24484,-913.8607],
[50.56635,-60.77784,-912.3636]
],0.25,0,3,[39.7578,-61.04725,-920.7091],[50.8502,-43.11527,-907.3464],true);

disk([40.07735,-43.34329,-915.6492],0.25,0,3,
[1.644576,2.013434]);

disk([50.56635,-60.77784,-912.3636],0.25,0,3,
[1.315007,-0.913798]);

tube([[31.71448,-38.5113,-903.1351],
[35.21082,-41.58909,-901.1805],
[38.70715,-43.71485,-898.9266],
[42.20349,-45.47827,-896.5589]
],0.25,0,3,[31.46769,-45.77988,-904.8657],[42.40087,-38.21982,-894.8727],true);

disk([31.71448,-38.5113,-903.1351],0.25,0,3,
[1.968098,2.419774]);

disk([42.20349,-45.47827,-896.5589],0.25,0,3,
[1.026957,-0.4671314]);

tube([[40.07735,-43.34329,-915.6492],
[37.28973,-44.27354,-912.2766],
[34.5021,-43.88701,-908.49],
[31.71448,-38.5113,-903.1351]
],0.25,0,3,[31.38912,-46.8229,-915.878],[40.27718,-35.1854,-902.7895],true);

disk([40.07735,-43.34329,-915.6492],0.25,0,3,
[2.424828,0.3220863]);

disk([31.71448,-38.5113,-903.1351],0.25,0,3,
[0.8467174,2.04918]);

tube([[50.56635,-60.77784,-912.3636],
[47.77873,-58.35032,-907.9354],
[44.99111,-53.52236,-902.7527],
[42.20349,-45.47827,-896.5589]
],0.25,0,3,[41.8669,-64.20813,-912.6882],[50.86029,-42.14762,-896.2095],true);

disk([50.56635,-60.77784,-912.3636],0.25,0,3,
[2.446012,-0.7164596]);

disk([42.20349,-45.47827,-896.5589],0.25,0,3,
[0.9418303,1.904388]);

tube([[-58.43428,38.56826,-918.2786],
[-54.93795,37.43556,-915.7125],
[-51.44162,36.34985,-913.1317],
[-47.94528,35.10645,-910.6005]
],0.25,0,3,[-58.60372,34.80591,-918.8499],[-47.77083,38.8681,-910.0252],true);

disk([-58.43428,38.56826,-918.2786],0.25,0,3,
[2.180312,2.828296]);

disk([-47.94528,35.10645,-910.6005],0.25,0,3,
[0.9721714,-0.34168]);

tube([[-66.79715,64.48626,-899.1358],
[-63.30082,62.32337,-896.8937],
[-59.80448,59.96784,-894.712],
[-56.30815,57.01978,-892.7167]
],0.25,0,3,[-67.01106,56.72684,-900.9924],[-56.06559,64.78634,-890.8339],true);

disk([-66.79715,64.48626,-899.1358],0.25,0,3,
[2.070083,2.587596]);

disk([-56.30815,57.01978,-892.7167],0.25,0,3,
[1.159391,-0.7005246]);

tube([[-58.43428,38.56826,-918.2786],
[-61.22191,48.77474,-911.405],
[-64.00953,57.58182,-904.9714],
[-66.79715,64.48626,-899.1358]
],0.25,0,3,[-67.13002,35.23973,-918.6282],[-58.09303,67.84616,-898.7873],true);

disk([-58.43428,38.56826,-918.2786],0.25,0,3,
[2.146929,-1.304176]);

disk([-66.79715,64.48626,-899.1358],0.25,0,3,
[0.906063,1.954526]);

tube([[-47.94528,35.10645,-910.6005],
[-50.7329,44.55207,-903.9661],
[-53.52053,51.93391,-897.9805],
[-56.30815,57.01978,-892.7167]
],0.25,0,3,[-56.63163,31.77617,-910.9502],[-47.60538,60.4026,-892.372],true);

disk([-47.94528,35.10645,-910.6005],0.25,0,3,
[2.163619,-1.28382]);

disk([-56.30815,57.01978,-892.7167],0.25,0,3,
[0.8337991,2.072188]);

tube([[-18.6044,-2.373925,-915.8452],
[-15.10807,-3.209914,-913.1859],
[-11.61173,-4.045902,-910.5266],
[-8.1154,-4.881891,-907.8673]
],0.25,0,3,[-18.76026,-5.179028,-916.1067],[-7.959543,-2.076788,-907.6058],true);

disk([-18.6044,-2.373925,-915.8452],0.25,0,3,
[2.207705,2.906895]);

disk([-8.1154,-4.881891,-907.8673],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[-26.96727,23.73159,-896.6436],
[-23.47094,20.6538,-894.689],
[-19.9746,16.62398,-893.0337],
[-16.47827,9.035667,-892.497]
],0.25,0,3,[-27.21401,8.810238,-900.7706],[-16.15712,24.02308,-888.287],true);

disk([-26.96727,23.73159,-896.6436],0.25,0,3,
[1.968098,2.419774]);

disk([-16.47827,9.035667,-892.497],0.25,0,3,
[1.506655,-1.139037]);

tube([[-18.6044,-2.373925,-915.8452],
[-21.39203,7.990975,-908.9219],
[-24.17965,18.35587,-901.9985],
[-26.96727,23.73159,-896.6436]
],0.25,0,3,[-27.29264,-5.698548,-916.1949],[-18.26288,27.11069,-896.298],true);

disk([-18.6044,-2.373925,-915.8452],0.25,0,3,
[2.143677,-1.308065]);

disk([-26.96727,23.73159,-896.6436],0.25,0,3,
[0.8467174,2.04918]);

tube([[-8.1154,-4.881891,-907.8673],
[-9.509211,-1.705823,-905.0363],
[-10.90302,1.470244,-902.2054],
[-12.29683,4.003955,-899.5764]
],0.25,0,3,[-12.62019,-6.668634,-908.215],[-7.784846,5.810745,-899.2318],true);

tube([[-12.29683,4.003955,-899.5764],
[-13.69065,6.537667,-896.9473],
[-15.08446,8.429023,-894.5203],
[-16.47827,9.035667,-892.497]
],0.25,0,3,[-16.74679,2.220496,-899.9209],[-11.97348,10.91403,-892.1947],true);

disk([-8.1154,-4.881891,-907.8673],0.25,0,3,
[2.255338,-1.157255]);

disk([-16.47827,9.035667,-892.497],0.25,0,3,
[0.6443527,2.731079]);

tube([[10.73647,-4.244278,-909.8956],
[11.17352,-4.348776,-909.5631],
[11.61056,-4.614381,-909.2814],
[12.0476,-5.014004,-909.0417]
],0.25,0,3,[10.5806,-5.303967,-910.2911],[12.29815,-3.947119,-908.5648],true);

tube([[12.0476,-5.014004,-909.0417],
[12.48464,-5.413628,-908.8021],
[12.92168,-5.947269,-908.6046],
[13.35872,-6.587839,-908.4407]
],0.25,0,3,[11.79705,-6.847827,-909.7493],[13.65321,-4.724042,-907.6869],true);

tube([[13.35872,-6.587839,-908.4407],
[14.23281,-7.86898,-908.1129],
[15.10689,-9.577839,-907.9196],
[15.98098,-11.49771,-907.7926]
],0.25,0,3,[13.06424,-11.72219,-910.1187],[16.30276,-6.327851,-906.0806],true);

tube([[15.98098,-11.49771,-907.7926],
[17.72914,-15.33745,-907.5386],
[19.47731,-20.02122,-907.5499],
[21.22548,-23.81538,-907.2816]
],0.25,0,3,[15.65919,-24.04093,-911.6233],[21.54664,-11.27323,-903.4517],true);

disk([10.73647,-4.244278,-909.8956],0.25,0,3,
[2.207705,2.906895]);

disk([21.22548,-23.81538,-907.2816],0.25,0,3,
[1.506655,-1.139037]);

tube([[2.373604,-7.389856,-899.8893],
[5.869939,-8.225845,-897.23],
[9.366273,-9.061833,-894.5707],
[12.86261,-9.897822,-891.9114]
],0.25,0,3,[2.217754,-10.19493,-900.1508],[13.01846,-7.092744,-891.6499],true);

disk([2.373604,-7.389856,-899.8893],0.25,0,3,
[2.207705,2.906895]);

disk([12.86261,-9.897822,-891.9114],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[10.73647,-4.244278,-909.8956],
[7.94885,-5.292804,-906.5601],
[5.161227,-6.34133,-903.2247],
[2.373604,-7.389856,-899.8893]
],0.25,0,3,[2.17813,-7.675088,-910.1192],[10.93195,-3.959046,-899.6657],true);

disk([10.73647,-4.244278,-909.8956],0.25,0,3,
[2.412696,0.3597665]);

disk([2.373604,-7.389856,-899.8893],0.25,0,3,
[0.728897,-2.781826]);

tube([[21.22548,-23.81538,-907.2816],
[19.83167,-23.20874,-905.2584],
[18.43785,-21.31738,-902.8313],
[17.04404,-18.78367,-900.2023]
],0.25,0,3,[16.72074,-25.6937,-907.5839],[21.49396,-17.00024,-899.8578],true);

tube([[17.04404,-18.78367,-900.2023],
[15.65023,-16.24996,-897.5733],
[14.25642,-13.07389,-894.7423],
[12.86261,-9.897822,-891.9114]
],0.25,0,3,[12.53211,-20.59042,-900.5468],[17.36735,-8.111112,-891.5637],true);

disk([21.22548,-23.81538,-907.2816],0.25,0,3,
[2.49724,-0.4105133]);

disk([12.86261,-9.897822,-891.9114],0.25,0,3,
[0.8862546,1.984338]);

tube([[50.56635,-60.77784,-912.3636],
[54.06269,-65.31085,-910.8664],
[57.55902,-68.85143,-909.0574],
[61.05536,-71.79949,-907.062]
],0.25,0,3,[50.28242,-72.09264,-915.391],[61.29799,-60.50833,-904.0764],true);

disk([50.56635,-60.77784,-912.3636],0.25,0,3,
[1.826585,2.227795]);

disk([61.05536,-71.79949,-907.062],0.25,0,3,
[1.159391,-0.7005246]);

tube([[42.20349,-45.47827,-896.5589],
[45.69982,-47.24169,-894.1911],
[49.19615,-48.64277,-891.7094],
[52.69249,-49.88616,-889.1782]
],0.25,0,3,[42.00605,-50.18672,-897.4489],[52.86697,-45.1766,-888.307],true);

disk([42.20349,-45.47827,-896.5589],0.25,0,3,
[2.114635,2.674461]);

disk([52.69249,-49.88616,-889.1782],0.25,0,3,
[0.9721714,-0.34168]);

tube([[50.56635,-60.77784,-912.3636],
[47.77873,-58.35032,-907.9354],
[44.99111,-53.52236,-902.7527],
[42.20349,-45.47827,-896.5589]
],0.25,0,3,[41.86692,-64.20795,-912.6881],[50.86027,-42.14772,-896.2096],true);

disk([50.56635,-60.77784,-912.3636],0.25,0,3,
[2.446012,-0.7164596]);

disk([42.20349,-45.47827,-896.5589],0.25,0,3,
[0.9418303,1.904388]);

tube([[61.05536,-71.79949,-907.062],
[58.26774,-66.71362,-901.7982],
[55.48011,-59.33179,-895.8126],
[52.69249,-49.88616,-889.1782]
],0.25,0,3,[52.35281,-75.18211,-907.4064],[61.37861,-46.55603,-888.8287],true);

disk([61.05536,-71.79949,-907.062],0.25,0,3,
[2.307794,-1.069405]);

disk([52.69249,-49.88616,-889.1782],0.25,0,3,
[0.977974,1.857773]);

tube([[-47.94528,35.10645,-910.6005],
[-44.44895,33.86306,-908.0692],
[-40.95261,32.46198,-905.5875],
[-37.45628,30.69856,-903.2198]
],0.25,0,3,[-48.11975,30.3969,-911.4716],[-37.25886,35.40699,-902.3298],true);

disk([-47.94528,35.10645,-910.6005],0.25,0,3,
[2.169421,2.799913]);

disk([-37.45628,30.69856,-903.2198],0.25,0,3,
[1.026957,-0.4671314]);

tube([[-56.30815,57.01978,-892.7167],
[-52.81182,54.07172,-890.7213],
[-49.31548,50.53113,-888.9122],
[-45.81915,45.99813,-887.4151]
],0.25,0,3,[-56.55074,45.72867,-895.7021],[-45.53527,57.31287,-884.3877],true);

disk([-56.30815,57.01978,-892.7167],0.25,0,3,
[1.982202,2.441068]);

disk([-45.81915,45.99813,-887.4151],0.25,0,3,
[1.315007,-0.913798]);

tube([[-47.94528,35.10645,-910.6005],
[-50.7329,44.55207,-903.9661],
[-53.52053,51.93391,-897.9805],
[-56.30815,57.01978,-892.7167]
],0.25,0,3,[-56.63147,31.77628,-910.95],[-47.60553,60.40245,-892.3722],true);

disk([-47.94528,35.10645,-910.6005],0.25,0,3,
[2.163619,-1.28382]);

disk([-56.30815,57.01978,-892.7167],0.25,0,3,
[0.8337991,2.072188]);

tube([[-37.45628,30.69856,-903.2198],
[-40.2439,38.74265,-897.026],
[-43.03152,43.5706,-891.8432],
[-45.81915,45.99813,-887.4151]
],0.25,0,3,[-46.11314,27.36798,-903.5692],[-37.11963,49.42827,-887.0905],true);

disk([-37.45628,30.69856,-903.2198],0.25,0,3,
[2.199762,-1.237204]);

disk([-45.81915,45.99813,-887.4151],0.25,0,3,
[0.6955807,2.425133]);

tube([[-8.1154,-4.881891,-907.8673],
[-4.619065,-5.717879,-905.208],
[-1.122731,-6.553868,-902.5486],
[2.373604,-7.389856,-899.8893]
],0.25,0,3,[-8.271253,-7.686974,-908.1287],[2.529458,-4.584772,-899.6279],true);

disk([-8.1154,-4.881891,-907.8673],0.25,0,3,
[2.207705,2.906895]);

disk([2.373604,-7.389856,-899.8893],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[-16.47827,9.035667,-892.497],
[-14.7301,5.241512,-892.2287],
[-12.98193,0.5577337,-892.2401],
[-11.23377,-3.282004,-891.9861]
],0.25,0,3,[-16.79938,-3.506452,-896.3269],[-10.91202,9.261185,-888.1554],true);

tube([[-11.23377,-3.282004,-891.9861],
[-10.35968,-5.201874,-891.8591],
[-9.4856,-6.910733,-891.6658],
[-8.611516,-8.191874,-891.338]
],0.25,0,3,[-11.55551,-8.451827,-893.698],[-8.317071,-3.057557,-889.66],true);

tube([[-8.611516,-8.191874,-891.338],
[-8.174474,-8.832444,-891.1741],
[-7.737432,-9.366085,-890.9766],
[-7.300391,-9.765708,-890.7369]
],0.25,0,3,[-8.905961,-10.05564,-892.0917],[-7.049877,-7.931913,-890.0294],true);

tube([[-7.300391,-9.765708,-890.7369],
[-6.863349,-10.16533,-890.4973],
[-6.426307,-10.43094,-890.2155],
[-5.989265,-10.53543,-889.8831]
],0.25,0,3,[-7.550905,-10.83258,-891.2138],[-5.833411,-9.475764,-889.4876],true);

disk([-16.47827,9.035667,-892.497],0.25,0,3,
[1.634938,2.002556]);

disk([-5.989265,-10.53543,-889.8831],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[-8.1154,-4.881891,-907.8673],
[-9.509211,-1.705823,-905.0363],
[-10.90302,1.470244,-902.2054],
[-12.29683,4.003955,-899.5764]
],0.25,0,3,[-12.62014,-6.6686,-908.215],[-7.784899,5.810708,-899.2318],true);

tube([[-12.29683,4.003955,-899.5764],
[-13.69065,6.537667,-896.9473],
[-15.08446,8.429023,-894.5203],
[-16.47827,9.035667,-892.497]
],0.25,0,3,[-16.74675,2.22053,-899.9209],[-11.97353,10.91398,-892.1948],true);

disk([-8.1154,-4.881891,-907.8673],0.25,0,3,
[2.255338,-1.157255]);

disk([-16.47827,9.035667,-892.497],0.25,0,3,
[0.6443527,2.731079]);

tube([[2.373604,-7.389856,-899.8893],
[-0.414019,-8.438382,-896.5539],
[-3.201642,-9.486909,-893.2185],
[-5.989265,-10.53543,-889.8831]
],0.25,0,3,[-6.18474,-10.82067,-900.113],[2.569079,-7.104624,-889.6594],true);

disk([2.373604,-7.389856,-899.8893],0.25,0,3,
[2.412696,0.3597665]);

disk([-5.989265,-10.53543,-889.8831],0.25,0,3,
[0.728897,-2.781826]);

tube([[21.22548,-23.81538,-907.2816],
[24.72181,-31.40369,-906.745],
[28.21815,-35.43351,-905.0897],
[31.71448,-38.5113,-903.1351]
],0.25,0,3,[20.90437,-38.80278,-911.4916],[31.96127,-23.58987,-899.008],true);

disk([21.22548,-23.81538,-907.2816],0.25,0,3,
[1.634938,2.002556]);

disk([31.71448,-38.5113,-903.1351],0.25,0,3,
[1.173495,-0.7218187]);

tube([[12.86261,-9.897822,-891.9114],
[16.35894,-10.73381,-889.2521],
[19.85528,-11.5698,-886.5927],
[23.35161,-12.40579,-883.9334]
],0.25,0,3,[12.70676,-12.7029,-892.1728],[23.50746,-9.600707,-883.672],true);

disk([12.86261,-9.897822,-891.9114],0.25,0,3,
[2.207705,2.906895]);

disk([23.35161,-12.40579,-883.9334],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[21.22548,-23.81538,-907.2816],
[19.83167,-23.20874,-905.2584],
[18.43785,-21.31738,-902.8313],
[17.04404,-18.78367,-900.2023]
],0.25,0,3,[16.72074,-25.6937,-907.5839],[21.49396,-17.00024,-899.8578],true);

tube([[17.04404,-18.78367,-900.2023],
[15.65023,-16.24996,-897.5733],
[14.25642,-13.07389,-894.7423],
[12.86261,-9.897822,-891.9114]
],0.25,0,3,[12.53211,-20.59042,-900.5468],[17.36735,-8.111112,-891.5637],true);

disk([21.22548,-23.81538,-907.2816],0.25,0,3,
[2.49724,-0.4105133]);

disk([12.86261,-9.897822,-891.9114],0.25,0,3,
[0.8862546,1.984338]);

tube([[31.71448,-38.5113,-903.1351],
[28.92686,-33.13559,-897.7801],
[26.13923,-22.77069,-890.8568],
[23.35161,-12.40579,-883.9334]
],0.25,0,3,[23.01015,-41.89046,-903.4806],[32.03979,-9.081139,-883.5838],true);

disk([31.71448,-38.5113,-903.1351],0.25,0,3,
[2.294875,-1.092412]);

disk([23.35161,-12.40579,-883.9334],0.25,0,3,
[0.9979157,1.833528]);

tube([[61.05536,-71.79949,-907.062],
[64.55169,-74.74755,-905.0666],
[68.04803,-77.10308,-902.885],
[71.54436,-79.26598,-900.6428]
],0.25,0,3,[60.81276,-79.56622,-908.9448],[71.75828,-71.50639,-898.7862],true);

disk([61.05536,-71.79949,-907.062],0.25,0,3,
[1.982202,2.441068]);

disk([71.54436,-79.26598,-900.6428],0.25,0,3,
[1.071509,-0.5539965]);

tube([[52.69249,-49.88616,-889.1782],
[56.18882,-51.12956,-886.647],
[59.68516,-52.21527,-884.0661],
[63.18149,-53.34797,-881.5001]
],0.25,0,3,[52.51809,-53.64779,-889.7534],[63.35089,-49.58565,-880.9288],true);

disk([52.69249,-49.88616,-889.1782],0.25,0,3,
[2.169421,2.799913]);

disk([63.18149,-53.34797,-881.5001],0.25,0,3,
[0.9612806,-0.3132971]);

tube([[61.05536,-71.79949,-907.062],
[58.26774,-66.71362,-901.7982],
[55.48011,-59.33179,-895.8126],
[52.69249,-49.88616,-889.1782]
],0.25,0,3,[52.35272,-75.18222,-907.4065],[61.37871,-46.55596,-888.8286],true);

disk([61.05536,-71.79949,-907.062],0.25,0,3,
[2.307794,-1.069405]);

disk([52.69249,-49.88616,-889.1782],0.25,0,3,
[0.977974,1.857773]);

tube([[71.54436,-79.26598,-900.6428],
[68.75674,-72.36153,-894.8073],
[65.96912,-63.55446,-888.3737],
[63.18149,-53.34797,-881.5001]
],0.25,0,3,[62.84037,-82.62578,-900.9912],[71.87709,-50.01952,-881.1506],true);

disk([71.54436,-79.26598,-900.6428],0.25,0,3,
[2.23553,-1.187067]);

disk([63.18149,-53.34797,-881.5001],0.25,0,3,
[0.9946637,1.837416]);

tube([[-37.45628,30.69856,-903.2198],
[-33.95994,28.93514,-900.852],
[-30.46361,26.80938,-898.5981],
[-26.96727,23.73159,-896.6436]
],0.25,0,3,[-37.65367,23.44006,-904.906],[-26.7205,31.00022,-894.9129],true);

disk([-37.45628,30.69856,-903.2198],0.25,0,3,
[2.114635,2.674461]);

disk([-26.96727,23.73159,-896.6436],0.25,0,3,
[1.173495,-0.7218187]);

tube([[-45.81915,45.99813,-887.4151],
[-42.32281,41.46513,-885.918],
[-38.82648,35.93971,-884.7328],
[-35.33014,28.56358,-884.1295]
],0.25,0,3,[-46.10294,28.33561,-892.4322],[-35.01048,46.26741,-879.0694],true);

disk([-45.81915,45.99813,-887.4151],0.25,0,3,
[1.826585,2.227795]);

disk([-35.33014,28.56358,-884.1295],0.25,0,3,
[1.497017,-1.128159]);

tube([[-37.45628,30.69856,-903.2198],
[-40.2439,38.74265,-897.026],
[-43.03152,43.5706,-891.8432],
[-45.81915,45.99813,-887.4151]
],0.25,0,3,[-46.11315,27.36787,-903.5692],[-37.11962,49.42848,-887.0904],true);

disk([-37.45628,30.69856,-903.2198],0.25,0,3,
[2.199762,-1.237204]);

disk([-45.81915,45.99813,-887.4151],0.25,0,3,
[0.6955807,2.425133]);

tube([[-26.96727,23.73159,-896.6436],
[-29.7549,29.1073,-891.2886],
[-32.54252,29.49383,-887.5021],
[-35.33014,28.56358,-884.1295]
],0.25,0,3,[-35.52996,20.40569,-896.9891],[-26.6419,32.0432,-883.9007],true);

disk([-26.96727,23.73159,-896.6436],0.25,0,3,
[2.294875,-1.092412]);

disk([-35.33014,28.56358,-884.1295],0.25,0,3,
[0.7167649,-2.819506]);

tube([[31.71448,-38.5113,-903.1351],
[35.21082,-41.58909,-901.1805],
[38.70715,-43.71485,-898.9266],
[42.20349,-45.47827,-896.5589]
],0.25,0,3,[31.46777,-45.77977,-904.8656],[42.40081,-38.21992,-894.8728],true);

disk([31.71448,-38.5113,-903.1351],0.25,0,3,
[1.968098,2.419774]);

disk([42.20349,-45.47827,-896.5589],0.25,0,3,
[1.026957,-0.4671314]);

tube([[23.35161,-12.40579,-883.9334],
[26.84795,-13.24178,-881.2741],
[30.34428,-14.07776,-878.6148],
[33.84062,-14.91375,-875.9555]
],0.25,0,3,[23.19576,-15.21087,-884.1949],[33.99647,-12.10867,-875.694],true);

disk([23.35161,-12.40579,-883.9334],0.25,0,3,
[2.207705,2.906895]);

disk([33.84062,-14.91375,-875.9555],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[31.71448,-38.5113,-903.1351],
[28.92686,-33.13559,-897.7801],
[26.13923,-22.77069,-890.8568],
[23.35161,-12.40579,-883.9334]
],0.25,0,3,[23.01015,-41.89046,-903.4806],[32.03979,-9.081139,-883.5838],true);

disk([31.71448,-38.5113,-903.1351],0.25,0,3,
[2.294875,-1.092412]);

disk([23.35161,-12.40579,-883.9334],0.25,0,3,
[0.9979157,1.833528]);

tube([[42.20349,-45.47827,-896.5589],
[39.41586,-37.43419,-890.3651],
[36.62824,-26.17397,-883.1603],
[33.84062,-14.91375,-875.9555]
],0.25,0,3,[33.49788,-48.82728,-896.9082],[42.54006,-11.59027,-875.6061],true);

disk([42.20349,-45.47827,-896.5589],0.25,0,3,
[2.199762,-1.237204]);

disk([33.84062,-14.91375,-875.9555],0.25,0,3,
[1.015009,1.813481]);

tube([[2.373604,-7.389856,-899.8893],
[5.869939,-8.225845,-897.23],
[9.366273,-9.061833,-894.5707],
[12.86261,-9.897822,-891.9114]
],0.25,0,3,[2.217714,-10.19501,-900.1508],[13.0185,-7.092665,-891.6499],true);

disk([2.373604,-7.389856,-899.8893],0.25,0,3,
[2.207705,2.906895]);

disk([12.86261,-9.897822,-891.9114],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[-5.989265,-10.53543,-889.8831],
[-5.552223,-10.63993,-889.5507],
[-5.115181,-10.58333,-889.1676],
[-4.67814,-10.3927,-888.7424]
],0.25,0,3,[-6.145136,-11.16875,-890.1446],[-4.427587,-9.811856,-888.4183],true);

tube([[-4.67814,-10.3927,-888.7424],
[-4.241098,-10.20207,-888.3172],
[-3.804056,-9.87743,-887.8499],
[-3.367014,-9.445856,-887.349]
],0.25,0,3,[-4.928692,-11.00669,-889.0666],[-3.07252,-8.882862,-887.0042],true);

tube([[-3.367014,-9.445856,-887.349],
[-2.492931,-8.58271,-886.3471],
[-1.618847,-7.291845,-885.2108],
[-0.7447632,-5.78997,-884.0081]
],0.25,0,3,[-3.661491,-10.33936,-887.6938],[-0.4229861,-4.945035,-883.6558],true);

tube([[-0.7447632,-5.78997,-884.0081],
[1.003404,-2.786221,-881.6028],
[2.751571,1.061569,-878.9321],
[4.499739,4.019736,-876.5411]
],0.25,0,3,[-1.06654,-7.268275,-884.3605],[4.820888,5.499411,-876.1889],true);

disk([-5.989265,-10.53543,-889.8831],0.25,0,3,
[2.207705,2.906895]);

disk([4.499739,4.019736,-876.5411],0.25,0,3,
[0.962865,1.037048]);

tube([[2.373604,-7.389856,-899.8893],
[-0.414019,-8.438382,-896.5539],
[-3.201642,-9.486909,-893.2185],
[-5.989265,-10.53543,-889.8831]
],0.25,0,3,[-6.184761,-10.8207,-900.113],[2.5691,-7.104592,-889.6594],true);

disk([2.373604,-7.389856,-899.8893],0.25,0,3,
[2.412696,0.3597665]);

disk([-5.989265,-10.53543,-889.8831],0.25,0,3,
[0.728897,-2.781826]);

tube([[12.86261,-9.897822,-891.9114],
[11.4688,-6.721755,-889.0804],
[10.07498,-3.545688,-886.2495],
[8.681173,-1.011976,-883.6205]
],0.25,0,3,[8.357785,-11.68459,-892.2591],[13.19319,0.7948377,-883.2759],true);

tube([[8.681173,-1.011976,-883.6205],
[7.287362,1.521736,-880.9914],
[5.89355,3.413092,-878.5644],
[4.499739,4.019736,-876.5411]
],0.25,0,3,[4.231186,-2.795456,-883.9651],[9.004561,5.898132,-876.2388],true);

disk([12.86261,-9.897822,-891.9114],0.25,0,3,
[2.255338,-1.157255]);

disk([4.499739,4.019736,-876.5411],0.25,0,3,
[0.6443527,2.731079]);

tube([[-26.96727,23.73159,-896.6436],
[-23.47094,20.6538,-894.689],
[-19.9746,16.62398,-893.0337],
[-16.47827,9.035667,-892.497]
],0.25,0,3,[-27.21408,8.810131,-900.7707],[-16.15713,24.0231,-888.287],true);

disk([-26.96727,23.73159,-896.6436],0.25,0,3,
[1.968098,2.419774]);

disk([-16.47827,9.035667,-892.497],0.25,0,3,
[1.506655,-1.139037]);

tube([[-35.33014,28.56358,-884.1295],
[-31.83381,21.18745,-883.5261],
[-28.33747,11.96062,-883.5046],
[-24.84114,3.452498,-883.2571]
],0.25,0,3,[-35.64975,3.237111,-891.5825],[-24.51441,28.79166,-875.7941],true);

disk([-35.33014,28.56358,-884.1295],0.25,0,3,
[1.644576,2.013434]);

disk([-24.84114,3.452498,-883.2571],0.25,0,3,
[1.543897,-1.180894]);

tube([[-26.96727,23.73159,-896.6436],
[-29.7549,29.1073,-891.2886],
[-32.54252,29.49383,-887.5021],
[-35.33014,28.56358,-884.1295]
],0.25,0,3,[-35.52992,20.40572,-896.9891],[-26.64197,32.04313,-883.9007],true);

disk([-26.96727,23.73159,-896.6436],0.25,0,3,
[2.294875,-1.092412]);

disk([-35.33014,28.56358,-884.1295],0.25,0,3,
[0.7167649,-2.819506]);

tube([[-16.47827,9.035667,-892.497],
[-19.26589,10.24896,-888.4506],
[-22.05352,6.323401,-886.0196],
[-24.84114,3.452498,-883.2571]
],0.25,0,3,[-25.0979,3.163344,-893.5684],[-16.20972,10.16413,-881.9374],true);

disk([-16.47827,9.035667,-892.497],0.25,0,3,
[2.49724,-0.4105133]);

disk([-24.84114,3.452498,-883.2571],0.25,0,3,
[0.9665752,-2.341478]);

tube([[-66.79715,64.48626,-899.1358],
[-63.30082,62.32337,-896.8937],
[-59.80448,59.96784,-894.712],
[-56.30815,57.01978,-892.7167]
],0.25,0,3,[-67.01106,56.72682,-900.9924],[-56.06558,64.78636,-890.8339],true);

disk([-66.79715,64.48626,-899.1358],0.25,0,3,
[2.070083,2.587596]);

disk([-56.30815,57.01978,-892.7167],0.25,0,3,
[1.159391,-0.7005246]);

tube([[-75.16002,79.78343,-883.3319],
[-71.66369,75.9388,-881.6184],
[-68.16735,71.4882,-880.0954],
[-64.67102,66.18819,-878.8394]
],0.25,0,3,[-75.42849,65.93114,-887.1412],[-64.37391,80.06374,-874.9988],true);

disk([-75.16002,79.78343,-883.3319],0.25,0,3,
[1.889302,2.308784]);

disk([-64.67102,66.18819,-878.8394],0.25,0,3,
[1.375503,-0.9876428]);

tube([[-66.79715,64.48626,-899.1358],
[-69.58478,71.39071,-893.3003],
[-72.3724,76.39252,-888.0629],
[-75.16002,79.78343,-883.3319]
],0.25,0,3,[-75.46793,61.13891,-899.4843],[-66.46428,83.1927,-882.9968],true);

disk([-66.79715,64.48626,-899.1358],0.25,0,3,
[2.23553,-1.187067]);

disk([-75.16002,79.78343,-883.3319],0.25,0,3,
[0.7479911,2.25886]);

tube([[-56.30815,57.01978,-892.7167],
[-59.09577,62.10565,-887.4528],
[-61.8834,64.89556,-882.9108],
[-64.67102,66.18819,-878.8394]
],0.25,0,3,[-64.94153,53.66306,-893.0613],[-55.98466,69.63777,-878.5352],true);

disk([-56.30815,57.01978,-892.7167],0.25,0,3,
[2.307794,-1.069405]);

disk([-64.67102,66.18819,-878.8394],0.25,0,3,
[0.6465133,2.707402]);

tube([[-16.47827,9.035667,-892.497],
[-14.7301,5.241512,-892.2287],
[-12.98193,0.5577337,-892.2401],
[-11.23377,-3.282004,-891.9861]
],0.25,0,3,[-16.79939,-3.506458,-896.3269],[-10.91202,9.261191,-888.1554],true);

tube([[-11.23377,-3.282004,-891.9861],
[-10.35968,-5.201874,-891.8591],
[-9.4856,-6.910733,-891.6658],
[-8.611516,-8.191874,-891.338]
],0.25,0,3,[-11.55552,-8.451834,-893.698],[-8.317063,-3.057551,-889.66],true);

tube([[-8.611516,-8.191874,-891.338],
[-8.174474,-8.832444,-891.1741],
[-7.737432,-9.366085,-890.9766],
[-7.300391,-9.765708,-890.7369]
],0.25,0,3,[-8.905969,-10.05564,-892.0917],[-7.04987,-7.931914,-890.0294],true);

tube([[-7.300391,-9.765708,-890.7369],
[-6.863349,-10.16533,-890.4973],
[-6.426307,-10.43094,-890.2155],
[-5.989265,-10.53543,-889.8831]
],0.25,0,3,[-7.550911,-10.83256,-891.2138],[-5.833407,-9.475776,-889.4876],true);

disk([-16.47827,9.035667,-892.497],0.25,0,3,
[1.634938,2.002556]);

disk([-5.989265,-10.53543,-889.8831],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[-24.84114,3.452498,-883.2571],
[-23.09297,-0.8015615,-883.1334],
[-21.3448,-4.875942,-882.9531],
[-19.59664,-7.991307,-882.4714]
],0.25,0,3,[-25.16779,-8.234482,-886.8233],[-19.2875,3.667834,-878.9286],true);

tube([[-19.59664,-7.991307,-882.4714],
[-18.72255,-9.548989,-882.2306],
[-17.84847,-10.86692,-881.9144],
[-16.97439,-11.84767,-881.4921]
],0.25,0,3,[-19.90578,-12.12692,-883.8504],[-16.70397,-7.748131,-880.1568],true);

tube([[-16.97439,-11.84767,-881.4921],
[-16.1003,-12.82843,-881.0699],
[-15.22622,-13.47202,-880.5417],
[-14.35213,-13.68101,-879.8769]
],0.25,0,3,[-17.2448,-13.97815,-882.2199],[-14.1963,-11.56843,-879.2504],true);

disk([-24.84114,3.452498,-883.2571],0.25,0,3,
[1.597695,1.960699]);

disk([-14.35213,-13.68101,-879.8769],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[-16.47827,9.035667,-892.497],
[-19.26589,10.24896,-888.4506],
[-22.05352,6.323401,-886.0196],
[-24.84114,3.452498,-883.2571]
],0.25,0,3,[-25.09784,3.163411,-893.5683],[-16.20979,10.16406,-881.9375],true);

disk([-16.47827,9.035667,-892.497],0.25,0,3,
[2.49724,-0.4105133]);

disk([-24.84114,3.452498,-883.2571],0.25,0,3,
[0.9665752,-2.341478]);

tube([[-5.989265,-10.53543,-889.8831],
[-8.776888,-11.58396,-886.5477],
[-11.56451,-12.63249,-883.2123],
[-14.35213,-13.68101,-879.8769]
],0.25,0,3,[-14.54761,-13.96625,-890.1068],[-5.793791,-10.2502,-879.6532],true);

disk([-5.989265,-10.53543,-889.8831],0.25,0,3,
[2.412696,0.3597665]);

disk([-14.35213,-13.68101,-879.8769],0.25,0,3,
[0.728897,-2.781826]);

tube([[-56.30815,57.01978,-892.7167],
[-52.81182,54.07172,-890.7213],
[-49.31548,50.53113,-888.9122],
[-45.81915,45.99813,-887.4151]
],0.25,0,3,[-56.55074,45.72867,-895.7021],[-45.53527,57.31287,-884.3877],true);

disk([-56.30815,57.01978,-892.7167],0.25,0,3,
[1.982202,2.441068]);

disk([-45.81915,45.99813,-887.4151],0.25,0,3,
[1.315007,-0.913798]);

tube([[-64.67102,66.18819,-878.8394],
[-61.17468,60.88819,-877.5834],
[-57.67835,54.73877,-876.5944],
[-54.18202,47.49651,-875.949]
],0.25,0,3,[-64.96819,47.26681,-884.2625],[-53.86346,66.44537,-870.4994],true);

disk([-64.67102,66.18819,-878.8394],0.25,0,3,
[1.76609,2.15395]);

disk([-54.18202,47.49651,-875.949],0.25,0,3,
[1.490711,-1.121029]);

tube([[-56.30815,57.01978,-892.7167],
[-59.09577,62.10565,-887.4528],
[-61.8834,64.89556,-882.9108],
[-64.67102,66.18819,-878.8394]
],0.25,0,3,[-64.94137,53.66318,-893.0611],[-55.98485,69.63759,-878.5354],true);

disk([-56.30815,57.01978,-892.7167],0.25,0,3,
[2.307794,-1.069405]);

disk([-64.67102,66.18819,-878.8394],0.25,0,3,
[0.6465133,2.707402]);

tube([[-45.81915,45.99813,-887.4151],
[-48.60677,48.42566,-882.9869],
[-51.39439,48.45276,-879.3134],
[-54.18202,47.49651,-875.949]
],0.25,0,3,[-54.38091,42.61977,-887.7396],[-45.52523,50.96414,-875.7213],true);

disk([-45.81915,45.99813,-887.4151],0.25,0,3,
[2.446012,-0.7164596]);

disk([-54.18202,47.49651,-875.949],0.25,0,3,
[0.7193698,-2.811139]);

tube([[42.20349,-45.47827,-896.5589],
[45.69982,-47.24169,-894.1911],
[49.19615,-48.64277,-891.7094],
[52.69249,-49.88616,-889.1782]
],0.25,0,3,[42.00612,-50.18661,-897.4488],[52.86691,-45.1767,-888.3071],true);

disk([42.20349,-45.47827,-896.5589],0.25,0,3,
[2.114635,2.674461]);

disk([52.69249,-49.88616,-889.1782],0.25,0,3,
[0.9721714,-0.34168]);

tube([[33.84062,-14.91375,-875.9555],
[37.33695,-15.74974,-873.2962],
[40.83329,-16.58573,-870.6368],
[44.32962,-17.42172,-867.9775]
],0.25,0,3,[33.68477,-17.71883,-876.2169],[44.48546,-14.61664,-867.7161],true);

disk([33.84062,-14.91375,-875.9555],0.25,0,3,
[2.207705,2.906895]);

disk([44.32962,-17.42172,-867.9775],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[42.20349,-45.47827,-896.5589],
[39.41586,-37.43419,-890.3651],
[36.62824,-26.17397,-883.1603],
[33.84062,-14.91375,-875.9555]
],0.25,0,3,[33.49788,-48.82728,-896.9082],[42.54006,-11.59027,-875.6061],true);

disk([42.20349,-45.47827,-896.5589],0.25,0,3,
[2.199762,-1.237204]);

disk([33.84062,-14.91375,-875.9555],0.25,0,3,
[1.015009,1.813481]);

tube([[52.69249,-49.88616,-889.1782],
[49.90487,-40.44054,-882.5438],
[47.11724,-28.93113,-875.2607],
[44.32962,-17.42172,-867.9775]
],0.25,0,3,[43.98658,-53.2238,-889.5279],[53.03234,-14.09839,-867.6282],true);

disk([52.69249,-49.88616,-889.1782],0.25,0,3,
[2.163619,-1.28382]);

disk([44.32962,-17.42172,-867.9775],0.25,0,3,
[1.019408,1.808424]);

tube([[-45.81915,45.99813,-887.4151],
[-42.32281,41.46513,-885.918],
[-38.82648,35.93971,-884.7328],
[-35.33014,28.56358,-884.1295]
],0.25,0,3,[-46.10293,28.33562,-892.4322],[-35.01049,46.2674,-879.0695],true);

disk([-45.81915,45.99813,-887.4151],0.25,0,3,
[1.826585,2.227795]);

disk([-35.33014,28.56358,-884.1295],0.25,0,3,
[1.497017,-1.128159]);

tube([[-54.18202,47.49651,-875.949],
[-50.68568,40.25425,-875.3036],
[-47.18935,31.91915,-875.0017],
[-43.69301,22.9595,-874.8961]
],0.25,0,3,[-54.50065,22.74871,-883.2192],[-43.36402,47.72612,-867.6113],true);

disk([-54.18202,47.49651,-875.949],0.25,0,3,
[1.650881,2.020564]);

disk([-43.69301,22.9595,-874.8961],0.25,0,3,
[1.559822,-1.198739]);

tube([[-45.81915,45.99813,-887.4151],
[-48.60677,48.42566,-882.9869],
[-51.39439,48.45276,-879.3134],
[-54.18202,47.49651,-875.949]
],0.25,0,3,[-54.3809,42.61961,-887.7398],[-45.52514,50.96438,-875.7213],true);

disk([-45.81915,45.99813,-887.4151],0.25,0,3,
[2.446012,-0.7164596]);

disk([-54.18202,47.49651,-875.949],0.25,0,3,
[0.7193698,-2.811139]);

tube([[-35.33014,28.56358,-884.1295],
[-38.11777,27.63333,-880.7569],
[-40.90539,25.38631,-877.7983],
[-43.69301,22.9595,-874.8961]
],0.25,0,3,[-43.93647,22.66772,-885.1276],[-35.13031,28.85811,-873.8398],true);

disk([-35.33014,28.56358,-884.1295],0.25,0,3,
[2.424828,0.3220863]);

disk([-43.69301,22.9595,-874.8961],0.25,0,3,
[0.9051367,-2.42528]);

tube([[12.86261,-9.897822,-891.9114],
[16.35894,-10.73381,-889.2521],
[19.85528,-11.5698,-886.5927],
[23.35161,-12.40579,-883.9334]
],0.25,0,3,[12.70672,-12.70298,-892.1729],[23.5075,-9.600631,-883.6719],true);

disk([12.86261,-9.897822,-891.9114],0.25,0,3,
[2.207705,2.906895]);

disk([23.35161,-12.40579,-883.9334],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[4.499739,4.019736,-876.5411],
[7.996073,9.93607,-871.7592],
[11.49241,12.29391,-868.0958],
[14.98874,13.69973,-864.7318]
],0.25,0,3,[4.178582,1.304157,-876.8934],[15.23547,16.51699,-864.4098],true);

disk([4.499739,4.019736,-876.5411],0.25,0,3,
[2.178728,-2.104545]);

disk([14.98874,13.69973,-864.7318],0.25,0,3,
[0.8420255,0.3823001]);

tube([[12.86261,-9.897822,-891.9114],
[11.4688,-6.721755,-889.0804],
[10.07498,-3.545688,-886.2495],
[8.681173,-1.011976,-883.6205]
],0.25,0,3,[8.3579,-11.68451,-892.259],[13.19308,0.7947545,-883.276],true);

tube([[8.681173,-1.011976,-883.6205],
[7.287362,1.521736,-880.9914],
[5.89355,3.413092,-878.5644],
[4.499739,4.019736,-876.5411]
],0.25,0,3,[4.231282,-2.795367,-883.9649],[9.004446,5.898023,-876.2389],true);

disk([12.86261,-9.897822,-891.9114],0.25,0,3,
[2.255338,-1.157255]);

disk([4.499739,4.019736,-876.5411],0.25,0,3,
[0.6443527,2.731079]);

tube([[23.35161,-12.40579,-883.9334],
[20.56399,-2.040888,-877.0101],
[17.77637,8.324012,-870.0867],
[14.98874,13.69973,-864.7318]
],0.25,0,3,[14.66346,-15.7304,-884.283],[23.69304,17.07883,-864.3863],true);

disk([23.35161,-12.40579,-883.9334],0.25,0,3,
[2.143677,-1.308065]);

disk([14.98874,13.69973,-864.7318],0.25,0,3,
[0.8467174,2.04918]);

tube([[-35.33014,28.56358,-884.1295],
[-31.83381,21.18745,-883.5261],
[-28.33747,11.96062,-883.5046],
[-24.84114,3.452498,-883.2571]
],0.25,0,3,[-35.64967,3.237169,-891.5824],[-24.5145,28.7916,-875.7942],true);

disk([-35.33014,28.56358,-884.1295],0.25,0,3,
[1.644576,2.013434]);

disk([-24.84114,3.452498,-883.2571],0.25,0,3,
[1.543897,-1.180894]);

tube([[-43.69301,22.9595,-874.8961],
[-40.19668,13.99986,-874.7906],
[-36.70034,4.41569,-874.8814],
[-33.20401,-3.081926,-874.3162]
],0.25,0,3,[-44.02187,-3.30848,-882.6526],[-32.88359,23.17035,-866.5717],true);

disk([-43.69301,22.9595,-874.8961],0.25,0,3,
[1.58177,1.942853]);

disk([-33.20401,-3.081926,-874.3162],0.25,0,3,
[1.502587,-1.134449]);

tube([[-35.33014,28.56358,-884.1295],
[-38.11777,27.63333,-880.7569],
[-40.90539,25.38631,-877.7983],
[-43.69301,22.9595,-874.8961]
],0.25,0,3,[-43.93637,22.66784,-885.1275],[-35.13037,28.85801,-873.8399],true);

disk([-35.33014,28.56358,-884.1295],0.25,0,3,
[2.424828,0.3220863]);

disk([-43.69301,22.9595,-874.8961],0.25,0,3,
[0.9051367,-2.42528]);

tube([[-24.84114,3.452498,-883.2571],
[-27.62876,0.5815948,-880.4946],
[-30.41638,-1.234656,-877.4005],
[-33.20401,-3.081926,-874.3162]
],0.25,0,3,[-33.42813,-3.373827,-884.618],[-24.58448,3.741539,-872.999],true);

disk([-24.84114,3.452498,-883.2571],0.25,0,3,
[2.175017,0.8001147]);

disk([-33.20401,-3.081926,-874.3162],0.25,0,3,
[0.8257939,-2.556363]);

tube([[-5.989265,-10.53543,-889.8831],
[-5.552223,-10.63993,-889.5507],
[-5.115181,-10.58333,-889.1676],
[-4.67814,-10.3927,-888.7424]
],0.25,0,3,[-6.145123,-11.16872,-890.1446],[-4.42761,-9.81188,-888.4183],true);

tube([[-4.67814,-10.3927,-888.7424],
[-4.241098,-10.20207,-888.3172],
[-3.804056,-9.87743,-887.8499],
[-3.367014,-9.445856,-887.349]
],0.25,0,3,[-4.92866,-11.00666,-889.0665],[-3.072547,-8.882888,-887.0042],true);

tube([[-3.367014,-9.445856,-887.349],
[-2.492931,-8.58271,-886.3471],
[-1.618847,-7.291845,-885.2108],
[-0.7447632,-5.78997,-884.0081]
],0.25,0,3,[-3.661467,-10.33933,-887.6938],[-0.4230127,-4.945053,-883.6558],true);

tube([[-0.7447632,-5.78997,-884.0081],
[1.003404,-2.786221,-881.6028],
[2.751571,1.061569,-878.9321],
[4.499739,4.019736,-876.5411]
],0.25,0,3,[-1.066514,-7.268256,-884.3605],[4.820862,5.499392,-876.1889],true);

disk([-5.989265,-10.53543,-889.8831],0.25,0,3,
[2.207705,2.906895]);

disk([4.499739,4.019736,-876.5411],0.25,0,3,
[0.962865,1.037048]);

tube([[-14.35213,-13.68101,-879.8769],
[-13.47805,-13.89001,-879.212],
[-12.60397,-13.66442,-878.4106],
[-11.72988,-13.10166,-877.5032]
],0.25,0,3,[-14.50801,-14.63259,-880.1384],[-11.45938,-12.22278,-877.1687],true);

tube([[-11.72988,-13.10166,-877.5032],
[-10.8558,-12.53889,-876.5957],
[-9.981716,-11.63896,-875.5823],
[-9.107632,-10.49927,-874.4935]
],0.25,0,3,[-12.00038,-14.01719,-877.8376],[-8.798395,-9.638291,-874.1438],true);

tube([[-9.107632,-10.49927,-874.4935],
[-7.359465,-8.219896,-872.3159],
[-5.611298,-4.981504,-869.8368],
[-3.86313,-1.563433,-867.3012]
],0.25,0,3,[-9.41687,-12.0007,-874.8431],[-3.536378,-0.09823429,-866.9483],true);

disk([-14.35213,-13.68101,-879.8769],0.25,0,3,
[2.207705,2.906895]);

disk([-3.86313,-1.563433,-867.3012],0.25,0,3,
[0.9871125,1.098032]);

tube([[-5.989265,-10.53543,-889.8831],
[-8.776888,-11.58396,-886.5477],
[-11.56451,-12.63249,-883.2123],
[-14.35213,-13.68101,-879.8769]
],0.25,0,3,[-14.54764,-13.96629,-890.1068],[-5.793764,-10.25015,-879.6532],true);

disk([-5.989265,-10.53543,-889.8831],0.25,0,3,
[2.412696,0.3597665]);

disk([-14.35213,-13.68101,-879.8769],0.25,0,3,
[0.728897,-2.781826]);

tube([[4.499739,4.019736,-876.5411],
[1.712116,5.233026,-872.4947],
[-1.075507,1.30747,-870.0637],
[-3.86313,-1.563433,-867.3012]
],0.25,0,3,[-4.119833,-1.852558,-877.6124],[4.768215,5.148167,-865.9816],true);

disk([4.499739,4.019736,-876.5411],0.25,0,3,
[2.49724,-0.4105133]);

disk([-3.86313,-1.563433,-867.3012],0.25,0,3,
[0.9665752,-2.341478]);

tube([[-24.84114,3.452498,-883.2571],
[-23.09297,-0.8015615,-883.1334],
[-21.3448,-4.875942,-882.9531],
[-19.59664,-7.991307,-882.4714]
],0.25,0,3,[-25.16788,-8.234553,-886.8234],[-19.28741,3.667897,-878.9285],true);

tube([[-19.59664,-7.991307,-882.4714],
[-18.72255,-9.548989,-882.2306],
[-17.84847,-10.86692,-881.9144],
[-16.97439,-11.84767,-881.4921]
],0.25,0,3,[-19.90587,-12.12695,-883.8505],[-16.70389,-7.74806,-880.1567],true);

tube([[-16.97439,-11.84767,-881.4921],
[-16.1003,-12.82843,-881.0699],
[-15.22622,-13.47202,-880.5417],
[-14.35213,-13.68101,-879.8769]
],0.25,0,3,[-17.24488,-13.97819,-882.22],[-14.19626,-11.56839,-879.2504],true);

disk([-24.84114,3.452498,-883.2571],0.25,0,3,
[1.597695,1.960699]);

disk([-14.35213,-13.68101,-879.8769],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[-33.20401,-3.081926,-874.3162],
[-31.45584,-6.830735,-874.0336],
[-29.70767,-10.0579,-873.5871],
[-27.95951,-12.45237,-872.8787]
],0.25,0,3,[-33.52453,-12.71774,-877.2345],[-27.67074,-2.855292,-869.9992],true);

tube([[-27.95951,-12.45237,-872.8787],
[-26.21134,-14.84684,-872.1704],
[-24.46317,-16.4086,-871.2003],
[-22.715,-16.82659,-869.8707]
],0.25,0,3,[-28.24827,-17.12376,-874.2222],[-22.55912,-12.187,-868.6483],true);

disk([-33.20401,-3.081926,-874.3162],0.25,0,3,
[1.639005,2.007144]);

disk([-22.715,-16.82659,-869.8707],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[-24.84114,3.452498,-883.2571],
[-27.62876,0.5815948,-880.4946],
[-30.41638,-1.234656,-877.4005],
[-33.20401,-3.081926,-874.3162]
],0.25,0,3,[-33.42817,-3.373873,-884.6181],[-24.58444,3.741585,-872.9989],true);

disk([-24.84114,3.452498,-883.2571],0.25,0,3,
[2.175017,0.8001147]);

disk([-33.20401,-3.081926,-874.3162],0.25,0,3,
[0.8257939,-2.556363]);

tube([[-14.35213,-13.68101,-879.8769],
[-17.13976,-14.72954,-876.5415],
[-19.92738,-15.77807,-873.2061],
[-22.715,-16.82659,-869.8707]
],0.25,0,3,[-22.91048,-17.11182,-880.1005],[-14.15666,-13.39578,-869.647],true);

disk([-14.35213,-13.68101,-879.8769],0.25,0,3,
[2.412696,0.3597665]);

disk([-22.715,-16.82659,-869.8707],0.25,0,3,
[0.728897,-2.781826]);

tube([[-75.16002,79.78343,-883.3319],
[-71.66369,75.9388,-881.6184],
[-68.16735,71.4882,-880.0954],
[-64.67102,66.18819,-878.8394]
],0.25,0,3,[-75.42854,65.93101,-887.1413],[-64.37384,80.06389,-874.9987],true);

disk([-75.16002,79.78343,-883.3319],0.25,0,3,
[1.889302,2.308784]);

disk([-64.67102,66.18819,-878.8394],0.25,0,3,
[1.375503,-0.9876428]);

tube([[-83.52289,85.63931,-870.496],
[-80.02656,80.02174,-869.3398],
[-76.53022,73.51591,-868.4629],
[-73.03389,66.24858,-867.8253]
],0.25,0,3,[-83.82461,66.01918,-876.1421],[-72.71514,85.89158,-862.1577],true);

disk([-83.52289,85.63931,-870.496],0.25,0,3,
[1.743783,2.127519]);

disk([-73.03389,66.24858,-867.8253],0.25,0,3,
[1.491906,-1.12238]);

tube([[-75.16002,79.78343,-883.3319],
[-77.94765,83.17435,-878.6009],
[-80.73527,84.95437,-874.3763],
[-83.52289,85.63931,-870.496]
],0.25,0,3,[-83.77698,76.40386,-883.6669],[-74.85224,89.09478,-870.2077],true);

disk([-75.16002,79.78343,-883.3319],0.25,0,3,
[2.393602,-0.8827329]);

disk([-83.52289,85.63931,-870.496],0.25,0,3,
[0.6369175,2.900658]);

tube([[-64.67102,66.18819,-878.8394],
[-67.45864,67.48082,-874.768],
[-70.24627,67.27616,-871.1673],
[-73.03389,66.24858,-867.8253]
],0.25,0,3,[-73.2301,62.78655,-879.1435],[-64.4006,69.70755,-867.6008],true);

disk([-64.67102,66.18819,-878.8394],0.25,0,3,
[2.495079,-0.4341902]);

disk([-73.03389,66.24858,-867.8253],0.25,0,3,
[0.7266969,-2.788425]);

tube([[52.69249,-49.88616,-889.1782],
[56.18882,-51.12956,-886.647],
[59.68516,-52.21527,-884.0661],
[63.18149,-53.34797,-881.5001]
],0.25,0,3,[52.51805,-53.64775,-889.7534],[63.35091,-49.58569,-880.9288],true);

disk([52.69249,-49.88616,-889.1782],0.25,0,3,
[2.169421,2.799913]);

disk([63.18149,-53.34797,-881.5001],0.25,0,3,
[0.9612806,-0.3132971]);

tube([[44.32962,-17.42172,-867.9775],
[47.82595,-18.25771,-865.3182],
[51.32229,-19.0937,-862.6589],
[54.81862,-19.92968,-859.9996]
],0.25,0,3,[44.17377,-20.2268,-868.239],[54.97447,-17.12461,-859.7381],true);

disk([44.32962,-17.42172,-867.9775],0.25,0,3,
[2.207705,2.906895]);

disk([54.81862,-19.92968,-859.9996],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[52.69249,-49.88616,-889.1782],
[49.90487,-40.44054,-882.5438],
[47.11724,-28.93113,-875.2607],
[44.32962,-17.42172,-867.9775]
],0.25,0,3,[43.98658,-53.2238,-889.5279],[53.03234,-14.09839,-867.6282],true);

disk([52.69249,-49.88616,-889.1782],0.25,0,3,
[2.163619,-1.28382]);

disk([44.32962,-17.42172,-867.9775],0.25,0,3,
[1.019408,1.808424]);

tube([[63.18149,-53.34797,-881.5001],
[60.39387,-43.14148,-874.6265],
[57.60625,-31.53558,-867.3131],
[54.81862,-19.92968,-859.9996]
],0.25,0,3,[54.47546,-56.68036,-881.8497],[63.5227,-16.60637,-859.6502],true);

disk([63.18149,-53.34797,-881.5001],0.25,0,3,
[2.146929,-1.304176]);

disk([54.81862,-19.92968,-859.9996],0.25,0,3,
[1.021072,1.806521]);

tube([[-64.67102,66.18819,-878.8394],
[-61.17468,60.88819,-877.5834],
[-57.67835,54.73877,-876.5944],
[-54.18202,47.49651,-875.949]
],0.25,0,3,[-64.96815,47.26684,-884.2624],[-53.8635,66.44534,-870.4995],true);

disk([-64.67102,66.18819,-878.8394],0.25,0,3,
[1.76609,2.15395]);

disk([-54.18202,47.49651,-875.949],0.25,0,3,
[1.490711,-1.121029]);

tube([[-73.03389,66.24858,-867.8253],
[-69.53755,58.98126,-867.1878],
[-66.04122,50.95244,-866.7896],
[-62.54488,42.28892,-866.591]
],0.25,0,3,[-73.35259,42.07516,-874.9151],[-62.21744,66.47795,-859.489],true);

disk([-73.03389,66.24858,-867.8253],0.25,0,3,
[1.649687,2.019213]);

disk([-62.54488,42.28892,-866.591],0.25,0,3,
[1.549538,-1.187217]);

tube([[-64.67102,66.18819,-878.8394],
[-67.45864,67.48082,-874.768],
[-70.24627,67.27616,-871.1673],
[-73.03389,66.24858,-867.8253]
],0.25,0,3,[-73.23004,62.78663,-879.1434],[-64.40068,69.70745,-867.6009],true);

disk([-64.67102,66.18819,-878.8394],0.25,0,3,
[2.495079,-0.4341902]);

disk([-73.03389,66.24858,-867.8253],0.25,0,3,
[0.7266969,-2.788425]);

tube([[-54.18202,47.49651,-875.949],
[-56.96964,46.54027,-872.5846],
[-59.75726,44.60068,-869.5293],
[-62.54488,42.28892,-866.591]
],0.25,0,3,[-62.7845,41.99696,-876.8217],[-53.98313,47.78915,-865.6642],true);

disk([-54.18202,47.49651,-875.949],0.25,0,3,
[2.422223,0.330454]);

disk([-62.54488,42.28892,-866.591],0.25,0,3,
[0.8891713,-2.449242]);

tube([[-54.18202,47.49651,-875.949],
[-50.68568,40.25425,-875.3036],
[-47.18935,31.91915,-875.0017],
[-43.69301,22.9595,-874.8961]
],0.25,0,3,[-54.50052,22.74866,-883.2191],[-43.36415,47.72618,-867.6114],true);

disk([-54.18202,47.49651,-875.949],0.25,0,3,
[1.650881,2.020564]);

disk([-43.69301,22.9595,-874.8961],0.25,0,3,
[1.559822,-1.198739]);

tube([[-62.54488,42.28892,-866.591],
[-59.04855,33.62539,-866.3923],
[-55.55222,24.32716,-866.3932],
[-52.05588,15.37823,-866.2843]
],0.25,0,3,[-62.87232,15.16728,-874.6141],[-51.72707,42.50267,-858.2591],true);

disk([-62.54488,42.28892,-866.591],0.25,0,3,
[1.592055,1.954376]);

disk([-52.05588,15.37823,-866.2843],0.25,0,3,
[1.559461,-1.198334]);

tube([[-54.18202,47.49651,-875.949],
[-56.96964,46.54027,-872.5846],
[-59.75726,44.60068,-869.5293],
[-62.54488,42.28892,-866.591]
],0.25,0,3,[-62.78461,41.99686,-876.8218],[-53.98318,47.78928,-865.6642],true);

disk([-54.18202,47.49651,-875.949],0.25,0,3,
[2.422223,0.330454]);

disk([-62.54488,42.28892,-866.591],0.25,0,3,
[0.8891713,-2.449242]);

tube([[-43.69301,22.9595,-874.8961],
[-46.48063,20.5327,-871.994],
[-49.26826,17.92611,-869.1484],
[-52.05588,15.37823,-866.2843]
],0.25,0,3,[-52.30304,15.08705,-876.5673],[-43.44962,23.25121,-864.608],true);

disk([-43.69301,22.9595,-874.8961],0.25,0,3,
[2.236456,0.7163122]);

disk([-52.05588,15.37823,-866.2843],0.25,0,3,
[0.9219564,-2.401097]);

tube([[-43.69301,22.9595,-874.8961],
[-40.19668,13.99986,-874.7906],
[-36.70034,4.41569,-874.8814],
[-33.20401,-3.081926,-874.3162]
],0.25,0,3,[-44.02197,-3.308398,-882.6527],[-32.88349,23.17028,-866.5716],true);

disk([-43.69301,22.9595,-874.8961],0.25,0,3,
[1.58177,1.942853]);

disk([-33.20401,-3.081926,-874.3162],0.25,0,3,
[1.502587,-1.134449]);

tube([[-52.05588,15.37823,-866.2843],
[-48.55955,6.429296,-866.1754],
[-45.06321,-2.170329,-865.9567],
[-41.56688,-8.561698,-865.0438]
],0.25,0,3,[-52.38479,-8.802674,-873.3855],[-41.25594,15.58911,-857.9671],true);

disk([-52.05588,15.37823,-866.2843],0.25,0,3,
[1.582132,1.943259]);

disk([-41.56688,-8.561698,-865.0438],0.25,0,3,
[1.446135,-1.070229]);

tube([[-43.69301,22.9595,-874.8961],
[-46.48063,20.5327,-871.994],
[-49.26826,17.92611,-869.1484],
[-52.05588,15.37823,-866.2843]
],0.25,0,3,[-52.30299,15.0871,-876.5672],[-43.44966,23.25116,-864.6081],true);

disk([-43.69301,22.9595,-874.8961],0.25,0,3,
[2.236456,0.7163122]);

disk([-52.05588,15.37823,-866.2843],0.25,0,3,
[0.9219564,-2.401097]);

tube([[-33.20401,-3.081926,-874.3162],
[-35.99163,-4.929196,-871.2319],
[-38.77925,-6.807486,-868.1573],
[-41.56688,-8.561698,-865.0438]
],0.25,0,3,[-41.78769,-8.853227,-875.3069],[-32.97989,-2.790034,-864.0573],true);

disk([-33.20401,-3.081926,-874.3162],0.25,0,3,
[2.315799,0.5852296]);

disk([-41.56688,-8.561698,-865.0438],0.25,0,3,
[0.8134964,-2.579917]);

tube([[23.35161,-12.40579,-883.9334],
[26.84795,-13.24178,-881.2741],
[30.34428,-14.07776,-878.6148],
[33.84062,-14.91375,-875.9555]
],0.25,0,3,[23.19575,-15.21091,-884.1949],[33.99648,-12.10863,-875.694],true);

disk([23.35161,-12.40579,-883.9334],0.25,0,3,
[2.207705,2.906895]);

disk([33.84062,-14.91375,-875.9555],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[14.98874,13.69973,-864.7318],
[18.48508,15.10554,-861.3677],
[21.98141,15.55933,-858.3029],
[25.47775,15.65077,-855.3521]
],0.25,0,3,[14.74195,10.91208,-865.0538],[25.67513,18.47216,-855.0607],true);

disk([14.98874,13.69973,-864.7318],0.25,0,3,
[2.299567,-2.759293]);

disk([25.47775,15.65077,-855.3521],0.25,0,3,
[0.8699712,0.02614784]);

tube([[23.35161,-12.40579,-883.9334],
[20.56399,-2.040888,-877.0101],
[17.77637,8.324012,-870.0867],
[14.98874,13.69973,-864.7318]
],0.25,0,3,[14.66351,-15.73039,-884.2829],[23.69299,17.07883,-864.3863],true);

disk([23.35161,-12.40579,-883.9334],0.25,0,3,
[2.143677,-1.308065]);

disk([14.98874,13.69973,-864.7318],0.25,0,3,
[0.8467174,2.04918]);

tube([[33.84062,-14.91375,-875.9555],
[31.05299,-3.653536,-868.7507],
[28.26537,7.606681,-861.5459],
[25.47775,15.65077,-855.3521]
],0.25,0,3,[25.14117,-18.23718,-876.3049],[34.18335,18.9997,-855.0027],true);

disk([33.84062,-14.91375,-875.9555],0.25,0,3,
[2.126584,-1.328112]);

disk([25.47775,15.65077,-855.3521],0.25,0,3,
[0.9418303,1.904388]);

tube([[-14.35213,-13.68101,-879.8769],
[-13.47805,-13.89001,-879.212],
[-12.60397,-13.66442,-878.4106],
[-11.72988,-13.10166,-877.5032]
],0.25,0,3,[-14.50801,-14.63258,-880.1384],[-11.45938,-12.22279,-877.1687],true);

tube([[-11.72988,-13.10166,-877.5032],
[-10.8558,-12.53889,-876.5957],
[-9.981716,-11.63896,-875.5823],
[-9.107632,-10.49927,-874.4935]
],0.25,0,3,[-12.00038,-14.01719,-877.8376],[-8.798393,-9.638289,-874.1438],true);

tube([[-9.107632,-10.49927,-874.4935],
[-7.359465,-8.219896,-872.3159],
[-5.611298,-4.981504,-869.8368],
[-3.86313,-1.563433,-867.3012]
],0.25,0,3,[-9.416872,-12.0007,-874.8431],[-3.536376,-0.0982331,-866.9483],true);

disk([-14.35213,-13.68101,-879.8769],0.25,0,3,
[2.207705,2.906895]);

disk([-3.86313,-1.563433,-867.3012],0.25,0,3,
[0.9871125,1.098032]);

tube([[-22.715,-16.82659,-869.8707],
[-20.96684,-17.24459,-868.541],
[-19.21867,-16.51881,-866.8518],
[-17.4705,-14.96034,-864.9008]
],0.25,0,3,[-22.87085,-18.40951,-870.1321],[-17.18179,-13.47276,-864.5583],true);

tube([[-17.4705,-14.96034,-864.9008],
[-15.72233,-13.40186,-862.9498],
[-13.97417,-11.01068,-860.737],
[-12.226,-8.097858,-858.3603]
],0.25,0,3,[-17.75921,-16.48722,-865.2433],[-11.90554,-6.62488,-858.0082],true);

disk([-22.715,-16.82659,-869.8707],0.25,0,3,
[2.207705,2.906895]);

disk([-12.226,-8.097858,-858.3603],0.25,0,3,
[0.9603199,1.030257]);

tube([[-14.35213,-13.68101,-879.8769],
[-17.13976,-14.72954,-876.5415],
[-19.92738,-15.77807,-873.2061],
[-22.715,-16.82659,-869.8707]
],0.25,0,3,[-22.9105,-17.11186,-880.1006],[-14.15664,-13.39575,-869.647],true);

disk([-14.35213,-13.68101,-879.8769],0.25,0,3,
[2.412696,0.3597665]);

disk([-22.715,-16.82659,-869.8707],0.25,0,3,
[0.728897,-2.781826]);

tube([[-3.86313,-1.563433,-867.3012],
[-6.650754,-4.434336,-864.5387],
[-9.438377,-6.250587,-861.4446],
[-12.226,-8.097858,-858.3603]
],0.25,0,3,[-12.45016,-8.389818,-868.6621],[-3.606442,-1.27434,-857.043],true);

disk([-3.86313,-1.563433,-867.3012],0.25,0,3,
[2.175017,0.8001147]);

disk([-12.226,-8.097858,-858.3603],0.25,0,3,
[0.8257939,-2.556363]);

tube([[-33.20401,-3.081926,-874.3162],
[-31.45584,-6.830735,-874.0336],
[-29.70767,-10.0579,-873.5871],
[-27.95951,-12.45237,-872.8787]
],0.25,0,3,[-33.52454,-12.71774,-877.2345],[-27.67073,-2.855289,-869.9992],true);

tube([[-27.95951,-12.45237,-872.8787],
[-26.21134,-14.84684,-872.1704],
[-24.46317,-16.4086,-871.2003],
[-22.715,-16.82659,-869.8707]
],0.25,0,3,[-28.24828,-17.12376,-874.2222],[-22.55912,-12.18699,-868.6482],true);

disk([-33.20401,-3.081926,-874.3162],0.25,0,3,
[1.639005,2.007144]);

disk([-22.715,-16.82659,-869.8707],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[-41.56688,-8.561698,-865.0438],
[-38.07054,-14.95307,-864.1308],
[-34.57421,-19.13618,-862.5237],
[-31.07787,-19.97217,-859.8644]
],0.25,0,3,[-41.87778,-20.26929,-868.2218],[-30.92202,-8.320751,-856.8337],true);

disk([-41.56688,-8.561698,-865.0438],0.25,0,3,
[1.695458,2.071364]);

disk([-31.07787,-19.97217,-859.8644],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[-33.20401,-3.081926,-874.3162],
[-35.99163,-4.929196,-871.2319],
[-38.77925,-6.807486,-868.1573],
[-41.56688,-8.561698,-865.0438]
],0.25,0,3,[-41.78779,-8.8533,-875.307],[-32.97985,-2.789979,-864.0572],true);

disk([-33.20401,-3.081926,-874.3162],0.25,0,3,
[2.315799,0.5852296]);

disk([-41.56688,-8.561698,-865.0438],0.25,0,3,
[0.8134964,-2.579917]);

tube([[-22.715,-16.82659,-869.8707],
[-25.50263,-17.87512,-866.5352],
[-28.29025,-18.92364,-863.1998],
[-31.07787,-19.97217,-859.8644]
],0.25,0,3,[-31.27335,-20.2574,-870.0943],[-22.51953,-16.54136,-859.6408],true);

disk([-22.715,-16.82659,-869.8707],0.25,0,3,
[2.412696,0.3597665]);

disk([-31.07787,-19.97217,-859.8644],0.25,0,3,
[0.728897,-2.781826]);

tube([[4.499739,4.019736,-876.5411],
[7.996073,9.93607,-871.7592],
[11.49241,12.29391,-868.0958],
[14.98874,13.69973,-864.7318]
],0.25,0,3,[4.178628,1.304028,-876.8934],[15.23553,16.51694,-864.4098],true);

disk([4.499739,4.019736,-876.5411],0.25,0,3,
[2.178728,-2.104545]);

disk([14.98874,13.69973,-864.7318],0.25,0,3,
[0.8420255,0.3823001]);

tube([[-3.86313,-1.563433,-867.3012],
[-0.3667959,5.272709,-862.2301],
[3.129539,12.82757,-856.933],
[6.625873,18.53172,-852.2177]
],0.25,0,3,[-4.189825,-4.285063,-867.6541],[6.945451,21.26944,-851.8657],true);

disk([-3.86313,-1.563433,-867.3012],0.25,0,3,
[2.15448,-2.043561]);

disk([6.625873,18.53172,-852.2177],0.25,0,3,
[0.9568679,1.020912]);

tube([[4.499739,4.019736,-876.5411],
[1.712116,5.233026,-872.4947],
[-1.075507,1.30747,-870.0637],
[-3.86313,-1.563433,-867.3012]
],0.25,0,3,[-4.119804,-1.852489,-877.6124],[4.768191,5.148159,-865.9816],true);

disk([4.499739,4.019736,-876.5411],0.25,0,3,
[2.49724,-0.4105133]);

disk([-3.86313,-1.563433,-867.3012],0.25,0,3,
[0.9665752,-2.341478]);

tube([[14.98874,13.69973,-864.7318],
[12.20112,19.07544,-859.3768],
[9.413496,19.46196,-855.5903],
[6.625873,18.53172,-852.2177]
],0.25,0,3,[6.426085,10.37385,-865.0773],[15.31408,22.0113,-851.9889],true);

disk([14.98874,13.69973,-864.7318],0.25,0,3,
[2.294875,-1.092412]);

disk([6.625873,18.53172,-852.2177],0.25,0,3,
[0.7167649,-2.819506]);

tube([[-83.52289,85.63931,-870.496],
[-80.02656,80.02174,-869.3398],
[-76.53022,73.51591,-868.4629],
[-73.03389,66.24858,-867.8253]
],0.25,0,3,[-83.82461,66.01919,-876.1421],[-72.71514,85.89158,-862.1577],true);

disk([-83.52289,85.63931,-870.496],0.25,0,3,
[1.743783,2.127519]);

disk([-73.03389,66.24858,-867.8253],0.25,0,3,
[1.491906,-1.12238]);

tube([[-91.88576,84.92471,-859.7255],
[-88.38943,77.81242,-859.0393],
[-84.89309,69.83942,-858.6235],
[-81.39676,61.37144,-858.3634]
],0.25,0,3,[-92.20328,61.15566,-866.6874],[-81.07028,85.15605,-851.3892],true);

disk([-91.88576,84.92471,-859.7255],0.25,0,3,
[1.657176,2.027694]);

disk([-81.39676,61.37144,-858.3634],0.25,0,3,
[1.542412,-1.179229]);

tube([[-83.52289,85.63931,-870.496],
[-86.31052,86.32425,-866.6156],
[-89.09814,85.91411,-863.0795],
[-91.88576,84.92471,-859.7255]
],0.25,0,3,[-92.08339,82.22524,-870.7843],[-83.26875,88.37756,-859.4993],true);

disk([-83.52289,85.63931,-870.496],0.25,0,3,
[2.504675,-0.2409348]);

disk([-91.88576,84.92471,-859.7255],0.25,0,3,
[0.7227434,-2.800535]);

tube([[-73.03389,66.24858,-867.8253],
[-75.82151,65.221,-864.4833],
[-78.60913,63.3705,-861.4],
[-81.39676,61.37144,-858.3634]
],0.25,0,3,[-81.62612,61.07917,-868.5924],[-72.83765,66.53409,-857.5549],true);

disk([-73.03389,66.24858,-867.8253],0.25,0,3,
[2.414896,0.3531676]);

disk([-81.39676,61.37144,-858.3634],0.25,0,3,
[0.8462058,-2.519469]);

tube([[-73.03389,66.24858,-867.8253],
[-69.53755,58.98126,-867.1878],
[-66.04122,50.95244,-866.7896],
[-62.54488,42.28892,-866.591]
],0.25,0,3,[-73.35266,42.07511,-874.9152],[-62.21737,66.478,-859.4889],true);

disk([-73.03389,66.24858,-867.8253],0.25,0,3,
[2.414896,0.3531676]);

disk([-81.39676,61.37144,-858.3634],0.25,0,3,
[0.8462058,-2.519469]);

tube([[-62.54488,42.28892,-866.591],
[-65.33251,39.97715,-863.6527],
[-68.12013,37.29321,-860.8314],
[-70.90775,34.84827,-857.935]
],0.25,0,3,[-71.15171,34.55664,-868.2141],[-62.30517,42.58097,-856.3062],true);

disk([-62.54488,42.28892,-866.591],0.25,0,3,
[2.252421,0.6923506]);

disk([-70.90775,34.84827,-857.935],0.25,0,3,
[0.9076569,-2.42159]);

tube([[-62.54488,42.28892,-866.591],
[-59.04855,33.62539,-866.3923],
[-55.55222,24.32716,-866.3932],
[-52.05588,15.37823,-866.2843]
],0.25,0,3,[-62.87213,15.16737,-874.6139],[-51.72711,42.50254,-858.2592],true);

disk([-62.54488,42.28892,-866.591],0.25,0,3,
[1.592055,1.954376]);

disk([-52.05588,15.37823,-866.2843],0.25,0,3,
[1.559461,-1.198334]);

tube([[-70.90775,34.84827,-857.935],
[-67.41142,25.75605,-857.8711],
[-63.91508,16.53458,-857.8478],
[-60.41875,8.149162,-857.5618]
],0.25,0,3,[-71.23717,7.932685,-865.895],[-60.09296,35.05777,-849.6069],true);

disk([-70.90775,34.84827,-857.935],0.25,0,3,
[1.577353,1.937906]);

disk([-60.41875,8.149162,-857.5618],0.25,0,3,
[1.53932,-1.17576]);

tube([[-62.54488,42.28892,-866.591],
[-65.33251,39.97715,-863.6527],
[-68.12013,37.29321,-860.8314],
[-70.90775,34.84827,-857.935]
],0.25,0,3,[-71.15161,34.55676,-868.2139],[-62.30526,42.58085,-856.3063],true);

disk([-62.54488,42.28892,-866.591],0.25,0,3,
[2.252421,0.6923506]);

disk([-70.90775,34.84827,-857.935],0.25,0,3,
[0.9076569,-2.42159]);

tube([[-52.05588,15.37823,-866.2843],
[-54.8435,12.83034,-863.4203],
[-57.63113,10.34116,-860.5377],
[-60.41875,8.149162,-857.5618]
],0.25,0,3,[-60.65457,7.856874,-867.8509],[-51.80874,15.66941,-856.0104],true);

disk([-52.05588,15.37823,-866.2843],0.25,0,3,
[2.219636,0.7404959]);

disk([-60.41875,8.149162,-857.5618],0.25,0,3,
[0.872613,-2.475242]);

tube([[33.84062,-14.91375,-875.9555],
[37.33695,-15.74974,-873.2962],
[40.83329,-16.58573,-870.6368],
[44.32962,-17.42172,-867.9775]
],0.25,0,3,[33.68473,-17.7189,-876.217],[44.4855,-14.61657,-867.716],true);

disk([33.84062,-14.91375,-875.9555],0.25,0,3,
[2.207705,2.906895]);

disk([44.32962,-17.42172,-867.9775],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[25.47775,15.65077,-855.3521],
[28.97408,15.74221,-852.4012],
[32.47042,15.47131,-849.5642],
[35.96675,15.04273,-846.7769]
],0.25,0,3,[25.28036,12.84676,-855.6434],[36.14117,17.85659,-846.5016],true);

disk([25.47775,15.65077,-855.3521],0.25,0,3,
[2.271621,-3.115445]);

disk([35.96675,15.04273,-846.7769],0.25,0,3,
[0.9013772,-0.1219719]);

tube([[33.84062,-14.91375,-875.9555],
[31.05299,-3.653536,-868.7507],
[28.26537,7.606681,-861.5459],
[25.47775,15.65077,-855.3521]
],0.25,0,3,[25.1412,-18.23722,-876.3049],[34.18332,18.99975,-855.0028],true);

disk([33.84062,-14.91375,-875.9555],0.25,0,3,
[2.126584,-1.328112]);

disk([25.47775,15.65077,-855.3521],0.25,0,3,
[0.9418303,1.904388]);

tube([[44.32962,-17.42172,-867.9775],
[41.542,-5.912307,-860.6944],
[38.75437,5.597105,-853.4112],
[35.96675,15.04273,-846.7769]
],0.25,0,3,[35.62691,-20.74505,-868.3269],[44.67265,18.38035,-846.4272],true);

disk([44.32962,-17.42172,-867.9775],0.25,0,3,
[2.122185,-1.333169]);

disk([35.96675,15.04273,-846.7769],0.25,0,3,
[0.977974,1.857773]);

tube([[-52.05588,15.37823,-866.2843],
[-48.55955,6.429296,-866.1754],
[-45.06321,-2.170329,-865.9567],
[-41.56688,-8.561698,-865.0438]
],0.25,0,3,[-52.38473,-8.802624,-873.3854],[-41.25601,15.58919,-857.9671],true);

disk([-52.05588,15.37823,-866.2843],0.25,0,3,
[1.582132,1.943259]);

disk([-41.56688,-8.561698,-865.0438],0.25,0,3,
[1.446135,-1.070229]);

tube([[-60.41875,8.149162,-857.5618],
[-56.92242,-0.2362592,-857.2757],
[-53.42608,-7.785635,-856.7268],
[-49.92975,-13.29701,-855.5373]
],0.25,0,3,[-60.74474,-13.55087,-863.8816],[-49.62954,8.365769,-849.2507],true);

disk([-60.41875,8.149162,-857.5618],0.25,0,3,
[1.602273,1.965832]);

disk([-49.92975,-13.29701,-855.5373],0.25,0,3,
[1.39052,-1.005477]);

tube([[-52.05588,15.37823,-866.2843],
[-54.8435,12.83034,-863.4203],
[-57.63113,10.34116,-860.5377],
[-60.41875,8.149162,-857.5618]
],0.25,0,3,[-60.65456,7.8569,-867.8509],[-51.80874,15.66939,-856.0104],true);

disk([-52.05588,15.37823,-866.2843],0.25,0,3,
[2.219636,0.7404959]);

disk([-60.41875,8.149162,-857.5618],0.25,0,3,
[0.872613,-2.475242]);

tube([[-41.56688,-8.561698,-865.0438],
[-44.3545,-10.31591,-861.9302],
[-47.14212,-11.94605,-858.7776],
[-49.92975,-13.29701,-855.5373]
],0.25,0,3,[-50.1362,-13.58577,-865.7973],[-41.34606,-8.270138,-854.8018],true);

disk([-41.56688,-8.561698,-865.0438],0.25,0,3,
[2.328096,0.5616756]);

disk([-49.92975,-13.29701,-855.5373],0.25,0,3,
[0.7629027,-2.690317]);

tube([[-22.715,-16.82659,-869.8707],
[-20.96684,-17.24459,-868.541],
[-19.21867,-16.51881,-866.8518],
[-17.4705,-14.96034,-864.9008]
],0.25,0,3,[-22.87079,-18.40943,-870.132],[-17.18187,-13.47282,-864.5584],true);

tube([[-17.4705,-14.96034,-864.9008],
[-15.72233,-13.40186,-862.9498],
[-13.97417,-11.01068,-860.737],
[-12.226,-8.097858,-858.3603]
],0.25,0,3,[-17.75913,-16.48717,-865.2432],[-11.90562,-6.624925,-858.0083],true);

disk([-22.715,-16.82659,-869.8707],0.25,0,3,
[2.207705,2.906895]);

disk([-12.226,-8.097858,-858.3603],0.25,0,3,
[0.9603199,1.030257]);

tube([[-31.07787,-19.97217,-859.8644],
[-27.58154,-20.80816,-857.2051],
[-24.0852,-18.29702,-853.4936],
[-20.58887,-13.57763,-849.0879]
],0.25,0,3,[-31.23367,-22.81427,-860.1258],[-20.27804,-10.86583,-848.7379],true);

disk([-31.07787,-19.97217,-859.8644],0.25,0,3,
[2.207705,2.906895]);

disk([-20.58887,-13.57763,-849.0879],0.25,0,3,
[0.9272237,0.9331807]);

tube([[-22.715,-16.82659,-869.8707],
[-25.50263,-17.87512,-866.5352],
[-28.29025,-18.92364,-863.1998],
[-31.07787,-19.97217,-859.8644]
],0.25,0,3,[-31.27334,-20.25741,-870.0943],[-22.51953,-16.54135,-859.6408],true);

disk([-22.715,-16.82659,-869.8707],0.25,0,3,
[2.412696,0.3597665]);

disk([-31.07787,-19.97217,-859.8644],0.25,0,3,
[0.728897,-2.781826]);

tube([[-12.226,-8.097858,-858.3603],
[-15.01362,-9.945128,-855.276],
[-17.80125,-11.82342,-852.2014],
[-20.58887,-13.57763,-849.0879]
],0.25,0,3,[-20.80976,-13.86923,-859.351],[-12.00187,-7.805912,-848.1013],true);

disk([-12.226,-8.097858,-858.3603],0.25,0,3,
[2.315799,0.5852296]);

disk([-20.58887,-13.57763,-849.0879],0.25,0,3,
[0.8134964,-2.579917]);

tube([[-41.56688,-8.561698,-865.0438],
[-38.07054,-14.95307,-864.1308],
[-34.57421,-19.13618,-862.5237],
[-31.07787,-19.97217,-859.8644]
],0.25,0,3,[-41.87769,-20.2693,-868.2217],[-30.92215,-8.320754,-856.8338],true);

disk([-41.56688,-8.561698,-865.0438],0.25,0,3,
[1.695458,2.071364]);

disk([-31.07787,-19.97217,-859.8644],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[-49.92975,-13.29701,-855.5373],
[-46.43341,-18.80839,-854.3477],
[-42.93708,-22.28176,-852.5175],
[-39.44074,-23.11775,-849.8582]
],0.25,0,3,[-50.23006,-23.41492,-858.2075],[-39.28486,-13.04307,-847.3223],true);

disk([-49.92975,-13.29701,-855.5373],0.25,0,3,
[1.751073,2.136116]);

disk([-39.44074,-23.11775,-849.8582],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[-41.56688,-8.561698,-865.0438],
[-44.3545,-10.31591,-861.9302],
[-47.14212,-11.94605,-858.7776],
[-49.92975,-13.29701,-855.5373]
],0.25,0,3,[-50.13624,-13.58579,-865.7974],[-41.34597,-8.270097,-854.8017],true);

disk([-41.56688,-8.561698,-865.0438],0.25,0,3,
[2.328096,0.5616756]);

disk([-49.92975,-13.29701,-855.5373],0.25,0,3,
[0.7629027,-2.690317]);

tube([[-31.07787,-19.97217,-859.8644],
[-33.8655,-21.0207,-856.529],
[-36.65312,-22.06922,-853.1936],
[-39.44074,-23.11775,-849.8582]
],0.25,0,3,[-39.63622,-23.40298,-860.0881],[-30.8824,-19.68694,-849.6345],true);

disk([-31.07787,-19.97217,-859.8644],0.25,0,3,
[2.412696,0.3597665]);

disk([-39.44074,-23.11775,-849.8582],0.25,0,3,
[0.728897,-2.781826]);

tube([[-3.86313,-1.563433,-867.3012],
[-0.3667959,5.272709,-862.2301],
[3.129539,12.82757,-856.933],
[6.625873,18.53172,-852.2177]
],0.25,0,3,[-4.189825,-4.285063,-867.6541],[6.945451,21.26944,-851.8657],true);

disk([-3.86313,-1.563433,-867.3012],0.25,0,3,
[2.15448,-2.043561]);

disk([6.625873,18.53172,-852.2177],0.25,0,3,
[0.9568679,1.020912]);

tube([[-12.226,-8.097858,-858.3603],
[-8.729665,-2.272218,-853.6068],
[-5.23333,5.639979,-848.1974],
[-1.736996,12.92764,-842.9843]
],0.25,0,3,[-12.54647,-10.83444,-858.7124],[-1.408079,15.64447,-842.6314],true);

disk([-12.226,-8.097858,-858.3603],0.25,0,3,
[2.181273,-2.111336]);

disk([-1.736996,12.92764,-842.9843],0.25,0,3,
[0.9979816,1.123471]);

tube([[-3.86313,-1.563433,-867.3012],
[-6.650754,-4.434336,-864.5387],
[-9.438377,-6.250587,-861.4446],
[-12.226,-8.097858,-858.3603]
],0.25,0,3,[-12.45018,-8.389859,-868.6621],[-3.606439,-1.274306,-857.043],true);

disk([-3.86313,-1.563433,-867.3012],0.25,0,3,
[2.175017,0.8001147]);

disk([-12.226,-8.097858,-858.3603],0.25,0,3,
[0.8257939,-2.556363]);

tube([[6.625873,18.53172,-852.2177],
[3.83825,17.60147,-848.8451],
[1.050627,15.35445,-845.8865],
[-1.736996,12.92764,-842.9843]
],0.25,0,3,[-1.980398,12.63589,-853.2158],[6.825705,18.82624,-841.9281],true);

disk([6.625873,18.53172,-852.2177],0.25,0,3,
[2.424828,0.3220863]);

disk([-1.736996,12.92764,-842.9843],0.25,0,3,
[0.9051367,-2.42528]);

tube([[44.32962,-17.42172,-867.9775],
[47.82595,-18.25771,-865.3182],
[51.32229,-19.0937,-862.6589],
[54.81862,-19.92968,-859.9996]
],0.25,0,3,[44.17374,-20.22688,-868.239],[54.97451,-17.12453,-859.7381],true);

disk([44.32962,-17.42172,-867.9775],0.25,0,3,
[2.207705,2.906895]);

disk([54.81862,-19.92968,-859.9996],0.25,0,3,
[0.9338881,-0.2346979]);

tube([[35.96675,15.04273,-846.7769],
[39.46309,14.61414,-843.9895],
[42.95942,14.02788,-841.2516],
[46.45575,13.4886,-838.4991]
],0.25,0,3,[35.79235,12.23544,-847.0521],[46.62515,16.29759,-838.2275],true);

disk([35.96675,15.04273,-846.7769],0.25,0,3,
[2.240215,3.019621]);

disk([46.45575,13.4886,-838.4991],0.25,0,3,
[0.9095662,-0.1530358]);

tube([[44.32962,-17.42172,-867.9775],
[41.542,-5.912307,-860.6944],
[38.75437,5.597105,-853.4112],
[35.96675,15.04273,-846.7769]
],0.25,0,3,[35.62685,-20.74508,-868.3269],[44.67271,18.38039,-846.4271],true);

disk([44.32962,-17.42172,-867.9775],0.25,0,3,
[2.122185,-1.333169]);

disk([35.96675,15.04273,-846.7769],0.25,0,3,
[0.977974,1.857773]);

tube([[54.81862,-19.92968,-859.9996],
[52.031,-8.323785,-852.6861],
[49.24338,3.282114,-845.3726],
[46.45575,13.4886,-838.4991]
],0.25,0,3,[46.1145,-23.25303,-860.349],[55.16183,16.82102,-838.1494],true);

disk([54.81862,-19.92968,-859.9996],0.25,0,3,
[2.12052,-1.335072]);

disk([46.45575,13.4886,-838.4991],0.25,0,3,
[0.9946637,1.837416]);

tube([[14.98874,13.69973,-864.7318],
[18.48508,15.10554,-861.3677],
[21.98141,15.55933,-858.3029],
[25.47775,15.65077,-855.3521]
],0.25,0,3,[14.74196,10.91214,-865.0538],[25.67513,18.4721,-855.0607],true);

disk([14.98874,13.69973,-864.7318],0.25,0,3,
[2.299567,-2.759293]);

disk([25.47775,15.65077,-855.3521],0.25,0,3,
[0.8699712,0.02614784]);

tube([[6.625873,18.53172,-852.2177],
[10.12221,24.23587,-847.5024],
[13.61854,28.08931,-843.3689],
[17.11488,30.95034,-839.5474]
],0.25,0,3,[6.306225,15.80434,-852.5697],[17.39868,33.73618,-839.207],true);

disk([6.625873,18.53172,-852.2177],0.25,0,3,
[2.184725,-2.12068]);

disk([17.11488,30.95034,-839.5474],0.25,0,3,
[0.8686905,0.6857961]);

tube([[14.98874,13.69973,-864.7318],
[12.20112,19.07544,-859.3768],
[9.413496,19.46196,-855.5903],
[6.625873,18.53172,-852.2177]
],0.25,0,3,[6.426116,10.37388,-865.0772],[15.31401,22.01123,-851.9889],true);

disk([14.98874,13.69973,-864.7318],0.25,0,3,
[2.294875,-1.092412]);

disk([6.625873,18.53172,-852.2177],0.25,0,3,
[0.7167649,-2.819506]);

tube([[25.47775,15.65077,-855.3521],
[22.69012,23.69485,-849.1583],
[19.9025,28.52281,-843.9755],
[17.11488,30.95034,-839.5474]
],0.25,0,3,[16.82094,12.32012,-855.7014],[25.81433,34.38062,-839.2228],true);

disk([25.47775,15.65077,-855.3521],0.25,0,3,
[2.199762,-1.237204]);

disk([17.11488,30.95034,-839.5474],0.25,0,3,
[0.6955807,2.425133]);

tube([[-31.07787,-19.97217,-859.8644],
[-27.58154,-20.80816,-857.2051],
[-24.0852,-18.29702,-853.4936],
[-20.58887,-13.57763,-849.0879]
],0.25,0,3,[-31.23373,-22.81433,-860.1259],[-20.27797,-10.86579,-848.7378],true);

disk([-31.07787,-19.97217,-859.8644],0.25,0,3,
[2.207705,2.906895]);

disk([-20.58887,-13.57763,-849.0879],0.25,0,3,
[0.9272237,0.9331807]);

tube([[-39.44074,-23.11775,-849.8582],
[-35.94441,-23.95374,-847.1989],
[-32.44807,-22.15234,-843.7105],
[-28.95174,-18.31294,-839.5814]
],0.25,0,3,[-39.59643,-25.95731,-850.1195],[-28.65164,-15.58566,-839.2347],true);

disk([-39.44074,-23.11775,-849.8582],0.25,0,3,
[2.207705,2.906895]);

disk([-28.95174,-18.31294,-839.5814],0.25,0,3,
[0.899018,0.8321302]);

tube([[-31.07787,-19.97217,-859.8644],
[-33.8655,-21.0207,-856.529],
[-36.65312,-22.06922,-853.1936],
[-39.44074,-23.11775,-849.8582]
],0.25,0,3,[-39.63622,-23.40298,-860.0881],[-30.8824,-19.68694,-849.6345],true);

disk([-31.07787,-19.97217,-859.8644],0.25,0,3,
[2.412696,0.3597665]);

disk([-39.44074,-23.11775,-849.8582],0.25,0,3,
[0.728897,-2.781826]);

tube([[-20.58887,-13.57763,-849.0879],
[-23.37649,-15.33184,-845.9743],
[-26.16412,-16.96198,-842.8217],
[-28.95174,-18.31294,-839.5814]
],0.25,0,3,[-29.15822,-18.60172,-849.8415],[-20.36799,-13.28604,-838.8458],true);

disk([-20.58887,-13.57763,-849.0879],0.25,0,3,
[2.328096,0.5616756]);

disk([-28.95174,-18.31294,-839.5814],0.25,0,3,
[0.7629027,-2.690317]);

tube([[-12.226,-8.097858,-858.3603],
[-8.729665,-2.272218,-853.6068],
[-5.23333,5.639979,-848.1974],
[-1.736996,12.92764,-842.9843]
],0.25,0,3,[-12.54647,-10.83429,-858.7124],[-1.408073,15.64434,-842.6314],true);

disk([-12.226,-8.097858,-858.3603],0.25,0,3,
[2.181273,-2.111336]);

disk([-1.736996,12.92764,-842.9843],0.25,0,3,
[0.9979816,1.123471]);

tube([[-20.58887,-13.57763,-849.0879],
[-17.09253,-8.858237,-844.6821],
[-13.5962,-1.93059,-839.5822],
[-10.09987,5.346364,-834.3725]
],0.25,0,3,[-20.89977,-16.33084,-849.4379],[-9.770991,8.060884,-834.0196],true);

disk([-20.58887,-13.57763,-849.0879],0.25,0,3,
[2.214369,-2.208412]);

disk([-10.09987,5.346364,-834.3725],0.25,0,3,
[0.9977315,1.122897]);

tube([[-12.226,-8.097858,-858.3603],
[-15.01362,-9.945128,-855.276],
[-17.80125,-11.82342,-852.2014],
[-20.58887,-13.57763,-849.0879]
],0.25,0,3,[-20.80981,-13.86927,-859.3511],[-12.00181,-7.805875,-848.1012],true);

disk([-12.226,-8.097858,-858.3603],0.25,0,3,
[2.315799,0.5852296]);

disk([-20.58887,-13.57763,-849.0879],0.25,0,3,
[0.8134964,-2.579917]);

tube([[-1.736996,12.92764,-842.9843],
[-4.524619,10.50084,-840.0822],
[-7.312242,7.894249,-837.2366],
[-10.09987,5.346364,-834.3725]
],0.25,0,3,[-10.34703,5.05517,-844.6555],[-1.493591,13.21936,-832.6962],true);

disk([-1.736996,12.92764,-842.9843],0.25,0,3,
[2.236456,0.7163122]);

disk([-10.09987,5.346364,-834.3725],0.25,0,3,
[0.9219564,-2.401097]);

tube([[25.47775,15.65077,-855.3521],
[28.97408,15.74221,-852.4012],
[32.47042,15.47131,-849.5642],
[35.96675,15.04273,-846.7769]
],0.25,0,3,[25.28036,12.84663,-855.6434],[36.14121,17.85671,-846.5016],true);

disk([25.47775,15.65077,-855.3521],0.25,0,3,
[2.271621,-3.115445]);

disk([35.96675,15.04273,-846.7769],0.25,0,3,
[0.9013772,-0.1219719]);

tube([[17.11488,30.95034,-839.5474],
[20.61121,33.81136,-835.7259],
[24.10755,35.67997,-832.2163],
[27.60388,36.95605,-828.8931]
],0.25,0,3,[16.83093,28.18272,-839.888],[27.84653,39.76706,-828.5733],true);

disk([17.11488,30.95034,-839.5474],0.25,0,3,
[2.272902,-2.455797]);

disk([27.60388,36.95605,-828.8931],0.25,0,3,
[0.8419233,0.3499545]);

tube([[25.47775,15.65077,-855.3521],
[22.69012,23.69485,-849.1583],
[19.9025,28.52281,-843.9755],
[17.11488,30.95034,-839.5474]
],0.25,0,3,[16.82099,12.32015,-855.7014],[25.81428,34.38057,-839.2228],true);

disk([25.47775,15.65077,-855.3521],0.25,0,3,
[2.199762,-1.237204]);

disk([17.11488,30.95034,-839.5474],0.25,0,3,
[0.6955807,2.425133]);

tube([[35.96675,15.04273,-846.7769],
[33.17913,24.48835,-840.1425],
[30.3915,31.87018,-834.1569],
[27.60388,36.95605,-828.8931]
],0.25,0,3,[27.28039,11.71244,-847.1266],[36.30667,40.33888,-828.5484],true);

disk([35.96675,15.04273,-846.7769],0.25,0,3,
[2.163619,-1.28382]);

disk([27.60388,36.95605,-828.8931],0.25,0,3,
[0.8337991,2.072188]);

tube([[6.625873,18.53172,-852.2177],
[10.12221,24.23587,-847.5024],
[13.61854,28.08931,-843.3689],
[17.11488,30.95034,-839.5474]
],0.25,0,3,[6.306289,15.80422,-852.5697],[17.39876,33.7363,-839.2069],true);

disk([6.625873,18.53172,-852.2177],0.25,0,3,
[2.184725,-2.12068]);

disk([17.11488,30.95034,-839.5474],0.25,0,3,
[0.8686905,0.6857961]);

tube([[-1.736996,12.92764,-842.9843],
[1.759339,20.21531,-837.7712],
[5.255673,26.87844,-832.7545],
[8.752008,32.44872,-828.0813]
],0.25,0,3,[-2.065973,10.21124,-843.3374],[9.070622,35.18891,-827.7294],true);

disk([-1.736996,12.92764,-842.9843],0.25,0,3,
[2.143611,-2.018122]);

disk([8.752008,32.44872,-828.0813],0.25,0,3,
[0.9530084,1.010275]);

tube([[6.625873,18.53172,-852.2177],
[3.83825,17.60147,-848.8451],
[1.050627,15.35445,-845.8865],
[-1.736996,12.92764,-842.9843]
],0.25,0,3,[-1.980411,12.63591,-853.2158],[6.825688,18.82621,-841.9281],true);

disk([6.625873,18.53172,-852.2177],0.25,0,3,
[2.424828,0.3220863]);

disk([-1.736996,12.92764,-842.9843],0.25,0,3,
[0.9051367,-2.42528]);

tube([[17.11488,30.95034,-839.5474],
[14.32725,33.37787,-835.1192],
[11.53963,33.40496,-831.4457],
[8.752008,32.44872,-828.0813]
],0.25,0,3,[8.553113,27.57203,-839.8719],[17.40877,35.91627,-827.8536],true);

disk([17.11488,30.95034,-839.5474],0.25,0,3,
[2.446012,-0.7164596]);

disk([8.752008,32.44872,-828.0813],0.25,0,3,
[0.7193698,-2.811139]);

tube([[-20.58887,-13.57763,-849.0879],
[-17.09253,-8.858237,-844.6821],
[-13.5962,-1.93059,-839.5822],
[-10.09987,5.346364,-834.3725]
],0.25,0,3,[-20.89975,-16.33083,-849.4379],[-9.771008,8.061007,-834.0196],true);

disk([-20.58887,-13.57763,-849.0879],0.25,0,3,
[2.214369,-2.208412]);

disk([-10.09987,5.346364,-834.3725],0.25,0,3,
[0.9977315,1.122897]);

tube([[-28.95174,-18.31294,-839.5814],
[-25.4554,-14.47354,-835.4523],
[-21.95907,-8.596145,-830.6826],
[-18.46273,-1.8827,-825.65]
],0.25,0,3,[-29.25198,-21.08096,-839.9282],[-18.13671,0.8357324,-825.2972],true);

disk([-28.95174,-18.31294,-839.5814],0.25,0,3,
[2.242575,-2.309462]);

disk([-18.46273,-1.8827,-825.65],0.25,0,3,
[0.9840427,1.090651]);

tube([[-20.58887,-13.57763,-849.0879],
[-23.37649,-15.33184,-845.9743],
[-26.16412,-16.96198,-842.8217],
[-28.95174,-18.31294,-839.5814]
],0.25,0,3,[-29.15822,-18.60175,-849.8414],[-20.36802,-13.28602,-838.8458],true);

disk([-20.58887,-13.57763,-849.0879],0.25,0,3,
[2.328096,0.5616756]);

disk([-28.95174,-18.31294,-839.5814],0.25,0,3,
[0.7629027,-2.690317]);

tube([[-10.09987,5.346364,-834.3725],
[-12.88749,2.798478,-831.5085],
[-15.67511,0.309295,-828.6259],
[-18.46273,-1.8827,-825.65]
],0.25,0,3,[-18.6986,-2.175031,-835.9392],[-9.852666,5.637593,-824.0986],true);

disk([-10.09987,5.346364,-834.3725],0.25,0,3,
[2.219636,0.7404959]);

disk([-18.46273,-1.8827,-825.65],0.25,0,3,
[0.872613,-2.475242]);

tube([[35.96675,15.04273,-846.7769],
[39.46309,14.61414,-843.9895],
[42.95942,14.02788,-841.2516],
[46.45575,13.4886,-838.4991]
],0.25,0,3,[35.79232,12.23549,-847.0521],[46.62517,16.29755,-838.2275],true);

disk([35.96675,15.04273,-846.7769],0.25,0,3,
[2.240215,3.019621]);

disk([46.45575,13.4886,-838.4991],0.25,0,3,
[0.9095662,-0.1530358]);

tube([[27.60388,36.95605,-828.8931],
[31.10022,38.23214,-825.5698],
[34.59655,38.91569,-822.4328],
[38.09289,39.40661,-819.3563]
],0.25,0,3,[27.36131,34.16196,-829.2127],[38.30679,42.22154,-819.0541],true);

disk([27.60388,36.95605,-828.8931],0.25,0,3,
[2.299669,-2.791638]);

disk([38.09289,39.40661,-819.3563],0.25,0,3,
[0.8540325,0.1394971]);

tube([[35.96675,15.04273,-846.7769],
[33.17913,24.48835,-840.1425],
[30.3915,31.87018,-834.1569],
[27.60388,36.95605,-828.8931]
],0.25,0,3,[27.28042,11.71245,-847.1266],[36.30664,40.33886,-828.5484],true);

disk([35.96675,15.04273,-846.7769],0.25,0,3,
[2.163619,-1.28382]);

disk([27.60388,36.95605,-828.8931],0.25,0,3,
[0.8337991,2.072188]);

tube([[46.45575,13.4886,-838.4991],
[43.66813,23.69509,-831.6255],
[40.88051,32.50216,-825.1919],
[38.09289,39.40661,-819.3563]
],0.25,0,3,[37.75999,10.16006,-838.8488],[46.79704,42.76652,-819.0078],true);

disk([46.45575,13.4886,-838.4991],0.25,0,3,
[2.146929,-1.304176]);

disk([38.09289,39.40661,-819.3563],0.25,0,3,
[0.906063,1.954526]);

tube([[-1.736996,12.92764,-842.9843],
[1.759339,20.21531,-837.7712],
[5.255673,26.87844,-832.7545],
[8.752008,32.44872,-828.0813]
],0.25,0,3,[-2.065847,10.21132,-843.3372],[9.0705,35.18882,-827.7296],true);

disk([-1.736996,12.92764,-842.9843],0.25,0,3,
[2.143611,-2.018122]);

disk([8.752008,32.44872,-828.0813],0.25,0,3,
[0.9530084,1.010275]);

tube([[-10.09987,5.346364,-834.3725],
[-6.60353,12.62332,-829.1628],
[-3.107196,20.24958,-823.8433],
[0.3891388,27.24112,-818.7233]
],0.25,0,3,[-10.42868,2.627775,-834.7254],[0.7165832,29.96317,-818.3704],true);

disk([-10.09987,5.346364,-834.3725],0.25,0,3,
[2.143861,-2.018696]);

disk([0.3891388,27.24112,-818.7233],0.25,0,3,
[0.9909287,1.107085]);

tube([[-1.736996,12.92764,-842.9843],
[-4.524619,10.50084,-840.0822],
[-7.312242,7.894249,-837.2366],
[-10.09987,5.346364,-834.3725]
],0.25,0,3,[-10.34704,5.055262,-844.6555],[-1.493587,13.21927,-832.6963],true);

disk([-1.736996,12.92764,-842.9843],0.25,0,3,
[2.236456,0.7163122]);

disk([-10.09987,5.346364,-834.3725],0.25,0,3,
[0.9219564,-2.401097]);

tube([[8.752008,32.44872,-828.0813],
[5.964385,31.49248,-824.7169],
[3.176762,29.55289,-821.6616],
[0.3891388,27.24112,-818.7233]
],0.25,0,3,[0.14939,26.9492,-828.9541],[8.950897,32.74135,-817.7965],true);

disk([8.752008,32.44872,-828.0813],0.25,0,3,
[2.422223,0.330454]);

disk([0.3891388,27.24112,-818.7233],0.25,0,3,
[0.8891713,-2.449242]);

tube([[17.11488,30.95034,-839.5474],
[20.61121,33.81136,-835.7259],
[24.10755,35.67997,-832.2163],
[27.60388,36.95605,-828.8931]
],0.25,0,3,[16.83099,28.18277,-839.8879],[27.84648,39.767,-828.5734],true);

disk([17.11488,30.95034,-839.5474],0.25,0,3,
[2.272902,-2.455797]);

disk([27.60388,36.95605,-828.8931],0.25,0,3,
[0.8419233,0.3499545]);

tube([[8.752008,32.44872,-828.0813],
[12.24834,38.019,-823.4081],
[15.74468,42.49644,-819.0784],
[19.24101,46.12447,-815.0158]
],0.25,0,3,[8.433529,29.71621,-828.433],[19.53812,48.89466,-814.6701],true);

disk([8.752008,32.44872,-828.0813],0.25,0,3,
[2.188584,-2.131318]);

disk([19.24101,46.12447,-815.0158],0.25,0,3,
[0.8922191,0.8038812]);

tube([[17.11488,30.95034,-839.5474],
[14.32725,33.37787,-835.1192],
[11.53963,33.40496,-831.4457],
[8.752008,32.44872,-828.0813]
],0.25,0,3,[8.553245,27.57196,-839.8719],[17.40868,35.91637,-827.8537],true);

disk([17.11488,30.95034,-839.5474],0.25,0,3,
[2.446012,-0.7164596]);

disk([8.752008,32.44872,-828.0813],0.25,0,3,
[0.7193698,-2.811139]);

tube([[27.60388,36.95605,-828.8931],
[24.81626,42.04193,-823.6292],
[22.02864,44.83184,-819.0872],
[19.24101,46.12447,-815.0158]
],0.25,0,3,[18.97076,33.59954,-829.2375],[27.92719,49.5738,-814.7119],true);

disk([27.60388,36.95605,-828.8931],0.25,0,3,
[2.307794,-1.069405]);

disk([19.24101,46.12447,-815.0158],0.25,0,3,
[0.6465133,2.707402]);

tube([[-10.09987,5.346364,-834.3725],
[-6.60353,12.62332,-829.1628],
[-3.107196,20.24958,-823.8433],
[0.3891388,27.24112,-818.7233]
],0.25,0,3,[-10.42862,2.627816,-834.7254],[0.7165209,29.96313,-818.3705],true);

disk([-10.09987,5.346364,-834.3725],0.25,0,3,
[2.143861,-2.018696]);

disk([0.3891388,27.24112,-818.7233],0.25,0,3,
[0.9909287,1.107085]);

tube([[-18.46273,-1.8827,-825.65],
[-14.9664,4.830744,-820.6174],
[-11.47007,12.38023,-815.322],
[-7.97373,19.80047,-810.0673]
],0.25,0,3,[-18.78866,-4.608064,-826.0027],[-7.644326,22.51716,-809.7144],true);

disk([-18.46273,-1.8827,-825.65],0.25,0,3,
[2.15755,-2.050942]);

disk([-7.97373,19.80047,-810.0673],0.25,0,3,
[1.001048,1.130462]);

tube([[-10.09987,5.346364,-834.3725],
[-12.88749,2.798478,-831.5085],
[-15.67511,0.309295,-828.6259],
[-18.46273,-1.8827,-825.65]
],0.25,0,3,[-18.69862,-2.175053,-835.9392],[-9.852647,5.637616,-824.0985],true);

disk([-10.09987,5.346364,-834.3725],0.25,0,3,
[2.219636,0.7404959]);

disk([-18.46273,-1.8827,-825.65],0.25,0,3,
[0.872613,-2.475242]);

tube([[0.3891388,27.24112,-818.7233],
[-2.398484,24.92936,-815.785],
[-5.186107,22.24542,-812.9637],
[-7.97373,19.80047,-810.0673]
],0.25,0,3,[-8.217755,19.50876,-820.3464],[0.6289284,27.53326,-808.4384],true);

disk([0.3891388,27.24112,-818.7233],0.25,0,3,
[2.252421,0.6923506]);

disk([-7.97373,19.80047,-810.0673],0.25,0,3,
[0.9076569,-2.42159]);

tube([[10.87814,46.18486,-804.0017],
[8.09052,45.15728,-800.6597],
[5.302897,
43.30677,-797.5764],
[2.515273,41.30771,-794.5398]
],0.25,0,3,[2.28601,41.01551,-804.7688],[11.07435,46.47036,-793.7314],true);

disk([10.87814,46.18486,-804.0017],0.25,0,3,
[2.414896,0.3531676]);

disk([2.515273,41.30771,-794.5398],0.25,0,3,
[0.8462058,-2.519469]);

tube([[19.24101,46.12447,-815.0158],
[22.73735,49.7525,-810.9531],
[26.23368,52.53112,-807.1575],
[29.73002,54.70378,-803.5524]
],0.25,0,3,[18.94381,43.36615,-815.3616],[29.99855,57.49907,-803.2189],true);

disk([19.24101,46.12447,-815.0158],0.25,0,3,
[2.249374,-2.337711]);

disk([29.73002,54.70378,-803.5524],0.25,0,3,
[0.8515172,0.556014]);

tube([[10.87814,46.18486,-804.0017],
[14.37448,51.7802,-799.3206],
[17.87081,56.61406,-794.8789],
[21.36715,60.55966,-790.7165]
],0.25,0,3,[10.55938,43.45156,-804.3536],[21.66888,63.32397,-790.3691],true);

disk([10.87814,46.18486,-804.0017],0.25,0,3,
[2.187857,-2.129298]);

disk([21.36715,60.55966,-790.7165],0.25,0,3,
[0.902448,0.8456935]);

tube([[19.24101,46.12447,-815.0158],
[16.45339,47.41709,-810.9444],
[13.66577,47.21244,-807.3437],
[10.87814,46.18486,-804.0017]
],0.25,0,3,[10.68195,42.72284,-815.3198],[19.51142,49.64381,-803.7772],true);

disk([19.24101,46.12447,-815.0158],0.25,0,3,
[2.495079,-0.4341902]);

disk([10.87814,46.18486,-804.0017],0.25,0,3,
[0.7266969,-2.788425]);

tube([[29.73002,54.70378,-803.5524],
[26.94239,58.09469,-798.8214],
[24.15477,59.87472,-794.5968],
[21.36715,60.55966,-790.7165]
],0.25,0,3,[21.11313,51.32427,-803.8873],[30.03771,64.01503,-790.4283],true);

disk([29.73002,54.70378,-803.5524],0.25,0,3,
[2.393602,-0.8827329]);

disk([21.36715,60.55966,-790.7165],0.25,0,3,
[0.6369175,2.900658]);

tube([[10.87814,46.18486,-804.0017],
[14.37448,51.7802,-799.3206],
[17.87081,56.61406,-794.8789],
[21.36715,60.55966,-790.7165]
],0.25,0,3,[10.5595,43.45165,-804.3534],[21.66876,63.32387,-790.3693],true);

disk([10.87814,46.18486,-804.0017],0.25,0,3,
[2.187857,-2.129298]);

disk([21.36715,60.55966,-790.7165],0.25,0,3,
[0.902448,0.8456935]);

tube([[2.515273,41.30771,-794.5398],
[6.011608,48.10372,-789.4813],
[9.507943,54.40474,-784.5784],
[13.00428,59.84505,-779.946]
],0.25,0,3,[2.188865,38.58616,-794.8926],[13.32173,62.58645,-779.5945],true);

disk([2.515273,41.30771,-794.5398],0.25,0,3,
[2.155479,-2.045952]);

disk([13.00428,59.84505,-779.946],0.25,0,3,
[0.9492064,0.9995898]);

tube([[10.87814,46.18486,-804.0017],
[8.09052,45.15728,-800.6597],
[5.302897,43.30677,-797.5764],
[2.515273,41.30771,-794.5398]
],0.25,0,3,[2.285881,41.01541,-804.7688],[11.07439,46.47039,-793.7313],true);

disk([10.87814,46.18486,-804.0017],0.25,0,3,
[2.414896,0.3531676]);

disk([2.515273,41.30771,-794.5398],0.25,0,3,
[0.8462058,-2.519469]);

tube([[21.36715,60.55966,-790.7165],
[18.57952,61.2446,-786.8361],
[15.7919,60.83446,-783.3],
[13.00428,59.84505,-779.946]
],0.25,0,3,[12.80664,57.14556,-791.0048],[21.62132,63.29794,-779.7198],true);

disk([21.36715,60.55966,-790.7165],0.25,0,3,
[2.504675,-0.2409348]);

disk([13.00428,59.84505,-779.946],0.25,0,3,
[0.7227434,-2.800535]);

P.push(new BezierPatch([
[10.1986410634,-11.7007981756,-898.83194007],
[9.84327043841,-11.5571454256,-898.83194007],
[9.79021068841,-11.7153161756,-898.83194007],
[9.44991193841,-11.4818521756,-898.83194007],
[9.41702615716,-11.6064209881,-898.83194007],
[9.38178031341,-11.7298341756,-898.83194007],
[9.03272593841,-11.4818521756,-898.83194007],
[9.01293393841,-11.5693521756,-898.83194007],
[8.99314193841,-11.6568521756,-898.83194007],
[8.97334993841,-11.7443521756,-898.83194007]
],4,3,[-92.1102974528,-104.370155785,-1020.03589696],[96.8574901144,97.5518394311,-779.742754888]));

P.push(new BezierPatch([
[11.9889739384,-14.6521681756,-898.83194007],
[11.6707459384,-14.5094601756,-898.83194007],
[11.3525179384,-14.3667521756,-898.83194007],
[11.0342899384,-14.2240441756,-898.83194007],
[11.9889739384,-13.1779501756,-898.83194007],
[11.6707459384,-12.9542521756,-898.83194007],
[11.3525179384,-12.7305541756,-898.83194007],
[11.0342899384,-12.5068561756,-898.83194007],
[11.2647529384,-12.1317564256,-898.83194007],
[10.8504319384,-12.0026216756,-898.83194007],
[10.4361109384,-11.8734869256,-898.83194007],
[10.0217899384,-11.7443521756,-898.83194007],
[10.1986410634,-11.7007981756,-898.83194007],
[9.79021068841,-11.7153161756,-898.83194007],
[9.38178031341,-11.7298341756,-898.83194007],
[8.97334993841,-11.7443521756,-898.83194007]
],4,3,[-92.1102974528,-104.370155785,-1020.03589696],[96.8574901144,97.5518394311,-779.742754888]));

P.push(new BezierPatch([
[7.66455943841,-12.0902491756,-898.83194007],
[6.94724253841,-12.4591956756,-898.83194007],
[6.22992563841,-12.8281421756,-898.83194007],
[5.51260873841,-13.1970886756,-898.83194007],
[8.12334793841,-11.8517731756,-898.83194007],
[7.57400023841,-11.9569821756,-898.83194007],
[7.02465253841,-12.0621911756,-898.83194007],
[6.47530483841,-12.1674001756,-898.83194007],
[8.57334793841,-11.7443521756,-898.83194007],
[8.30121393841,-11.6568521756,-898.83194007],
[8.02907993841,-11.5693521756,-898.83194007],
[7.75694593841,-11.4818521756,-898.83194007],
[8.97334993841,-11.7443521756,-898.83194007],
[8.99314193841,-11.6568521756,-898.83194007],
[9.01293393841,-11.5693521756,-898.83194007],
[9.03272593841,-11.4818521756,-898.83194007]
],4,3,[-92.1102974528,-104.370155785,-1020.03589696],[96.8574901144,97.5518394311,-779.742754888]));

P.push(new BezierPatch([
[10.3706149384,-18.4609534756,-898.83194007],
[10.4830514384,-17.6813313756,-898.83194007],
[10.5954879384,-16.9017092756,-898.83194007],
[10.7079244384,-16.1220871756,-898.83194007],
[11.3362399384,-17.4506020756,-898.83194007],
[11.1978299384,-16.7783367756,-898.83194007],
[11.0594199384,-16.1060714756,-898.83194007],
[10.9210099384,-15.4338061756,-898.83194007],
[11.9889739384,-16.0826341756,-898.83194007],
[11.6707459384,-15.6399261756,-898.83194007],
[11.3525179384,-15.1972181756,-898.83194007],
[11.0342899384,-14.7545101756,-898.83194007],
[11.9889739384,-14.6521681756,-898.83194007],
[11.6707459384,-14.5094601756,-898.83194007],
[11.3525179384,-14.3667521756,-898.83194007],
[11.0342899384,-14.2240441756,-898.83194007]
],4,3,[-92.1102974528,-104.370155785,-1020.03589696],[96.8574901144,97.5518394311,-779.742754888]));

P.push(new BezierPatch([
[6.30303793841,-13.2224761756,-898.83194007],
[6.73819393841,-12.6982561756,-898.83194007],
[6.19555900301,-13.1337775643,-898.83194007],
[7.20577093841,-12.3287251756,-898.83194007],
[6.64349137801,-12.6849325643,-898.83194007],
[6.08808006761,-13.0450789531,-898.83194007],
[7.66455943841,-12.0902491756,-898.83194007],
[7.10324000301,-12.3789595643,-898.83194007],
[6.54192056761,-12.6676699531,-898.83194007],
[5.98060113221,-12.9563803419,-898.83194007]
],4,3,[-92.1102974528,-104.370155785,-1020.03589696],[96.8574901144,97.5518394311,-779.742754888]));

P.push(new BezierPatch([
[5.98060113221,-12.9563803419,-898.83194007],
[6.08808006761,-13.0450789531,-898.83194007],
[6.19555900301,-13.1337775643,-898.83194007],
[6.30303793841,-13.2224761756,-898.83194007],
[5.82460366761,-13.0366164531,-898.83194007],
[5.82105975788,-13.2972676939,-898.83194007],
[5.81751584815,-13.5579189348,-898.83194007],
[5.81397193841,-13.8185701756,-898.83194007],
[5.66860620301,-13.1168525643,-898.83194007],
[5.60273844815,-13.5981544348,-898.83194007],
[5.53687069328,-14.0794563052,-898.83194007],
[5.47100293841,-14.5607581756,-898.83194007],
[5.51260873841,-13.1970886756,-898.83194007],
[5.42517263841,-13.8945491756,-898.83194007],
[5.33773653841,-14.5920096756,-898.83194007],
[5.25030043841,-15.2894701756,-898.83194007]
],4,3,[-92.1102974528,-104.370155785,-1020.03589696],[96.8574901144,97.5518394311,-779.742754888]));

P.push(new BezierPatch([
[9.78272593841,-18.0740401756,-898.83194007],
[9.34756993841,-18.6818527756,-898.83194007],
[9.97007741127,-18.2071292571,-898.83194007],
[8.85889693841,-19.1259940156,-898.83194007],
[9.51250878627,-18.7340826946,-898.83194007],
[10.1574288841,-18.3402183385,-898.83194007],
[8.36885743841,-19.4181821056,-898.83194007],
[9.02749841127,-19.1032238771,-898.83194007],
[9.68613938413,-18.7882656485,-898.83194007],
[10.344780357,-18.47330742,-898.83194007]
],4,3,[-92.1102974528,-104.370155785,-1020.03589696],[96.8574901144,97.5518394311,-779.742754888]));

P.push(new BezierPatch([
[10.344780357,-18.47330742,-898.83194007],
[10.1574288841,-18.3402183385,-898.83194007],
[9.97007741127,-18.2071292571,-898.83194007],
[9.78272593841,-18.0740401756,-898.83194007],
[10.3533918841,-18.4691894385,-898.83194007],
[10.2962439022,-18.1486710176,-898.83194007],
[10.2390959203,-17.8281525966,-898.83194007],
[10.1819479384,-17.5076341756,-898.83194007],
[10.3620034113,-18.4650714571,-898.83194007],
[10.4062819203,-17.9135036966,-898.83194007],
[10.4505604294,-17.3619359361,-898.83194007],
[10.4948389384,-16.8103681756,-898.83194007],
[10.3706149384,-18.4609534756,-898.83194007],
[10.4830514384,-17.6813313756,-898.83194007],
[10.5954879384,-16.9017092756,-898.83194007],
[10.7079244384,-16.1220871756,-898.83194007]
],4,3,[-92.1102974528,-104.370155785,-1020.03589696],[96.8574901144,97.5518394311,-779.742754888]));

P.push(new BezierPatch([
[4.93116193841,-17.2756081756,-898.83194007],
[4.58949513841,-17.1761281756,-898.83194007],
[4.24782833841,-17.0766481756,-898.83194007],
[3.90616153841,-16.9771681756,-898.83194007],
[4.93116193841,-16.7334181756,-898.83194007],
[4.58949513841,-16.3558141756,-898.83194007],
[4.24782833841,-15.9782101756,-898.83194007],
[3.90616153841,-15.6006061756,-898.83194007],
[5.02959793841,-16.0181821756,-898.83194007],
[4.86970283841,-15.4210471756,-898.83194007],
[4.70980773841,-14.8239121756,-898.83194007],
[4.54991263841,-14.2267771756,-898.83194007],
[5.25030043841,-15.2894701756,-898.83194007],
[5.33773653841,-14.5920096756,-898.83194007],
[5.42517263841,-13.8945491756,-898.83194007],
[5.51260873841,-13.1970886756,-898.83194007]
],4,3,[-92.1102974528,-104.370155785,-1020.03589696],[96.8574901144,97.5518394311,-779.742754888]));

P.push(new BezierPatch([
[6.87490993841,-20.1240397756,-898.83194007],
[6.89886993841,-20.0328948956,-898.83194007],
[6.92282993841,-19.9417500156,-898.83194007],
[6.94678993841,-19.8506051356,-898.83194007],
[8.12647393841,-20.1240397756,-898.83194007],
[7.88011993841,-20.0328948956,-898.83194007],
[7.63376593841,-19.9417500156,-898.83194007],
[7.38741193841,-19.8506051356,-898.83194007],
[9.40498993841,-19.4713048756,-898.83194007],
[8.89626593841,-19.5509933156,-898.83194007],
[8.38754193841,-19.6306817556,-898.83194007],
[7.87881793841,-19.7103701956,-898.83194007],
[10.3706149384,-18.4609534756,-898.83194007],
[9.70336243841,-18.7800296856,-898.83194007],
[9.03610993841,-19.0991058956,-898.83194007],
[8.36885743841,-19.4181821056,-898.83194007]
],4,3,[-92.1102974528,-104.370155785,-1020.03589696],[96.8574901144,97.5518394311,-779.742754888]));

P.push(new BezierPatch([
[5.57217643841,-19.2603704056,-898.83194007],
[5.29561453841,-19.2577655456,-898.83194007],
[5.01905263841,-19.2551606856,-898.83194007],
[4.74249073841,-19.2525558256,-898.83194007],
[5.19053593841,-18.8490424156,-898.83194007],
[4.86579683841,-18.7993022356,-898.83194007],
[4.54105773841,-18.7495620556,-898.83194007],
[4.21631863841,-18.6998218756,-898.83194007],
[4.93116193841,-18.2052931756,-898.83194007],
[4.58949513841,-18.1078967756,-898.83194007],
[4.24782833841,-18.0105003756,-898.83194007],
[3.90616153841,-17.9131039756,-898.83194007],
[4.93116193841,-17.2756081756,-898.83194007],
[4.58949513841,-17.1761281756,-898.83194007],
[4.24782833841,-17.0766481756,-898.83194007],
[3.90616153841,-16.9771681756,-898.83194007]
],4,3,[-92.1102974528,-104.370155785,-1020.03589696],[96.8574901144,97.5518394311,-779.742754888]));

P.push(new BezierPatch([
[4.74249073841,-19.2525558256,-898.83194007],
[5.01905263841,-19.2551606856,-898.83194007],
[5.29561453841,-19.2577655456,-898.83194007],
[5.57217643841,-19.2603704056,-898.83194007],
[5.26866283841,-19.8052897756,-898.83194007],
[5.49704753841,-19.7607593156,-898.83194007],
[5.72543223841,-19.7162288556,-898.83194007],
[5.95381693841,-19.6716983956,-898.83194007],
[6.01084993841,-20.1240397756,-898.83194007],
[6.15980793841,-20.0328948956,-898.83194007],
[6.30876593841,-19.9417500156,-898.83194007],
[6.45772393841,-19.8506051356,-898.83194007],
[6.87490993841,-20.1240397756,-898.83194007],
[6.89886993841,-20.0328948956,-898.83194007],
[6.92282993841,-19.9417500156,-898.83194007],
[6.94678993841,-19.8506051356,-898.83194007]
],4,3,[-92.1102974528,-104.370155785,-1020.03589696],[96.8574901144,97.5518394311,-779.742754888]));

Centers=[
[-64.81967,-32.66368,-819.4922],
[81.04113,-26.1996,-840.0547],
[2.373604,88.59029,-869.7168],
[5.177921,-11.48185,-898.8319],
];
</script>
</head>

<body style="overflow: hidden; " onload="webGLStart();" >
<canvas id="Asymptote" width="192" height="201" >
</canvas>
</body>

</html>
'
                width="100%"
                height="350"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className="for_instructors">
        <h1>{t('home_h_7')}</h1>
        <h4 className="p-4">{t('home_h_8')}</h4>

        <div className="container">
          <div className="row">
            <div className="col-12 m-auto  ">
              <ul className="ms-auto  instructor_ul">
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i> {t('home_li_1')}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i> {t('home_li_2')}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i> {t('home_li_3')}
                </li>
                <li>
                  <i className="fa fa-check" aria-hidden="true"></i> {t('home_li_4')}
                </li>
              </ul>
              <div className="embed-responsive embed-responsive-16by9" style={{ margin: 'auto' }}>
                <div style={{ width: '854px', margin: 'auto' }} className="media">
                  <iframe
                    title="For Instructors"
                    style={{ height: '480px', width: '854px' }}
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/MYR0HP9sayw?si=wd8DYz4tr1tjw5IR"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
