import { DataService } from '../dataService';

export const subscribeServices = {
  adminGetAll: () => {
    return DataService.get('/subscription/list');
  },
  adminAdd: (data) => {
    return DataService.post('/subscription/add', data);
  },
  payApi: (data) => {
    return DataService.post('/subscription', data);
  },
  successApi: () => {
    return DataService.post('/subscription/success');
  },
  infoApi: () => {
    return DataService.get('/subscription/info');
  },
  cancelApi: () => {
    return DataService.delete('/subscription/cancel');
  },
};
